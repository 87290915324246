import React, { useState } from "react";

import { Link } from "react-router-dom";
import { ThemeColorsEnum } from "../../constants/enums";

const NavLink = ({
  active,
  badgeColor = ThemeColorsEnum.primary,
  badgeTitle,
  children,
  color,
  href,
  icon,
  IconComponent,
  parent,
  state,
  styleType,
  title,
  to = "#",
  visible = true,
  ...otherProps
}) => {
  if (!visible) return null;

  let listItemClass = "nav-item";
  if (active) listItemClass = `${listItemClass} nav-active nav-expanded`;
  if (parent) listItemClass = `${listItemClass} nav-parent`;

  let linkClass = parent ? "" : "nav-link";
  if (!!color) linkClass = `${linkClass} text-${color}`;

  return (
    <li className={listItemClass ?? ""}>
      {(!!href && (
        <a className={linkClass} href={href} rel="noreferrer" target="_blank">
          {badgeTitle && (
            <span className={`float-end badge badge-${badgeColor}`}>
              {badgeTitle}
            </span>
          )}
          {(icon && <i className={icon}></i>) ||
            (IconComponent && <IconComponent />)}
          <span>{title}</span>
        </a>
      )) || (
        <Link className={linkClass} state={state} to={to}>
          {badgeTitle && (
            <span className={`float-end badge badge-${badgeColor}`}>
              {badgeTitle}
            </span>
          )}
          {(icon && <i className={icon}></i>) ||
            (IconComponent && <IconComponent />)}
          <span>{title}</span>
        </Link>
      )}
      {children}
    </li>
  );
};

export default NavLink;
