import React, { useEffect, useState } from "react";
import { StatusEnum, ThemeColorsEnum } from "../../constants/enums";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "react-bootstrap";
import DetailsRow from "../../components/DetailsRow";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import useBranchInfo from "../../hooks/useBranchInfo";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";

const WorkOrderDetails = ({ workOrder, ...otherProps }) => {
  const [driverLocation, setDriverLocation] = useState();

  const customerLocation = {
    lat: workOrder?.customerLatitude,
    lng: workOrder?.customerLongitude,
  };
  const truckLoad = workOrder.truckLoad?.join("\n").trim();
  const truckInfo = `${
    !!workOrder?.truckName ? workOrder?.truckName : ""
  }\n${truckLoad}`.trim();

  const { isPropaneBranch } = useBranchInfo();
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    if (workOrder?.assignedToUserId)
      getDriverLocationAsync(workOrder?.assignedToUserId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDriverLocationAsync = async (id) => {
    const response = await Services.api.users.getLastKnownLocation(id);
    if (response.ok)
      setDriverLocation({
        lat: response.data?.geolocation?.location?.latitude,
        lng: response.data?.geolocation?.location?.longitude,
        createdDateFormatted: response.data?.createdDateFormatted,
      });
  };

  return (
    <div>
      <Map
        centerPosition={customerLocation}
        className="map-container-modal mb-3"
        zoom={7}
      >
        <AppMarker
          clickable
          info={
            <span className="info-window-text-title">
              {workOrder?.customerDisplayName}
            </span>
          }
          order={workOrder}
          position={customerLocation}
        />
        <AppMarker
          clickable
          icon={`${process.env.PUBLIC_URL}/img/ic_pin_truck.png`}
          info={
            <>
              <span className="info-window-text-title">
                {workOrder?.assignedToName}
              </span>
              <br />
              <span className="info-window-text">
                {driverLocation?.createdDateFormatted}
              </span>
            </>
          }
          position={driverLocation}
          visible={!!driverLocation}
        />
      </Map>
      <dl className="horizontal">
        <DetailsRow title="Id" details={workOrder?.id} />
        <DetailsRow
          title="Status"
          details={
            <>
              {workOrder.rowClass === ThemeColorsEnum.due ||
              workOrder.rowClass === ThemeColorsEnum.overdue ||
              workOrder.rowClass === ThemeColorsEnum.completed ? (
                <FontAwesomeIcon
                  className={`align-middle text-${workOrder.rowClass}`}
                  icon={faCircle}
                  size="2x"
                />
              ) : null}
              <Badge
                bg={null}
                className={`align-middle ${Services.workOrders.getBadgeClassByOrderStatus(
                  workOrder.orderStatusId
                )} ms-3`}
              >
                {workOrder.orderStatus}
              </Badge>
            </>
          }
        />
        <DetailsRow
          title="Due on"
          details={Services.utility.formatFullDate(workOrder?.dueDate)}
          visible={workOrder?.status === StatusEnum.active}
        />
        <DetailsRow
          title="Completed on"
          details={`${Services.utility.formatFullDateAndTime(
            workOrder?.completedDate
          )} (${workOrder?.completedByName})`}
          visible={!!workOrder?.completedDate}
        />
        <DetailsRow title="Customer" details={workOrder?.customerDisplayName} />
        <DetailsRow
          title="Customer Location"
          details={workOrder?.customerLocationName}
        />
        <DetailsRow
          title="Location Info"
          details={workOrder?.locationName}
          visible={!!workOrder?.locationName}
        />
        <DetailsRow
          title="Confirmation Info"
          details={workOrder?.confirmation}
          visible={!!workOrder?.confirmation}
        />
        <DetailsRow
          title="Member #"
          details={workOrder?.customerMemberNumber}
          visible={!!workOrder?.customerMemberNumber}
        />
        <DetailsRow
          title="Contact"
          details={workOrder?.customerContactName}
          visible={!!workOrder?.customerContactName}
        />
        <DetailsRow
          title="Phone"
          details={workOrder?.customerPhoneNumber}
          visible={!!workOrder?.customerPhoneNumber}
        />
        <DetailsRow
          title="Serial #"
          details={workOrder?.customerSerialNumber}
          visible={!!workOrder?.customerSerialNumber}
        />
        {/* Propane only */}
        <DetailsRow
          title="Tank Size"
          details={workOrder?.customerTankSize}
          visible={!!workOrder?.customerTankSize && isPropaneBranch}
        />
        <DetailsRow
          title="Route"
          details={workOrder?.customerRoute}
          visible={!!workOrder?.customerRoute && isPropaneBranch}
        />
        <DetailsRow
          title="Ram Card"
          details={workOrder?.customerRamCard}
          visible={!!workOrder?.customerRamCard && isPropaneBranch}
        />
        <DetailsRow
          title="Comment 1"
          details={workOrder?.customerComment1}
          visible={!!workOrder?.customerComment1 && isPropaneBranch}
        />
        <DetailsRow
          title="Comment 2"
          details={workOrder?.customerComment2}
          visible={!!workOrder?.customerComment2 && isPropaneBranch}
        />
        {/* End propane only */}
        <DetailsRow title="Truck" details={truckInfo} visible={!!truckInfo} />
        <DetailsRow
          title="Payment Method"
          details={workOrder?.paymentOption}
          visible={!!workOrder?.paymentOption}
        />
        <DetailsRow
          title="Products"
          details={workOrder?.productsListFormatted?.map(
            (productInfo, index) => (
              <div key={productInfo + index}>{productInfo}</div>
            )
          )}
        />
        <DetailsRow
          title="Driver"
          details={
            !!workOrder?.assignedToName ? (
              workOrder?.assignedToName
            ) : (
              <em className="text-muted">Unassigned</em>
            )
          }
        />
        <DetailsRow
          title={
            <span>
              Hazard Assessment
              <br />
              Permanent Instructions
            </span>
          }
          details={workOrder?.instructions}
          visible={!!workOrder?.instructions}
        />
        <DetailsRow
          title="Notes"
          details={workOrder?.notes}
          visible={!!workOrder?.notes}
        />
        <DetailsRow
          title="Entered"
          details={`${Services.utility.formatFullDateAndTime(
            workOrder?.createdDate
          )} (${workOrder?.createdByName})`}
        />
        <DetailsRow
          title="Device"
          details={workOrder.deviceInfo}
          visible={!!workOrder.deviceInfo && userInfo?.isSuperAdmin}
        />
      </dl>
    </div>
  );
};

export default WorkOrderDetails;
