import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Navigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext, LoadingContext } from "../../context/contextProvider";
import useBranchUsersDataTable from "../../hooks/useBranchUsersDataTable";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Page from "../Page";

const BranchUsersDetailsPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();
  const { enrolledUsers } = useContext(AuthContext);

  const allUsers = enrolledUsers.filter((x) => x.clientId === parseInt(id));
  const [users, setUsers] = useState(allUsers);
  const [searchValue, setSearchValue] = useState("");

  const { setLoading } = useContext(LoadingContext);

  const userInfo = useCurrentUserInfo();
  const dataTableInfo = useBranchUsersDataTable();

  const title = `${state?.client?.branchName} - Users`;
  const breadcrumbs = [
    {
      IconComponent: DashboardIcon,
      title: "Dashhboard",
      to: AppRoutes.dashboard,
    },
    {
      IconComponent: PointOfSaleIcon,
      title: "Enrolled Clients",
      to: AppRoutes.billingClients,
    },
    {
      title: title,
    },
  ];

  useEffect(() => {
    handleSearchUsers(searchValue);
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearchUsers = (searchValue) => {
    if (!searchValue) return setUsers(allUsers);

    setUsers(
      allUsers?.filter(
        (x) =>
          x.userName.toLowerCase().includes(searchValue.toLowerCase()) ||
          x.fullName.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  if (!userInfo?.isSuperAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            {/* <Card.Header collapsible>
              <h2 className="card-title">Users</h2>
            </Card.Header> */}
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  conditionalRowStyles={dataTableInfo.conditionalRowStyles}
                  data={users}
                  emptyDataComponent={<EmptyDataBox />}
                  onSearch={setSearchValue}
                  paginate
                  search
                  searchValue={searchValue}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default BranchUsersDetailsPage;
