import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { LoadingContext, ModalContext } from "../../context/contextProvider";

import DashboardIcon from "@mui/icons-material/Dashboard";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import PlaceIcon from "@mui/icons-material/Place";
import AddButton from "../../components/buttons/AddButton";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import useCustomerLocationsDataTable from "../../hooks/useCustomerLocationsDataTable";
import Services from "../../services/services";
import Page from "../Page";
import CustomerLocationDetails from "./CustomerLocationDetails";

const title = "Customer Locations";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: HolidayVillageIcon,
    title: "Customers",
    to: AppRoutes.customers,
  },
  {
    IconComponent: PlaceIcon,
    title: "Locations",
  },
];

const CustomerLocationsPage = (props) => {
  const { id } = useParams();

  const { setLoading } = useContext(LoadingContext);
  const { showModal } = useContext(ModalContext);
  // data-tables - start
  // data-tables - end

  const [customerLocations, setCustomerLocations] = useState([]);
  const dataTableInfo = useCustomerLocationsDataTable(handleDeleted);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    if (!!id) getCustomerLocationsAsync(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerLocationsAsync = useCallback(async () => {
    setLoading(true);
    const response = await Services.api.customers.getLocations(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the locations",
        ThemeColorsEnum.error
      );

    setCustomerLocations(response.data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handleDeleted(customerLocation) {
    setCustomerLocations(
      customerLocations.filter((x) => x.id !== customerLocation.id)
    );
  }

  const handleRowClick = (event, row) => {
    event.stopPropagation();
    // opend the details modal
    showModal("Location Details", <CustomerLocationDetails customer={row} />, [
      {
        color: ThemeColorsEnum.quaternary,
        title: "Close",
      },
    ]);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  buttons={
                    <AddButton
                      to={AppRoutes.customerLocations_add.replace(":id", id)}
                      visible={userInfo?.canCreateCustomers}
                    />
                  }
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  //   conditionalRowStyles={conditionalRowStyles}
                  data={customerLocations}
                  emptyDataComponent={<EmptyDataBox />}
                  onRowClick={handleRowClick}
                  //   onSearch={handleSearch}
                  //   onSort={handleSort}
                  //   paginate
                  //   search
                  //   searchValue={searchValue}
                  //   sortBy={sortOptions.column}
                  //   sortDirection={sortOptions.direction}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default CustomerLocationsPage;
