import Endpoints from "../../constants/endpoints";
import httpService from "../httpService";

const branchDetails = async (id) => {
  const url = Endpoints.billing.branchDetails.replace("{id}", id);

  const response = await httpService.get(url);

  return response;
};

const clientBranches = async (id) => {
  const url = Endpoints.billing.clientBranches.replace("{id}", id);

  const response = await httpService.get(url);

  return response;
};

const enrolledClients = async () => {
  const url = Endpoints.billing.enrolledClients;

  const response = await httpService.get(url);

  return response;
};

const enrolledUsers = async () => {
  const url = Endpoints.billing.billableUsers;
  const response = await httpService.get(url);

  return response;
};

const trialUsers = async () => {
  const url = Endpoints.billing.trialUsers;
  const response = await httpService.get(url);

  return response;
};

//-----------------------------------------//
const blillingApiService = {
  branchDetails,
  clientBranches,
  enrolledClients,
  enrolledUsers,
  trialUsers,
};

export default blillingApiService;
//-----------------------------------------//
