import * as Yup from "yup";

import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { ThemeColorsEnum } from "../../../constants/enums";
import { LoadingContext } from "../../../context/contextProvider";
import Services from "../../../services/services";
import LoadingButton from "../../buttons/LoadingButton";
import Card from "../../cards/Card";
import AppForm from "../Form";
import FormGoogleAddressField from "../FormGoogleAddressField";
import FormTextField from "../FormTextField";
import SubmitButton from "../SubmitButton";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).required().label("Name"),
  latitude: Yup.number().min(-90).max(90).optional().label("Latitude"),
  longitude: Yup.number().min(-180).max(180).optional().label("Longitude"),
});

const CustomerLocationForm = ({
  customerLocation,
  hideSubmitButton,
  modal,
  onCancel = () => {},
  onSave = () => {},
  ...otherProps
}) => {
  const { busy, setBusy } = useContext(LoadingContext);

  const handleAddressChange = (text, place, { setFieldValue }) => {
    setFieldValue("name", place.name);
    setFieldValue("latitude", place.geometry.location.lat());
    setFieldValue("longitude", place.geometry.location.lng());
  };

  const handleSubmit = async (values) => {
    setBusy(true);
    const response = await Services.api.customers.saveLocation(
      customerLocation?.customerId,
      {
        ...customerLocation,
        ...values,
      }
    );
    setBusy(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the location",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast("Saved", ThemeColorsEnum.success);
    onSave(response.data);
  };

  return (
    <AppForm
      initialValues={{
        id: customerLocation?.id ?? 0,
        customerId: customerLocation?.customerId,
        name: customerLocation?.name ?? "",
        fullAddress: customerLocation?.fullAddress ?? "",
        latitude: customerLocation?.latitude ?? "",
        longitude: customerLocation?.longitude ?? "",
        notes: customerLocation?.notes ?? "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Card>
        <Card.Body>
          <Row>
            <Col lg={!modal ? 4 : 6}>
              <FormGoogleAddressField
                label="Full Address"
                name="fullAddress"
                onPlaceChange={handleAddressChange}
                placeholder="Search address..."
              />
            </Col>
            <Col lg={!modal ? 4 : 6}>
              <FormTextField label="Name" name="name" required />
            </Col>
          </Row>
          <Row>
            <Col lg={!modal ? 4 : 6}>
              <FormTextField
                label="Latitude"
                name="latitude"
                // required
                type="number"
              />
            </Col>
            <Col lg={!modal ? 4 : 6}>
              <FormTextField
                label="Longitude"
                name="longitude"
                // required
                type="number"
              />
            </Col>
          </Row>
        </Card.Body>

        {!hideSubmitButton &&
          ((!!modal && (
            <Card.Footer>
              <Row className="mt-3">
                <Col className="text-end">
                  <LoadingButton
                    className="me-2"
                    onClick={onCancel}
                    padding={10}
                    color={ThemeColorsEnum.quaternary}
                  >
                    Cancel
                  </LoadingButton>
                  <SubmitButton loading={busy}>Save</SubmitButton>
                </Col>
              </Row>
            </Card.Footer>
          )) || (
            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" loading={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          ))}
      </Card>
    </AppForm>
  );
};

export default CustomerLocationForm;
