import { AuthContext, LoadingContext } from "../../context/contextProvider";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { AppRoutes } from "../../constants/appRoutes";
import BuildIcon from "@mui/icons-material/Build";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import { Navigate } from "react-router-dom";
import ServiceOrderFormPage from "./ServiceOrderFormPage";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: BuildIcon,
    title: "Service Orders",
    to: AppRoutes.serviceOrders,
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const ServiceOrderEditPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();

  const [serviceOrder, setServiceOrder] = useState(state?.serviceOrder);
  const { setLoading } = useContext(LoadingContext);
  const { branchModules } = useContext(AuthContext);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    setServiceOrder(state?.serviceOrder);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (id && !state?.serviceOrder) getServiceOrderAsync(id);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getServiceOrderAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.serviceOrders.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the order",
        ThemeColorsEnum.error
      );

    setServiceOrder(response.data);
  };

  if (!branchModules?.serviceOrders || !userInfo.canEditDeliveryOrders)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <ServiceOrderFormPage
      breadcrumbs={breadcrumbs}
      serviceOrder={serviceOrder}
      title="Service Orders - Edit"
    />
  );
};

export default ServiceOrderEditPage;
