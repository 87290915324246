import { LocalStorageKeys } from "../constants/storageKeys";
import sessionStorageService from "./sessionStorageService";

const isLoggedIn = () => {
  const currentUser = sessionStorageService.getProperty(
    LocalStorageKeys.currentUser
  );
  const token = sessionStorageService.getProperty(LocalStorageKeys.accessToken);
  const isLoggedIn = currentUser !== null && token !== null;

  return isLoggedIn;
};

const authService = { isLoggedIn };

export default authService;
