import { Col, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import DropdownSelect from "../forms/DropdownSelect";
import Pagination from "react-bootstrap/Pagination";

const NSDataTablePagination = ({
  // properties
  from,
  lastPage,
  page = 1,
  pageSize,
  showRecordsInfo,
  to,
  total,
  // events
  onPageChange,
  onPageSizeChange,
  handleAll,
  ...otherProps
}) => {
  const [currentPage, setCurrentPage] = useState(page);
  const [rowsPerPage, setRowsPerPage] = useState(pageSize);
  const [showingAll, setShowingAll] = useState(false);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const handlePageSizeChange = (value, text) => {
    if (text === "Show All") {
      handleAll();
      setShowingAll(true);
    }
    else if (showingAll) { //user changed from showing all
      setShowingAll(false);
      handleAll(); //toggle
    }
    setRowsPerPage(value);
    onPageSizeChange(value);
  };

  const pageSizeItems = [
    { text: "10", value: 10 },
    { text: "25", value: 25 },
    { text: "50", value: 50 },
    { text: "100", value: 100 },
    { text: "Show All", value: total},
  ];

  return (
    <Row>
      <Col md={5} sm={12}>
        <div className="dataTables_info dataTables_length mt-1">
          <Row className="align-items-center">
            <Col>
              <DropdownSelect
                items={pageSizeItems}
                itemLabelProperty="text"
                itemValueProperty="value"
                onChange={handlePageSizeChange}
                // placeholder="Select a name"
                style={{ marginRight: 5 /*, width: 200*/ }}
                value={rowsPerPage}
              />
            </Col>
            <Col>
              <span>rows per page</span>
            </Col>
          </Row>
        </div>
        {showRecordsInfo && from != null && to != null && (
          <div className="dataTables_info" role="status" aria-live="polite">
            <span>
              Showing {from}-{to} of {total} rows
            </span>
          </div>
        )}
      </Col>
      <Col md={7} sm={12}>
        {lastPage > 1 && (
          <div
            className="dataTables_paginate paging_simple_numbers"
            {...otherProps}
          >
            <Pagination>
              {/* Previous */}
              <Pagination.Prev
                className="paginate_button"
                disabled={page === 1}
                onClick={() => handlePageChange(currentPage - 1)}
              >
                <span>Previous</span>
              </Pagination.Prev>

              {/* Pages */}
              <Pagination.Item
                active={currentPage === 1}
                className="paginate_button"
                onClick={() => handlePageChange(1)}
              >
                1
              </Pagination.Item>
              <Pagination.Ellipsis className="paginate_button" disabled />
              {currentPage !== 1 && currentPage !== lastPage && (
                <>
                  <Pagination.Item active className="paginate_button">
                    {currentPage}
                  </Pagination.Item>
                  <Pagination.Ellipsis className="paginate_button" disabled />
                </>
              )}
              <Pagination.Item
                active={currentPage === lastPage}
                className="paginate_button"
                onClick={() => handlePageChange(lastPage)}
              >
                {lastPage}
              </Pagination.Item>

              {/* Next */}
              <Pagination.Next
                className="paginate_button"
                disabled={currentPage === lastPage}
                onClick={() => handlePageChange(currentPage + 1)}
              >
                Next
              </Pagination.Next>
            </Pagination>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default NSDataTablePagination;
