import { add, compareAsc, parseISO, startOfMonth } from "date-fns";

const sanitizeBillableUsers = (billableUsers) => {
  const firstOfCurrentMonth = startOfMonth(new Date());
  const firstOfLastMonth = add(firstOfCurrentMonth, { months: -1 });

  return billableUsers?.map((userRow) => ({
    ...userRow,
    isNew: compareAsc(parseISO(userRow.createdDate), firstOfLastMonth) === 1,
    isDeleted:
      compareAsc(parseISO(userRow.deletedDate), firstOfLastMonth) === 1 &&
      compareAsc(parseISO(userRow.deletedDate), firstOfCurrentMonth) === -1,
  }));
};

//------------------------------------------------
const billingService = { sanitizeBillableUsers };

export default billingService;
//------------------------------------------------
