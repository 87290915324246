import React, { useContext } from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext } from "../../context/contextProvider";
import WorkOrdersMapPage from "../workOrders/WorkOrdersMapPage";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: OilBarrelIcon,
    title: "DirectDrops",
    to: AppRoutes.deliveryOrders,
  },
  {
    IconComponent: LocationOnIcon,
    title: "Map",
  },
];

const DirectDropsMapPage = (props) => {
  const { branchModules } = useContext(AuthContext);

  if (!branchModules?.directDrops)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <WorkOrdersMapPage
      breadcrumbs={breadcrumbs}
      directDrops
      title="Direct Drops - Map"
    ></WorkOrdersMapPage>
  );
};

export default DirectDropsMapPage;
