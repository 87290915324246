import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AuthContext, LoadingContext } from "../../context/contextProvider";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { Navigate, useNavigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import useEnrolledClientsDataTable from "../../hooks/useEnrolledClientsDataTable";
import Services from "../../services/services";
import Page from "../Page";

const title = "Enrolled Clients";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PointOfSaleIcon,
    title: title,
  },
];

const EnrolledClientsPage = (props) => {
  const [enrolledClients, setEnrolledClients] = useState([]);
  const [allEnrolledClients, setAllEnrolledClients] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { setLoading } = useContext(LoadingContext);
  const { currentClient, setEnrolledUsers } = useContext(AuthContext);

  const navigate = useNavigate();
  const userInfo = useCurrentUserInfo();
  const dataTableInfo = useEnrolledClientsDataTable();

  useEffect(() => {
    initAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const getEnrolledClientsAsync = async () => {
    const response = await Services.api.billing.enrolledClients();

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the billing info",
        ThemeColorsEnum.error
      );
    setEnrolledClients(response.data);
    setAllEnrolledClients(response.data);
  };

  const getEnrolledUsersAsync = async () => {
    const response = await Services.api.billing.enrolledUsers();

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the users",
        ThemeColorsEnum.error
      );

    const data = Services.billing.sanitizeBillableUsers(response.data);

    setEnrolledUsers(data);
  };

  const handleRowClick = (event, row) => {
    navigate(AppRoutes.billingClientBranches.replace(":id", row.id), {
      state: { client: row },
    });
  };

  const handleSearch = (searchText) => {
    if (!searchText) return setEnrolledClients(allEnrolledClients);

    setEnrolledClients(
      allEnrolledClients?.filter(
        (x) =>
          x.name?.toLowerCase().includes(searchText.toLowerCase()) ||
          x.city?.toLowerCase().includes(searchText.toLowerCase()) ||
          x.phone?.toLowerCase().includes(searchText.toLowerCase()) ||
          x.email?.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  const initAsync = async () => {
    setLoading(true);
    await getEnrolledClientsAsync();
    await getEnrolledUsersAsync();
    setLoading(false);
  };

  if (!userInfo?.isSuperAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  data={enrolledClients}
                  emptyDataComponent={<EmptyDataBox />}
                  onRowClick={handleRowClick}
                  onSearch={setSearchValue}
                  paginate
                  search
                  searchValue={searchValue}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default EnrolledClientsPage;
