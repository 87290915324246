import React from "react";

const DetailsRow = ({ title, details, visible = true, ...otherProps }) => {
  if (!visible) return null;

  return (
    <>
      <dt className="horizontal">{title}</dt>
      <dd className="horizontal">{details}</dd>
    </>
  );
};

export default DetailsRow;
