import { useContext, useEffect } from "react";

import { AuthContext } from "../context/contextProvider";
import { NodePositions } from "../constants/enums";
import Services from "../services/services";

const useImportScript = (
  resourceUrl,
  condition = true,
  position = NodePositions.Append
) => {
  const { branchModules } = useContext(AuthContext);

  useEffect(() => {
    if (condition) {
      const script = Services.utility.appendScript(resourceUrl, position);
      return () => {
        Services.utility.removeScript(script);
      };
    }
  }, [resourceUrl, condition, position, branchModules]);
};

export default useImportScript;
