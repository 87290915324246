import BreadcrumbItem from "./BreadcrumbItem";
import React from "react";

const Breadcrumbs = ({ items, title, ...otherProps }) => {
  return (
    <header className="page-header">
      <h2>{title}</h2>

      <div className="right-wrapper text-end">
        <ol className="breadcrumbs">
          {items?.map((item, index) => (
            <BreadcrumbItem
              href={item.to}
              key={index + item.title}
              icon={item.icon}
              IconComponent={item.IconComponent}
              title={item.title}
            />
          ))}
        </ol>
      </div>
    </header>
  );
};

export default Breadcrumbs;
