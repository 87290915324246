import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AuthContext, LoadingContext } from "../../context/contextProvider";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import useBillableUsersDataTable from "../../hooks/useBillableUsersDataTable";
import Services from "../../services/services";
import Page from "../Page";

const title = "Billable Users";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PeopleOutlineIcon,
    title: title,
  },
];

const EnrolledUsersPage = (props) => {
  const [enrolledUsersData, setEnrolledUsersData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const { setLoading } = useContext(LoadingContext);
  const { currentClient, enrolledUsers, setEnrolledUsers } =
    useContext(AuthContext);

  const dataTableInfo = useBillableUsersDataTable();

  useEffect(() => {
    getEnrolledUsersAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const getEnrolledUsersAsync = async () => {
    setLoading(true);
    const response = await Services.api.billing.enrolledUsers();
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the users",
        ThemeColorsEnum.error
      );

    let data = Services.billing
      .sanitizeBillableUsers(response.data)
      ?.filter((x) => !x.isDeleted);
    setEnrolledUsers(data);

    // calculate the monthly total
    const feeSum = data.reduce(
      (total, billingRow) => total + parseInt(billingRow.fee),
      0
    );

    // add the "total" row at the bottom
    data = [
      ...data,
      {
        branchName: "",
        createdDate: undefined,
        fee: feeSum,
        fullName: "",
        userName: `Total (${data?.length})`,
      },
    ];

    setEnrolledUsersData(data);
  };

  const handleSearch = (searchText) => {
    if (!searchText) return setEnrolledUsersData(enrolledUsers);

    setEnrolledUsersData(
      enrolledUsers?.filter(
        (x) =>
          x?.userName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          x?.fullName?.toLowerCase()?.includes(searchText.toLowerCase()) ||
          x?.branchName?.toLowerCase()?.includes(searchText.toLowerCase())
      )
    );
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  data={enrolledUsersData}
                  emptyDataComponent={<EmptyDataBox />}
                  onSearch={setSearchValue}
                  paginate
                  search
                  searchValue={searchValue}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default EnrolledUsersPage;
