import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AuthContext, LoadingContext } from "../../context/contextProvider";

import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Navigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import FieldInfo from "../../components/forms/FieldInfo";
import AppForm from "../../components/forms/Form";
import FormCheckboxField from "../../components/forms/FormCheckboxField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import Page from "../Page";

const title = "Modules";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: ViewModuleIcon,
    title: title,
  },
];

const ModulesPage = (props) => {
  const [liveBranchModules, setLiveBranchModules] = useState();

  const { currentClient, setBranchModules } = useContext(AuthContext);
  const { busy, setBusy, loading, setLoading } = useContext(LoadingContext);

  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    getBranchModulesAsync();
    return () => {};
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const getBranchModulesAsync = async () => {
    setLoading(true);
    const response = await Services.api.settings.getBranchModules();
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the modules",
        ThemeColorsEnum.error
      );

    setLiveBranchModules(response.data);
    setBranchModules(response.data?.modules);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const response = await Services.api.settings.saveBranchModules({
      ...liveBranchModules,
      modules: values,
    });
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the modules",
        ThemeColorsEnum.error,
        response
      );

    setBranchModules(response.data?.modules);
    Services.utility.toast("Modules saved!", ThemeColorsEnum.success);
  };

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={{
              workOrders: liveBranchModules?.modules?.workOrders,
              directDrops: liveBranchModules?.modules?.directDrops,
              serviceOrders: liveBranchModules?.modules?.serviceOrders,
              workAlone: liveBranchModules?.modules?.workAlone,
			  scheduledOrders: liveBranchModules?.modules?.scheduledOrders,
            }}
            onSubmit={handleSubmit}
          >
            <Row>
              <Col>
                <FormCheckboxField
                  description="Enable"
                  label="Delivery Orders"
                  name="workOrders"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Enable"
                  label="Direct Drops"
                  name="directDrops"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Enable"
                  label="Service Orders"
                  name="serviceOrders"
                />
              </Col>
              <Col>
                <FormCheckboxField
                  description="Enable"
                  label="Work-Alone"
                  name="workAlone"
                />
                <FieldInfo>Includes travel-alone and check-ins.</FieldInfo>
              </Col>
			  <Col>
				<FormCheckboxField
				  description="Enable"
				  label="Scheduled Orders"
				  name="scheduledOrders"
				/>
			  </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" disabled={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ModulesPage;
