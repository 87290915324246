import { AppRoutes } from "../../constants/appRoutes";
import CustomerForm from "../../components/forms/custom/CustomerForm";
import Page from "../Page";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustomerFormPage = ({
  breadcrumbs,
  customer = {},
  hideLocationForm,
  title,
  ...otherProps
}) => {
  const navigate = useNavigate();

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <CustomerForm
        customer={customer}
        hideLocationForm={hideLocationForm}
        onSave={() => navigate(AppRoutes.customers)}
      />
    </Page>
  );
};

export default CustomerFormPage;
