import { Navigate, useLocation, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { AppRoutes } from "../../constants/appRoutes";
import CustomerLocationFormPage from "./CustomerLocationFormPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import { LoadingContext } from "../../context/contextProvider";
import PlaceIcon from "@mui/icons-material/Place";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const CustomerLocationEditPage = (props) => {
  const { id, locationId } = useParams();
  const { state } = useLocation();
  const userInfo = useCurrentUserInfo();

  const [customerLocation, setCustomerLocation] = useState(
    state?.customerLocation
  );
  const { setLoading } = useContext(LoadingContext);

  const breadcrumbs = [
    {
      IconComponent: DashboardIcon,
      title: "Dashboard",
      to: AppRoutes.dashboard,
    },
    {
      IconComponent: HolidayVillageIcon,
      title: "Customers",
      to: AppRoutes.customers,
    },
    {
      IconComponent: PlaceIcon,
      title: "Locations",
      to: AppRoutes.customerLocations.replace(":id", id),
    },
    {
      IconComponent: EditIcon,
      title: "Edit",
    },
  ];

  useEffect(() => {
    if (id && locationId && !state?.customerLocation)
      getCustomerLocationAsync();
  }, [id, state]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerLocationAsync = async () => {
    setLoading(true);
    const response = await Services.api.customers.getLocation(id, locationId);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the location",
        ThemeColorsEnum.error
      );

    setCustomerLocation(response.data);
  };

  if (!userInfo?.canEditCustomers)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <CustomerLocationFormPage
      breadcrumbs={breadcrumbs}
      customerLocation={customerLocation}
      title="Customer Locations - Edit"
    />
  );
};

export default CustomerLocationEditPage;
