import React from "react";
import DetailsRow from "../../components/DetailsRow";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";

const CustomerLocationDetails = ({
  customer: customerLocation,
  ...otherProps
}) => {
  return (
    <>
      <Map
        centerPosition={{
          lat: customerLocation?.latitude,
          lng: customerLocation?.longitude,
        }}
        className="map-container-modal mb-3"
        zoom={7}
      >
        <AppMarker
          clickable
          info={
            <span className="info-window-text-title">
              {customerLocation.name}
            </span>
          }
          key={customerLocation.id}
          position={{
            lat: customerLocation.latitude,
            lng: customerLocation.longitude,
          }}
        />
      </Map>
      <dl className="horizontal">
        <DetailsRow title="Id" details={customerLocation.id} />
        <DetailsRow title="Name" details={customerLocation.name} />
        <DetailsRow
          title="Full Address"
          details={customerLocation.fullAddress}
          visible={!!customerLocation.fullAddress}
        />
        <DetailsRow
          title="Latitude"
          details={customerLocation.latitude}
          visible={!!customerLocation.latitude}
        />
        <DetailsRow
          title="Longitude"
          details={customerLocation.longitude}
          visible={!!customerLocation.longitude}
        />
        <DetailsRow
          title="Notes"
          details={customerLocation?.notes}
          visible={!!customerLocation?.notes}
        />
      </dl>
    </>
  );
};

export default CustomerLocationDetails;
