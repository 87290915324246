import { Col, InputGroup, Row } from "react-bootstrap";
import { ElementSize, ThemeColorsEnum } from "../../constants/enums";
import React, { useContext } from "react";
import { useField, useFormikContext } from "formik";

import AddIcon from "@mui/icons-material/Add";
import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import AppSelect from "./Select";
import CircleButton from "../buttons/CircleButton";
import FieldInfo from "./FieldInfo";
import FormFieldContainer from "./FormFieldContainer";
import { ModalContext } from "../../context/contextProvider";
import ServiceCategoryForm from "./custom/ServiceCategoryForm";

const FormServiceCategorySelect = ({
  clearable,
  disabled = false,
  hidePlusButton,
  icon,
  info,
  initialText,
  itemLabelProperty,
  items = [],
  itemValueProperty,
  label,
  LabelComponent,
  onChange = () => {},
  placeholder,
  required,
  searchable = false,
  size = ElementSize.md,
  visible = true,
  ...otherProps
}) => {
  const { showModal, closeModal } = useContext(ModalContext);

  const formik = useFormikContext();
  const [field] = useField({ name: otherProps.name, value: otherProps.value });

  const { errors, handleBlur, handleChange, setFieldValue, touched, values } =
    formik;

  if (!visible) return null;

  const handleCategorySave = (category) => {
    items.push(category);
    closeModal();

    handleSelect(category);
  };

  const handleAddCategory = () => {
    showModal(
      `New Service Category`,
      null,
      null,
      <ServiceCategoryForm
        modal
        onCancel={closeModal}
        onSave={handleCategorySave}
      />,
      "lg"
    );
  };

  const handleSelect = (item) => {

    setFieldValue(field.name, item?.[itemValueProperty]);

    handleBlur(field.name);
    handleChange(field.name);
    onChange(item, formik);
  };

  return (
    <FormFieldContainer>
      <Row>
        <Col lg={10}>
          <AppFormLabel label={label} required={required} visible={!!label} />
          {LabelComponent}
        </Col>
        {!hidePlusButton && (
          <Col lg={2} className="text-right">
            <CircleButton
              color={ThemeColorsEnum.quaternary}
              size={25}
              IconComponent={() => (
                <AddIcon className="cur-pointer" style={{ fontSize: 20 }} />
              )}
              onClick={handleAddCategory}
            />
          </Col>
        )}
      </Row>
      <InputGroup>
        <AppSelect
          clearable={clearable}
          initialText={initialText}
          itemLabelProperty={itemLabelProperty}
          items={items}
          itemValueProperty={itemValueProperty}
          name={field.name}
          onBlur={handleBlur(field.name)}
          onChange={handleSelect}
          placeholder={placeholder}
          searchable={searchable}
          value={field.value}
          // value={selectedItem}
          // {...field}
          {...otherProps}
        />
        {icon && (
          <span className="input-group-text">
            <i className={icon}></i>
          </span>
        )}
      </InputGroup>
      <div>
        <AppErrorMessage
          error={errors?.[field.name]}
          visible={!!touched?.[field.name] && !!errors?.[field.name]}
        />
      </div>
      <FieldInfo visible={!!info}>{info}</FieldInfo>
    </FormFieldContainer>
  );
};

export default FormServiceCategorySelect;
