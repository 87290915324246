import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const create = async (payload) => {
  const url = Endpoints.tattles.create;
  const response = await httpService.post(url, {
    ...payload,
  });

  return response;
};

/**
 *
 * @param {int} id The product id
 * @returns
 */
const deleteTattle = async (id) => {
  const url = Endpoints.tattles.delete.replace("{id}", id);
  const response = await httpService.delete(url);

  return response;
};

const get = async (id) => {
  const url = Endpoints.tattles.get.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const getTattleInfo = async (id) => {
  const url = Endpoints.tattles.deviceInfo.replace("{uniqueId}", id);
  const response = await httpService.get(url);

  return response;
};

/**
 *
 * @param {SearchFilterRequest} filter
 * @returns
 */
const getTattles = async (filter, allBranches = false) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.tattles.filter.replace(
    "{clientId}",
    allBranches ? "" : clientId
  );
  const response = await httpService.post(url, filter);

  return response;
};

const save = async (payload) => {
  return payload?.id > 0 ? await update(payload) : await create(payload);
};

const update = async (payload) => {
  const url = Endpoints.tattles.update.replace("{id}", payload?.id);
  const response = await httpService.put(url, {
    ...payload,
  });

  return response;
};

//-----------------------------------------//
const tattlesApiService = {
  delete: deleteTattle,
  get,
  getTattleInfo,
  getTattles,
  save,
};

export default tattlesApiService;
//-----------------------------------------//
