import { Col, Modal, Row } from "react-bootstrap";
import React, { useContext } from "react";

import Card from "../cards/Card";
import LoadingButton from "../buttons/LoadingButton";
import { ModalContext } from "../../context/contextProvider";
import PropTypes from "prop-types";
import { ThemeColorsEnum } from "../../constants/enums";

const ModalDialog = ({
  body,
  buttons,
  color = ThemeColorsEnum.default,
  content,
  onClose = () => {},
  size = "md",
  title = null,
  transparent = true,
  visible,
  ...otherProps
}) => {
  const { closeModal } = useContext(ModalContext);

  const handleButtonClicked = (button) => {
    closeModal();
    if (button.onClick) button.onClick();
  };

  const handleClose = () => {
    closeModal();
    onClose();
  };

  return (
    <Modal
      size={size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...otherProps}
    >
      <Card color={color}>
        {title && (
          <Card.Header>
            <h2 className="card-title">{title}</h2>
          </Card.Header>
        )}
        {/* if you pass content it will be used instead of body and buttons */}
        {!!content ? (
          content
        ) : (
          <>
            <Card.Body>{body}</Card.Body>
            {buttons && (
              <Card.Footer>
                <Row>
                  <Col className="text-end">
                    {buttons?.map((button, index) => (
                      <LoadingButton
                        className="ms-2"
                        disabled={button.disabled}
                        key={`${button.title}_${index}`}
                        onClick={() => handleButtonClicked(button)}
                        padding={10}
                        color={button.color}
                      >
                        {button.title}
                      </LoadingButton>
                    ))}
                  </Col>
                </Row>
              </Card.Footer>
            )}
          </>
        )}
      </Card>
    </Modal>
  );
};

ModalDialog.propTypes = {
  isFeatured: PropTypes.bool,
};

export default ModalDialog;
