import Endpoints from "../../constants/endpoints";
import httpService from "../httpService";

const create = async (payload) => {
  const url = Endpoints.alerts.create;
  const response = await httpService.post(url, payload);

  return response;
};

/**
 *
 * @param {int} id The product id
 * @returns
 */
const deleteAlert = async (id) => {
  const url = Endpoints.alerts.delete.replace("{id}", id);
  const response = await httpService.delete(url);

  return response;
};

const filter = async (filter) => {
  const url = Endpoints.alerts.filter;
  const response = await httpService.post(url, filter);

  return response;
};

const get = async (id) => {
  const url = Endpoints.alerts.get.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const latest = async () => {
  const response = await httpService.get(Endpoints.alerts.latest);

  return response;
};

const save = async (payload) => {
  return payload?.id > 0 ? await update(payload) : await create(payload);
};

const update = async (payload) => {
  const url = Endpoints.alerts.update.replace("{id}", payload?.id);
  const response = await httpService.put(url, payload);

  return response;
};

//-----------------------------------------//
const alertsApiService = {
  delete: deleteAlert,
  get,
  filter,
  latest,
  save,
};

export default alertsApiService;
//-----------------------------------------//
