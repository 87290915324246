import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import CustomerFormPage from "./CustomerFormPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import { Navigate } from "react-router-dom";
import React from "react";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const CustomerCreatePage = (props) => {
  const userInfo = useCurrentUserInfo();

  const breadcrumbs = [
    {
      IconComponent: DashboardIcon,
      title: "Dashboard",
      to: AppRoutes.dashboard,
    },
    {
      IconComponent: HolidayVillageIcon,
      title: "Customers",
      to: AppRoutes.customers,
    },
    {
      IconComponent: AddIcon,
      title: "New",
    },
  ];

  if (!userInfo?.canCreateCustomers)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return <CustomerFormPage breadcrumbs={breadcrumbs} title="Customers - New" />;
};

export default CustomerCreatePage;
