import { RolesEnum, ThemeColorsEnum } from "../constants/enums";
import { useContext, useEffect, useState } from "react";

import { AuthContext } from "../context/contextProvider";
import Services from "../services/services";

const useCurrentUserInfo = (refresh = false) => {
  const { branchSettings, currentUser } = useContext(AuthContext);

  const [hasAdminRole, setHasAdminRole] = useState(
    !refresh && currentUser?.roles?.includes(RolesEnum.admin)
  );
  const [hasDriverRole, setHasDriverRole] = useState(
    !refresh && currentUser?.roles?.includes(RolesEnum.driver)
  );
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    !refresh && currentUser?.roles?.includes(RolesEnum.superAdmin)
  );
  const [isAdmin, setIsAdmin] = useState(
    !refresh &&
      (currentUser?.roles?.includes(RolesEnum.superAdmin) ||
        currentUser?.roles?.includes(RolesEnum.admin))
  );

  // Permissions on Delivery Orders
  const [canAssignDeliveryOrders, setCanAssignDeliveryOrders] = useState(
    isAdmin || branchSettings?.driversPermissions?.canAssignDeliveryOrders
  );
  const [canCreateDeliveryOrders, setCanCreateDeliveryOrders] = useState(
    isAdmin || branchSettings?.driversPermissions?.canCreateDeliveryOrders
  );
  const [canEditDeliveryOrders, setCanEditDeliveryOrders] = useState(
    isAdmin || branchSettings?.driversPermissions?.canEditDeliveryOrders
  );
  const [canMoveDeliveryOrders, setCanMoveDeliveryOrders] = useState(
    isAdmin || branchSettings?.driversPermissions?.canMoveDeliveryOrders
  );
  const [canDeleteDeliveryOrders, setCanDeleteDeliveryOrders] = useState(
    isAdmin || branchSettings?.driversPermissions?.canDeleteDeliveryOrders
  );

  // Permissions on Customers
  const [canCreateCustomers, setCanCreateCustomers] = useState(
    isAdmin || branchSettings?.driversPermissions?.canCreateCustomers
  );
  const [canEditCustomers, setCanEditCustomers] = useState(
    isAdmin || branchSettings?.driversPermissions?.canEditCustomers
  );
  const [canDeleteCustomers, setCanDeleteCustomers] = useState(
    isAdmin || branchSettings?.driversPermissions?.canDeleteCustomers
  );

  useEffect(() => {
    if (refresh) {
      refreshCurrentUserAsync();
    }
  }, [refresh]);

  useEffect(() => {
    // Permissions on Delivery Orders
    setCanAssignDeliveryOrders(
      isAdmin || branchSettings?.driversPermissions?.canAssignDeliveryOrders
    );
    setCanCreateDeliveryOrders(
      isAdmin || branchSettings?.driversPermissions?.canCreateDeliveryOrders
    );
    setCanEditDeliveryOrders(
      isAdmin || branchSettings?.driversPermissions?.canEditDeliveryOrders
    );
    setCanMoveDeliveryOrders(
      isAdmin || branchSettings?.driversPermissions?.canMoveDeliveryOrders
    );
    setCanDeleteDeliveryOrders(
      isAdmin || branchSettings?.driversPermissions?.canDeleteDeliveryOrders
    );
    // Permissions on Customers
    setCanCreateCustomers(
      isAdmin || branchSettings?.driversPermissions?.canCreateCustomers
    );
    setCanEditCustomers(
      isAdmin || branchSettings?.driversPermissions?.canEditCustomers
    );
    setCanDeleteCustomers(isAdmin);
  }, [branchSettings, isAdmin]);

  const refreshCurrentUserAsync = async () => {
    const response = await Services.api.account.me();

    if (!response.ok)
      return Services.utility.toast(
        "Could not get user preferences",
        ThemeColorsEnum.error
      );

    const user = response.data;

    setHasAdminRole(user?.roles?.includes(RolesEnum.admin));
    setHasDriverRole(user?.roles?.includes(RolesEnum.driver));
    setIsSuperAdmin(user?.roles?.includes(RolesEnum.superAdmin));
    setIsAdmin(
      user?.roles?.includes(RolesEnum.superAdmin) ||
        user?.roles?.includes(RolesEnum.admin)
    );
  };

  return {
    canAssignDeliveryOrders,
    canCreateDeliveryOrders,
    canDeleteDeliveryOrders,
    canEditDeliveryOrders,
    canMoveDeliveryOrders,

    canCreateCustomers,
    canEditCustomers,
    canDeleteCustomers,

    hasAdminRole,
    hasDriverRole,
    isAdmin,
    isSuperAdmin,
  };
};

export default useCurrentUserInfo;
