import { StatusCodes } from "http-status-codes";
import { useContext } from "react";
import { LocalStorageKeys } from "../constants/storageKeys";
import { AuthContext } from "../context/contextProvider";
import Services from "../services/services";

const useAuthentication = () => {
  const {
    setBranchModules,
    setBranchSettings,
    setCurrentUser,
    setCurrentClient,
  } = useContext(AuthContext);

  const logIn = async (credentialsPayload) => {
    const response = await Services.api.auth.token(credentialsPayload);

    if (response.ok && response.status === StatusCodes.OK) {
      if (response.data?.access_token) {
        const userResponse = await Services.api.account.me();

        if (userResponse.ok) {
          setCurrentUser(
            Services.localStorage.getProperty(LocalStorageKeys.currentUser)
          );
          setCurrentClient(
            Services.localStorage.getProperty(LocalStorageKeys.currentClient)
          );
        }

        return userResponse;
      }
    }

    return response;
  };

  const logOut = async () => {
    setCurrentClient(null);
    setCurrentUser(null);
    setBranchSettings(null);
    setBranchModules(null);

    const response = await Services.api.auth.logOut();

    return response;
  };

  return { logIn, logOut };
};

export default useAuthentication;
