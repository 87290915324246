import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { LoadingContext } from "../../context/contextProvider";
import ServiceOrderCategoryFormPage from "./ServiceOrderCategoryFormPage";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: FormatListBulletedIcon,
    title: "Service Categories",
    to: AppRoutes.serviceOrderCategories,
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const ServiceOrderCategoryEditPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();

  const [category, setCategory] = useState(state?.category);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (id && !state?.category) getCategoryAsync(id);
  }, [id, state]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoryAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.serviceCategories.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the category",
        ThemeColorsEnum.error
      );

    setCategory(response.data);
  };

  return (
    <ServiceOrderCategoryFormPage
      breadcrumbs={breadcrumbs}
      category={category}
      title="Service Categories - Edit"
    />
  );
};

export default ServiceOrderCategoryEditPage;
