import React from "react";

const SidebarNavRight = ({ children: content, ...otherProps }) => {
  return (
    <aside id="sidebar-right" className="sidebar-right">
      <div className="nano">
        <div className="nano-content">
          <a href="/#" className="mobile-close d-md-none">
            Collapse <i className="fa fa-chevron-right"></i>
          </a>

          <div className="sidebar-right-wrapper">{content}</div>
        </div>
      </div>
    </aside>
  );
};

export default SidebarNavRight;
