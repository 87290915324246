import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Navigate } from "react-router-dom";
import PeopleIcon from "@mui/icons-material/People";
import React from "react";
import Schemas from "../../constants/schemas";
import UserFormPage from "./UserFormPage";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PeopleIcon,
    title: "Users",
    to: AppRoutes.users,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const UserCreatePage = (props) => {
  const userInfo = useCurrentUserInfo();

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <UserFormPage
      breadcrumbs={breadcrumbs}
      title="Users - New"
      validationSchema={Schemas.createUser}
    />
  );
};

export default UserCreatePage;
