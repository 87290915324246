import { Col, Row } from "react-bootstrap";

import { Formik } from "formik";
import React from "react";

const AppForm = ({
  enableReinitialize = true,
  initialValues,
  onSubmit = () => {},
  validationSchema,
  ...otherProps
}) => {
  return (
    <Formik
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange
      {...otherProps}
    >
      {(props) => (
        <form>
          <div>{otherProps.children}</div>
          {(!process.env.NODE_ENV ||
            process.env.NODE_ENV === "development") && (
            <blockquote>
              <Row>
                <Col>
                  <b>Values</b>: {JSON.stringify(props.values, null, 2)}
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Errors</b>: {JSON.stringify(props.errors, null, 2)}
                </Col>
              </Row>
            </blockquote>
          )}
        </form>
      )}
    </Formik>
  );
};

export default AppForm;
