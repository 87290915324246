import { Form } from "react-bootstrap";
import { LocalStorageKeys } from "../constants/storageKeys";
import React from "react";
import Services from "../services/services";

const AlertBox = ({ alert, preview, ...otherProps }) => {
  const handleCheckboxChange = () => {
    if (!!preview) return;

    Services.localStorage.setProperty(LocalStorageKeys.hideAlertId, alert?.id);
  };

  return (
    <span style={{ color: "white" }}>
      <span style={{ fontWeight: "bold" }}>{alert?.title}</span>
      <br />
      {alert?.notes}
      <br />
      <i>
        <Form.Check
          label="Don't show this again"
          onChange={handleCheckboxChange}
        />
      </i>
    </span>
  );
};

export default AlertBox;
