import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  CustomerColumnsEnum,
  RolesEnum,
  SortDirections,
  ThemeColorsEnum,
} from "../../constants/enums";
import { AuthContext, LoadingContext } from "../../context/contextProvider";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import DashboardIcon from "@mui/icons-material/Dashboard";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import PlaceIcon from "@mui/icons-material/Place";
import { debounce } from "lodash";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import AppSelect from "../../components/forms/Select";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import { AppRoutes } from "../../constants/appRoutes";
import useCurrentPosition from "../../hooks/useCurrentPosition";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import Page from "../Page";

const title = "Customers - Map";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: HolidayVillageIcon,
    title: "Customers",
    to: AppRoutes.customers,
  },
  {
    IconComponent: PlaceIcon,
    title: "Map",
  },
];

const CustomersMapPage = (props) => {
  const [currentPosition, setCurrentPosition] = useState();
  const [customers, setCustomers] = useState([]);
  const [customerPredictions, setCustomerPredictions] = useState([]);
  const [allCustomersLocations, setAllCustomersLocations] = useState([]);
  const [customersLocations, setCustomersLocations] = useState([]);
  const [driversGeolocations, setDriversGeolocations] = useState([]);
  const [showAllCustomers, setShowAllCustomers] = useState(false);
  const [zoom, setZoom] = useState(7);

  const { currentClient } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const { position, positionLoaded } = useCurrentPosition();
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    initAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setCurrentPosition(position);
  }, [position]);

  const getDriversAndTechsGeolocationsAsync = async () => {
    const response = await Services.api.users.getLastKnownLocations([
      RolesEnum.driver,
      RolesEnum.serviceTech,
    ]);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch drivers/techs locations",
        ThemeColorsEnum.error
      );
    setDriversGeolocations(response.data);
  };

  const getGeolocationsAsync = async () => {
    const response = await Services.api.customers.getAllCustomersLocations();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the pins",
        ThemeColorsEnum.error
      );

    if (showAllCustomers) setCustomersLocations(response.data);
    setAllCustomersLocations(response.data);
  };

  const handleCustomerInputChange = async (text) => {
    if (!text) return setCustomerPredictions(customers);
    text = text.toLowerCase();

    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(text);
    filter.start = 0;
    filter.length = 20;
    filter.order[0].column = CustomerColumnsEnum.company;
    filter.order[0].dir = SortDirections.ascending;

    // setLoading(true);
    const response = await Services.api.customers.getCustomers(filter);
    // setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not get the customers",
        ThemeColorsEnum.error
      );

    setCustomerPredictions(response.data?.data);
    // const matches = Services.utility.getCustomerPredictions(customers, text);
    // setCustomerPredictions(matches);
  };

  const handleCustomerInputChangeWithDebounce = useMemo(
    () =>
      debounce((text) => {
        handleCustomerInputChange(text?.trim());
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleLoadAllChange = (event) => {
    const checked = event.target?.checked;
    setShowAllCustomers(checked);
    if (!!checked) setCustomersLocations(allCustomersLocations);
    else setCustomersLocations([]);
  };

  const handleMarkerClick = (position) => {
    setCurrentPosition(position);
    setZoom(10);
  };

  const handleSelectCustomer = (customer) => {
    if (!customer)
      return setCustomersLocations(
        showAllCustomers ? allCustomersLocations : []
      );

    setShowAllCustomers(false);
    const locations = allCustomersLocations.filter(
      (x) => x.customerId === customer.id
    );
    setCustomersLocations(locations);

    if (locations?.length > 0)
      return setCurrentPosition({
        lat: locations[0].latitude,
        lng: customer[0].longitude,
      });

    //else
    Services.utility.toast(
      `No location available for ${customer.displayName}`,
      ThemeColorsEnum.warning
    );
  };

  const initAsync = async () => {
    setLoading(true);
    setCustomerPredictions([]);
    await getGeolocationsAsync();
    if (userInfo.isAdmin) await getDriversAndTechsGeolocationsAsync();
    setLoading(false);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row className="mb-2">
        <Col>
          <Form.Check
            checked={showAllCustomers}
            className="checkbox-custom checkbox-warning"
            label="Show all customers"
            name="test"
            onChange={handleLoadAllChange}
            type="checkbox"
            value={1}
          />
        </Col>
        <Col lg={4} md={6} sm={12}>
          <AppSelect
            allItems={customers}
            autocomplete
            itemLabelProperty="company"
            items={customerPredictions}
            itemValueProperty="id"
            clearable
            onChange={handleSelectCustomer}
            onInputChange={handleCustomerInputChangeWithDebounce}
            placeholder="Search customers..."
            searchable
          />
        </Col>
      </Row>
      {!!positionLoaded && (
        <Map
          centerPosition={currentPosition}
          className="customers-map-container"
          zoom={zoom}
        >
          {customersLocations?.map((customerLocation, index) => (
            <AppMarker
              clickable
              info={
                <>
                  <span className="info-window-text-title">
                    {customerLocation.customerDisplayName}
                  </span>
                  <br />
                  <span className="info-window-text">
                    {customerLocation.name}
                  </span>
                </>
              }
              key={`${customerLocation.id}_${index}`}
              onClick={handleMarkerClick}
              position={{
                lat: customerLocation.latitude,
                lng: customerLocation.longitude,
              }}
            />
          ))}
          {driversGeolocations?.map((driverGeolocation, index) => (
            <AppMarker
              clickable
              icon={`${process.env.PUBLIC_URL}/img/ic_pin_truck.png`}
              info={
                <>
                  <span className="info-window-text-title">
                    {driverGeolocation.displayName}
                  </span>
                  <br />
                  <span className="info-window-text">
                    {driverGeolocation.createdDateFormatted}
                  </span>
                  <br />
                  <Link
                    state={{
                      workOrder: { assignedToUserId: driverGeolocation.id },
                    }}
                    to={AppRoutes.deliveryOrders_create}
                  >
                    Create new order
                  </Link>
                </>
              }
              key={driverGeolocation.id}
              onClick={handleMarkerClick}
              position={{
                lat: driverGeolocation.geolocation?.location?.latitude,
                lng: driverGeolocation.geolocation?.location?.longitude,
              }}
            />
          ))}
        </Map>
      )}
    </Page>
  );
};

export default CustomersMapPage;
