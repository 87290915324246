import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ThemeColorsEnum } from "../../../constants/enums";
import { AuthContext, LoadingContext } from "../../../context/contextProvider";

import useCurrentUserInfo from "../../../hooks/useCurrentUserInfo";
import Services from "../../../services/services";
import LoadingButton from "../../buttons/LoadingButton";
import Card from "../../cards/Card";
import AppForm from "../Form";
import FormSelectField from "../FormSelectField";
import FormTextField from "../FormTextField";
import SubmitButton from "../SubmitButton";

const UserForm = ({
  modal,
  onCancel = () => {},
  onSave = () => {},
  user,
  validationSchema,
  ...otherProps
}) => {
  const [roles, setRoles] = useState();

  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const { busy, setBusy, setLoading } = useContext(LoadingContext);

  const userInfo = useCurrentUserInfo();

  // const rolesArray =
  //   (!!user?.roleNames ? user?.roleNames?.split(",") : user?.roles) ?? [];

  // const isAdmin = rolesArray?.find((x) => x.trim() === RolesEnum.admin);

  useEffect(() => {
    getRolesAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getRolesAsync = async () => {
    setLoading(true);
    const response = await Services.api.roles.all();
    setLoading(false);

    setRoles(response.data);
  };

  const handleSubmit = async (values) => {
    setBusy(true);
    const response = await Services.api.users.save({
      ...user,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      password: values.password,
      confirmPassword: values.confirmPassword,
      phoneNumber: values.phoneNumber,
      roles: values.roles,
      userName: values.userName,
    });
    setBusy(false);

    if (!response.ok)
      return Services.utility.toast(
        `Could not save the user.\n${Services.log.extractErrorMessage(
          response.data
        )}`.trim(),
        ThemeColorsEnum.error,
        response
      );

    // update current user settings globally
    if (currentUser?.id === response.data?.id) setCurrentUser(response.data);

    Services.utility.toast("Saved", ThemeColorsEnum.success);
    onSave(response.data);
  };

  const FormColumn = ({ children, ...otherProps }) =>
    !!modal ? (
      <Col lg={6} md={6} sm={12}>
        {children}
      </Col>
    ) : (
      <Col xl={3} lg={6} md={6} sm={12}>
        {children}
      </Col>
    );

  return (
    <AppForm
      initialValues={{
        email: user?.email ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName ?? "",
        password: "",
        confirmPassword: "",
        phoneNumber: user?.phoneNumber ?? "",
        roles: user?.roles ?? "",
        userName: user?.userName ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Card.Body>
        <Row>
          <FormColumn>
            <FormTextField label="Email" name="email" required type="email" />
          </FormColumn>
          <FormColumn>
            <FormTextField label="Username" required name="userName" />
          </FormColumn>
        </Row>
        <Row>
          <FormColumn>
            <FormTextField
              autoComplete="off"
              label="Password"
              name="password"
              required={!user?.id}
              type="password"
            />
          </FormColumn>
          <FormColumn>
            <FormTextField
              autoComplete="off"
              label="Confirm Password"
              name="confirmPassword"
              required={!user?.id}
              type="password"
            />
          </FormColumn>
        </Row>
        <Row>
          <FormColumn>
            <FormTextField label="First Name" name="firstName" required />
          </FormColumn>
          <FormColumn>
            <FormTextField label="Last Name" name="lastName" required />
          </FormColumn>
          <FormColumn>
            <FormTextField
              label="Phone"
              name="phoneNumber"
              required
              type="tel"
            />
          </FormColumn>
        </Row>
        <Row>
          <FormColumn>
            <FormSelectField
              itemLabelProperty="label"
              items={roles}
              itemValueProperty="value"
              label="Role(s)"
              multi
              name="roles"
              required
              visible={!!userInfo.isAdmin}
            />
          </FormColumn>
        </Row>
      </Card.Body>

      {(!!modal && (
        <Card.Footer>
          <Row className="mt-3">
            <Col className="text-end">
              <LoadingButton
                className="me-2"
                onClick={onCancel}
                padding={10}
                color={ThemeColorsEnum.quaternary}
              >
                Cancel
              </LoadingButton>
              <SubmitButton loading={busy}>Save</SubmitButton>
            </Col>
          </Row>
        </Card.Footer>
      )) || (
        <div className="form-group mb-0 fixed-to-bottom">
          <Row>
            <Col lg={2} md={4} sm={6}>
              <SubmitButton className="btn-block" loading={busy}>
                Save
              </SubmitButton>
            </Col>
          </Row>
        </div>
      )}
    </AppForm>
  );
};

export default UserForm;
