import {
  AlertColumnsEnum,
  SortDirections,
  ThemeColorsEnum,
} from "../../../constants/enums";
import { AuthContext, LoadingContext } from "../../../context/contextProvider";
import {
  Search,
  SearchFilterRequest,
} from "../../../models/searchFilterRequest";

import DashboardIcon from "@mui/icons-material/Dashboard";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import AlertBox from "../../../components/AlertBox";
import AddButton from "../../../components/buttons/AddButton";
import Card from "../../../components/cards/Card";
import EmptyDataBox from "../../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../../components/dataTables/NSDataTable";
import NSDataTablePagination from "../../../components/dataTables/NSDataTablePagination";
import { AppRoutes } from "../../../constants/appRoutes";
import Constants from "../../../constants/constants";
import useAlertsDataTable from "../../../hooks/useAlertsDataTable";
import useCurrentUserInfo from "../../../hooks/useCurrentUserInfo";
import Services from "../../../services/services";
import Page from "../../Page";

const title = "Alerts";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: SpeakerNotesIcon,
    title: title,
  },
];

const AlertsPage = () => {
  const { setLoading } = useContext(LoadingContext);
  const { currentClient, cache, setCache } = useContext(AuthContext);

  // data-tables - start
  const [currentPage, setCurrentPage] = useState(
    cache?.alerts?.currentPage ?? 1
  );
  const [pageSize, setPageSize] = useState(
    cache?.alerts?.pageSize ?? Constants.defaults.rowsPerPage
  );
  const [paginationData, setPaginationData] = useState({
    from: null,
    pages: null,
    records: null,
    to: null,
  });
  const [searchValue, setSearchValue] = useState(cache?.alerts?.search ?? "");
  const [sortOptions, setSortOptions] = useState({
    column: cache?.alerts?.sortColumn ?? AlertColumnsEnum.company,
    direction: cache?.alerts?.sortDirection ?? SortDirections.ascending,
  });
  // data-tables - end

  const [alerts, setAlerts] = useState([]);

  const dataTableInfo = useAlertsDataTable(handleDeleted);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    setCache({
      ...cache,
      alerts: {
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchValue,
        sortColumn: sortOptions.column,
        sortDirection: sortOptions.direction,
      },
    });
    getAlertsAsync((currentPage - 1) * pageSize, pageSize, searchValue);
    return () => {};
  }, [currentClient, currentPage, pageSize, searchValue, sortOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAlertsAsync = async (
    start = 0,
    take = Constants.defaults.rowsPerPage,
    searchText = null
  ) => {
    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(searchText);
    filter.start = start;
    filter.length = take;
    filter.order[0].column = sortOptions.column ?? AlertColumnsEnum.createdDate;
    filter.order[0].dir = sortOptions.direction ?? SortDirections.descending;

    setLoading(true);
    const response = await Services.api.alerts.filter(filter);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the alerts",
        ThemeColorsEnum.error
      );

    setAlerts(response.data?.data);
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      pages: response.data?.pagesTotal,
      records: response.data?.recordsTotal,
      to:
        currentPage * pageSize > response.data?.recordsTotal
          ? response.data?.recordsTotal
          : currentPage * pageSize,
    });
  };

  function handleDeleted(alert) {
    setAlerts(alerts.filter((x) => x.id !== alert.id));
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (event, row) => {
    event.stopPropagation();

    Services.utility.toast(
      <AlertBox alert={row} preview />,
      row.type ?? ThemeColorsEnum.default,
      null,
      true,
      {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleRowsPerPageChange = (newPerPage, currentPage) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  const handleSort = (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });

    // ----- start updating the page cache
    setCache({
      ...cache,
      alerts: {
        ...cache.alerts,
        sortColumn: column,
        sortDirection: direction,
      },
    });
    // ----- end updating cache
  };

  if (!userInfo?.isSuperAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <div className="dataTables_wrapper dt-bootstrap5 no-footer">
            {/* **** BOOTSTRAP PAGINATION **** */}
            <NSDataTablePagination
              from={paginationData?.from}
              lastPage={paginationData?.pages}
              page={currentPage}
              pageSize={pageSize}
              total={paginationData?.records}
              to={paginationData?.to}
              onPageChange={handlePageChange}
              onPageSizeChange={(newPerPage) =>
                handleRowsPerPageChange(newPerPage, currentPage)
              }
            />
            {/* **** DATA TABLE **** */}
            <NSDataTable
              buttons={<AddButton to={AppRoutes.alerts_create} />}
              className="dataTable table-no-more"
              // dense
              columns={dataTableInfo.columns}
              conditionalRowStyles={dataTableInfo.conditionalRowStyles}
              data={alerts}
              emptyDataComponent={<EmptyDataBox />}
              onRowClick={handleRowClick}
              onSearch={handleSearch}
              onSort={handleSort}
              paginate
              search
              searchValue={searchValue}
              sortBy={sortOptions.column}
              sortDirection={sortOptions.direction}
            />
            {/* **** BOOTSTRAP PAGINATION **** */}
            <NSDataTablePagination
              from={paginationData?.from}
              lastPage={paginationData?.pages}
              page={currentPage}
              pageSize={pageSize}
              showRecordsInfo
              total={paginationData?.records}
              to={paginationData?.to}
              onPageChange={handlePageChange}
              onPageSizeChange={(newPerPage) =>
                handleRowsPerPageChange(newPerPage, currentPage)
              }
            />
          </div>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default AlertsPage;
