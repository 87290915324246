import { Col, Modal, Row } from "react-bootstrap";
import React, { useContext } from "react";

import Card from "../cards/Card";
import LoadingButton from "../buttons/LoadingButton";
import { ModalContext } from "../../context/contextProvider";
import PropTypes from "prop-types";
import { ThemeColorsEnum } from "../../constants/enums";

const ConfirmationDialog = ({
  buttons,
  color = ThemeColorsEnum.default,
  message = "",
  onClose = () => {},
  title = null,
  transparent = true,
  visible,
  ...otherProps
}) => {
  const { closeConfirmation } = useContext(ModalContext);

  const handleButtonClicked = (button) => {
    closeConfirmation();
    if (button.onClick) button.onClick();
  };

  const handleClose = () => {
    closeConfirmation();
    onClose();
  };

  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...otherProps}
    >
      <Card color={color}>
        {title && (
          <Card.Header>
            <h2 className="card-title">{title}</h2>
          </Card.Header>
        )}
        <Card.Body>{message}</Card.Body>
        {buttons && (
          <Card.Footer>
            <Row>
              <Col className="text-end">
                {buttons?.map((button, index) => (
                  <LoadingButton
                    className="ms-2"
                    key={`${button.title}_${index}`}
                    onClick={() => handleButtonClicked(button)}
                    padding={10}
                    color={button.color}
                  >
                    {button.title}
                  </LoadingButton>
                ))}
              </Col>
            </Row>
          </Card.Footer>
        )}
      </Card>
    </Modal>
  );
};

ConfirmationDialog.propTypes = {
  isFeatured: PropTypes.bool,
};

export default ConfirmationDialog;
