import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const addLocation = async (id, payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.customers.addLocation.replace("{id}", id);
  const response = await httpService.post(url, {
    ...payload,
    clientId: clientId,
    customerId: id,
  });

  return response;
};

const create = async (payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.customers.create;
  const response = await httpService.post(url, {
    ...payload,
    clientId: clientId,
  });

  return response;
};

/**
 *
 * @param {int} id The product id
 * @returns
 */
const deleteCustomer = async (id) => {
  const url = Endpoints.customers.delete.replace("{id}", id);
  const response = await httpService.delete(url);

  return response;
};

const deleteLocation = async (id, locationId) => {
  const url = Endpoints.customers.deleteLocation
    .replace("{id}", id)
    .replace("{locationId}", locationId);
  const response = await httpService.delete(url);

  return response;
};

const get = async (id) => {
  const url = Endpoints.customers.get.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const getAllCustomersLocations = async (clientId) => {
  clientId =
    clientId ??
    localStorageService.getProperty(LocalStorageKeys.currentClient)?.id;
  const url = Endpoints.customers.allLocations.replace("{clientId}", clientId);
  const response = await httpService.get(url);

  return response;
};

const getBasicList = async () => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.customers.getBasicList.replace("{clientId}", clientId);

  const response = await httpService.get(url);

  return response;
};

/**
 *
 * @param {SearchFilterRequest} filter
 * @returns
 */
const getCustomers = async (filter, clientId = null) => {
  clientId =
    clientId ??
    localStorageService.getProperty(LocalStorageKeys.currentClient)?.id;
  const url = Endpoints.customers.filter.replace("{clientId}", clientId);
  const response = await httpService.post(url, filter, false, true);

  return response;
};

const getCustomerDevices = async (id) => {
  const url = Endpoints.customers.devices.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const getLocation = async (id, locationId) => {
  const url = Endpoints.customers.getLocation
    .replace("{id}", id)
    .replace("{locationId}", locationId);
  const response = await httpService.get(url);

  return response;
};

const getLocations = async (id) => {
  const url = Endpoints.customers.locations.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const importCustomers = async (payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;

  const file = payload?.file;
  delete payload["file"];

  let formData = new FormData();
  formData.append("file", file);
  formData.append("data", JSON.stringify(payload));

  const url = Endpoints.customers.import.replace("{clientId}", clientId);
  // const response = await httpService.post(url, payload);
  const response = await httpService.post(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return response;
};

const removeCustomerDevice = async (id, tattleDeviceId) => {
  const url = Endpoints.customers.removeDevice
    .replace("{id}", id)
    .replace("{tattleDeviceId}", tattleDeviceId);
  const response = await httpService.delete(url);

  return response;
};

const save = async (payload) => {
  return payload?.id > 0 ? await update(payload) : await create(payload);
};

const saveLocation = async (id, payload) => {
  return payload?.id > 0
    ? await updateLocation(id, payload)
    : await addLocation(id, payload);
};

const saveTattleDevice = async (id, payload) => {
  const url = Endpoints.customers.attachDevice.replace("{id}", id);
  const response = await httpService.post(url, payload);

  return response;
};

const update = async (payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.customers.update.replace("{id}", payload?.id);
  const response = await httpService.put(url, {
    ...payload,
    clientId: clientId,
  });

  return response;
};

const updateLocation = async (id, payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.customers.updateLocation
    .replace("{id}", id)
    .replace("{locationId}", payload?.id);
  const response = await httpService.put(url, {
    ...payload,
    clientId: clientId,
    customerId: id,
  });

  return response;
};

//-----------------------------------------//
const customersApiService = {
  delete: deleteCustomer,
  deleteLocation,
  get,
  getBasicList,
  getAllCustomersLocations,
  getCustomers,
  getCustomerDevices,
  getLocation,
  getLocations,
  import: importCustomers,
  removeCustomerDevice,
  save,
  saveLocation,
  saveTattleDevice,
};

export default customersApiService;
//-----------------------------------------//
