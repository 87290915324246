import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const complete = async (checkInId, geolocation) => {
  const url = Endpoints.scheduledCheckIns.complete.replace("{id}", checkInId);
  const response = await httpService.put(url, geolocation);

  return response;
};

/**
 *
 * @param {SearchFilterRequest} filter
 * @returns
 */
const getCheckIns = async (filter) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.scheduledCheckIns.filter.replace(
    "{clientId}",
    clientId
  );
  const response = await httpService.post(url, filter);

  return response;
};

//-----------------------------------------//
const scheduledCheckInsApiService = {
  complete,
  getCheckIns,
};

export default scheduledCheckInsApiService;
//-----------------------------------------//
