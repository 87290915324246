import { useRef, useEffect } from 'react';

/**
 * @param {() => Promise<() => void>} effect
 * @param {any[]} deps
 * @description
 * This function is used to handle async useEffects.
 */
export const useEffectAsync = (effect, deps) => {
  const ref = useRef();
  useEffect(() => {
    effect().then((result) => ref.current = result).catch((error) => { });
    return () => {
      const result = ref.current;
      if (result) {
        result();
      }
    };
  }, deps);
};
