import React from "react";
import DetailsRow from "../../components/DetailsRow";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";

const BranchDetails = ({ branch, ...otherProps }) => {
  const userInfo = useCurrentUserInfo();

  const branchLocation = {
    lat: branch?.latitude,
    lng: branch?.longitude,
  };

  return (
    <>
      <dl className="horizontal">
        <DetailsRow title="Id" details={branch?.id} />
        <DetailsRow
          title="Company"
          details={branch?.name}
          visible={!!branch?.name}
        />
        <DetailsRow
          title="Branch"
          details={branch?.displayName}
          visible={!!branch?.displayName}
        />
        <DetailsRow
          title="City"
          details={branch?.city}
          visible={!!branch?.city}
        />
        <DetailsRow
          title="Province"
          details={branch?.province}
          visible={!!branch?.province}
        />
        <DetailsRow
          title="Service"
          details={branch?.businessType}
          visible={!!branch?.businessType}
        />
        <DetailsRow
          title="Phone"
          details={branch?.phone}
          visible={!!branch?.phone}
        />
        <DetailsRow
          title="Email"
          details={branch?.email}
          visible={!!branch?.email}
        />
        <DetailsRow
          title="Expiration"
          details={branch?.expiryDateFormatted}
          visible={!!branch?.expiryDate}
        />
        <DetailsRow
          title="Entered"
          details={`${Services.utility.formatFullDateAndTime(
            branch?.createdDate
          )} (${branch?.createdByName})`}
        />
      </dl>
    </>
  );
};

export default BranchDetails;
