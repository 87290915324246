import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const addCheckIn = async (id, payload) => {
  const url = Endpoints.trips.addCheckIn.replace("{id}", id);
  const response = await httpService.post(url, payload);

  return response;
};

const complete = async (id) => {
  const url = Endpoints.trips.complete.replace("{id}", id);
  const response = await httpService.put(url, null);

  return response;
};

const create = async (payload) => {
  const url = Endpoints.trips.create;
  const response = await httpService.post(url, payload);

  return response;
};

/**
 *
 * @param {int} id The trip id
 * @returns
 */
const deleteTrip = async (id) => {
  const url = Endpoints.trips.delete.replace("{id}", id);
  const response = await httpService.delete(url);

  return response;
};

const getMyTrips = async (filter) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const user = localStorageService.getProperty(LocalStorageKeys.currentUser);

  filter = { ...filter, userId: user?.id };

  const url = Endpoints.trips.filter.replace("{clientId}", clientId);
  const response = await httpService.post(url, filter, false, true);

  return response;
};

const save = async (payload) => {
  return payload?.id > 0 ? await update(payload) : await create(payload);
};

const start = async (id) => {
  const url = Endpoints.trips.start.replace("{id}", id);
  const response = await httpService.put(url, null);

  return response;
};

const update = async (payload) => {
  const url = Endpoints.trips.update.replace("{id}", payload?.id);
  const response = await httpService.put(url, payload);

  return response;
};

//-----------------------------------------//
const tripsApiService = {
  addCheckIn,
  complete,
  delete: deleteTrip,
  getMyTrips,
  save,
  start,
};

export default tripsApiService;
//-----------------------------------------//
