import { Navigate, useParams } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import CustomerLocationFormPage from "./CustomerLocationFormPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import PlaceIcon from "@mui/icons-material/Place";
import React from "react";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const CustomerLocationAddPage = (props) => {
  const { id } = useParams();
  const userInfo = useCurrentUserInfo();

  const breadcrumbs = [
    {
      IconComponent: DashboardIcon,
      title: "Dashboard",
      to: AppRoutes.dashboard,
    },
    {
      IconComponent: HolidayVillageIcon,
      title: "Customers",
      to: AppRoutes.customers,
    },
    {
      IconComponent: PlaceIcon,
      title: "Locations",
      to: AppRoutes.customerLocations.replace(":id", id),
    },
    {
      IconComponent: AddIcon,
      title: "New",
    },
  ];

  if (!userInfo?.canEditCustomers)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <CustomerLocationFormPage
      breadcrumbs={breadcrumbs}
      customerLocation={{ customerId: id }}
      title="Customer Locations - New"
    />
  );
};

export default CustomerLocationAddPage;
