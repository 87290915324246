import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Navigate } from "react-router-dom";
import React from "react";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import TattleFormPage from "./TattleFormPage";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: SettingsRemoteIcon,
    title: "Tattles",
    to: AppRoutes.tattles,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const TattleCreatePage = (props) => {
  const userInfo = useCurrentUserInfo();

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <TattleFormPage
      breadcrumbs={breadcrumbs}
      title="Tattles - New"
    ></TattleFormPage>
  );
};

export default TattleCreatePage;
