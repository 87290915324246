import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../assets/img/LiveDispatchLogo.png";
import CopyrightFooter from "../../components/CopyrightFooter";
import AppForm from "../../components/forms/Form";
import FormTextField from "../../components/forms/FormTextField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import { LoadingContext } from "../../context/contextProvider";
import Services from "../../services/services";
import GuestPage from "../GuestPage";

let validationSchema = Yup.object().shape({
  username: Yup.string().required().label("Username"),
});

const ForgotPasswordPage = (props) => {
  const [busy, setBusy] = useState(false);
  const [responseMessage, setResponseMessage] = useState();

  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async ({ username }) => {
    setResponseMessage(null);

    setLoading(true);
    const response = await Services.api.account.sendResetPasswordLink(username);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not send the instructions.",
        ThemeColorsEnum.error,
        response
      );

    setResponseMessage(response.data);
  };

  return (
    <GuestPage className="body-sign">
      <div className="center-sign">
        <a href="/" className="logo float-left">
          <img src={logo} height="70" alt="Porto Admin" />
        </a>

        <div className="panel card-sign">
          <div className="card-title-sign mt-3 text-end">
            <h2 className="title text-uppercase font-weight-bold m-0">
              <i className="bx bx-user-circle me-1 text-6 position-relative top-5"></i>
              Forgot Password
            </h2>
          </div>

          <div className="card-body">
            <div className="alert alert-info">
              <i className="fas fa-envelope"></i>&nbsp;{" "}
              {responseMessage ??
                "Enter your username below and we will send you reset instructions!"}
            </div>
            {!responseMessage && (
              <AppForm
                initialValues={{ username: "" }}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
              >
                <FormTextField
                  name="username"
                  label="Username"
                  icon="bx bx-user text-4"
                  required
                  size="lg"
                />

                <div className="row">
                  <div className="col-sm-5">
                    {/* <div className="checkbox-custom checkbox-default">
                      <input
                        id="RememberMe"
                        name="rememberme"
                        type="checkbox"
                      />
                      <label htmlFor="RememberMe">Remember Me</label>
                    </div> */}
                  </div>

                  <div className="col-sm-7 text-end">
                    <SubmitButton loading={busy}>Submit</SubmitButton>
                  </div>
                </div>
              </AppForm>
            )}

            <p className="text-center mt-3">
              Remembered? <Link to={AppRoutes.login}>Sign In!</Link>
            </p>
          </div>
        </div>
        <CopyrightFooter />
      </div>
    </GuestPage>
  );
};

export default ForgotPasswordPage;
