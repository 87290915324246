import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import React, { useMemo, useState } from "react";

const Map = ({
  children: markers,
  className,
  centerPosition,
  onLoad = () => {},
  zoom = 10,
  ...otherProps
}) => {
  const [map, setMap] = useState(/** @type google.maps.Map */ (null));

  const mapClassName = `map-container ${className}`.trim();
  const hasPosition = !!centerPosition?.lat && !!centerPosition?.lng;


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const initialPosition = useMemo(() => centerPosition, [centerPosition]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUnmount = () => {
    setMap(null);
  };

  if (!isLoaded) return <div className={mapClassName}>Loading...</div>;

  if (!hasPosition)
    return (
      <div className={mapClassName}>
        <span>No map available.</span>
      </div>
    );

  return (
    <GoogleMap
      center={initialPosition}
      mapContainerClassName={mapClassName}
      onLoad={setMap}
      onUnmount={handleUnmount}
      zoom={zoom}
      {...otherProps}
    >
      {markers}
    </GoogleMap>
  );
};

export default Map;
