import React, { useMemo, useState } from "react";
import {
  faSortAmountDown,
  faSortAmountDownAlt,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SortDirections } from "../../constants/enums";

const NSDataTableHeader = ({
  headers,
  onSort = () => {},
  sortBy,
  sortDirection,
  ...otherProps
}) => {
  const [sortData, setSortData] = useState({ column: null, direction: null });

  const handleHeaderClick = (event, header) => {
    event.preventDefault();

    if (header.sortable) {
      const direction =
        header.id === sortData.column
          ? sortData.direction === SortDirections.ascending
            ? SortDirections.descending
            : SortDirections.ascending
          : SortDirections.ascending;

      setSortData({ column: header.id, direction: direction });      

      onSort(header.id, direction);
    }
  };

  const headerColumns = useMemo(
    () =>
      headers?.map((header, index) => {
        // set the correct sort icon
        const sortIcon = (sortBy === header.id &&
          (sortData.direction === SortDirections.descending ? (
            <FontAwesomeIcon
              icon={faSortAmountDown}
              className="show sort-icon ms-2"
            />
          ) : (
            <FontAwesomeIcon
              icon={faSortAmountDownAlt}
              className="show sort-icon ms-2"
            />
          ))) || (
          <FontAwesomeIcon
            icon={faSortAmountDownAlt}
            className="sort-icon ms-2"
          />
        );

        return (
          <th
            key={JSON.stringify(header) + index}
            name={header.id}
            className={`${header.className ?? ""} ${
              header.sortable ? "sortable cur-pointer" : ""
            }`.trim()}
            onClick={(event) => handleHeaderClick(event, header)}
          >
            <span className="ws-nowrap">
              {header.text} {sortIcon}
            </span>
          </th>
        );
      }),
    [headers] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <thead>
      <tr>{headerColumns}</tr>
    </thead>
  );
};

export default NSDataTableHeader;
