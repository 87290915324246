import { Col, Row } from "react-bootstrap";
import { LoadingContext, ModalContext } from "../../../context/contextProvider";
import React, { useContext, useEffect, useState } from "react";

import AppSelect from "../../forms/Select";
import Card from "../../cards/Card";
import Enumerable from "linq";
import LoadingButton from "../../buttons/LoadingButton";
import Services from "../../../services/services";
import { ThemeColorsEnum } from "../../../constants/enums";

const UserPickerModal = ({
  initialText,
  multi,
  onSave = () => {},
  role,
  selectedValue,
  serviceOrder,
  workOrder,
  ...otherProps
}) => {
  const [selectedUser, setSelectedUser] = useState();
  const [users, setUsers] = useState([]);

  const { closeModal } = useContext(ModalContext);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    getUsersAsync();

    return () => {
      setUsers([]);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUsersAsync = async () => {
    setLoading(true);
    const response = await Services.api.users.getByRole(role);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the drivers",
        ThemeColorsEnum.error
      );

    const finalData = Services.utility.getDataForUserPicker(response.data);
    setUsers(finalData);
  };

  const handleSave = () => {
    let returnValue;

    if (multi)
      returnValue = Enumerable.from(selectedUser)
        .select((x) => x?.value)
        .toArray();
    else returnValue = selectedUser?.value;

    closeModal();
    onSave(returnValue, serviceOrder ?? workOrder);
  };

  return (
    <>
      <Card.Body>
        <AppSelect
          initialText={initialText}
          itemLabelProperty="label"
          items={users}
          itemValueProperty="value"
          multi={multi}
          onChange={setSelectedUser}
          placeholder="Select a driver"
          searchable
          value={selectedValue ?? ""}
        />
      </Card.Body>
      <Card.Footer>
        <Row className="mt-3">
          <Col className="text-end">
            <LoadingButton
              className="ms-2"
              onClick={() => {
                closeModal();
              }}
              padding={10}
              color={ThemeColorsEnum.quaternary}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              className="ms-2"
              onClick={handleSave}
              padding={10}
              color={ThemeColorsEnum.primary}
            >
              Save
            </LoadingButton>
          </Col>
        </Row>
      </Card.Footer>
    </>
  );
};

export default UserPickerModal;
