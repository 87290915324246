import AddIcon from "@mui/icons-material/Add";
import AlertFormPage from "./AlertFormPage";
import { AppRoutes } from "../../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import React from "react";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: SpeakerNotesIcon,
    title: "Alerts",
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];
const AlertCreatePage = () => {
  return <AlertFormPage breadcrumbs={breadcrumbs} title="Alerts - New" />;
};

export default AlertCreatePage;
