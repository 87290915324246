import { AppRoutes } from "../../constants/appRoutes";
import Page from "../Page";
import React from "react";
import ServiceCategoryForm from "../../components/forms/custom/ServiceCategoryForm";
import { useNavigate } from "react-router-dom";

const ServiceOrderCategoryFormPage = ({
  breadcrumbs,
  category = {},
  title,
  ...otherProps
}) => {
  const navigate = useNavigate();

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <ServiceCategoryForm
        category={category}
        onSave={() => navigate(AppRoutes.serviceOrderCategories)}
      />
    </Page>
  );
};

export default ServiceOrderCategoryFormPage;
