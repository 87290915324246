import DashboardIcon from "@mui/icons-material/Dashboard";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import Enumerable from "linq";
import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext, LoadingContext } from "../../context/contextProvider";
import useClientBranchesDataTable from "../../hooks/useClientBranchesDataTable";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Page from "../Page";

const ClientBranchesPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);
  const { currentClient, enrolledUsers } = useContext(AuthContext);

  const [allBranches, setAllBranches] = useState([]);
  const [branches, setBranches] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const userInfo = useCurrentUserInfo();
  const dataTableInfo = useClientBranchesDataTable();

  const title = `${state?.client?.name} - Branches`;
  const breadcrumbs = [
    {
      IconComponent: DashboardIcon,
      title: "Dashhboard",
      to: AppRoutes.dashboard,
    },
    {
      IconComponent: PointOfSaleIcon,
      title: "Enrolled Clients",
      to: AppRoutes.billingClients,
    },
    {
      title: title,
    },
  ];

  useEffect(() => {
    sanitizeClientBranches(id);
  }, [currentClient, enrolledUsers, id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue, allBranches]); // eslint-disable-line react-hooks/exhaustive-deps

  const sanitizeClientBranches = (id) => {
    const branches = Enumerable.from(enrolledUsers)
      .where(
        (x) => x.parentClientId === parseInt(id) || x.clientId === parseInt(id)
      )
      .distinct((x) => x.clientId)
      .toArray();

    let data = branches.map((branch) => {
      // users created last month
      const newUsersCount = enrolledUsers.filter(
        (x) => x.clientId === branch.clientId && x.isNew
      )?.length;

      // users deleted last month
      const deletedUsersCount = enrolledUsers.filter(
        (x) => x.clientId === branch.clientId && x.isDeleted
      )?.length;

      const usersCount = enrolledUsers.filter(
        (x) => x.clientId === branch.clientId
      )?.length;

      const total = branch.fee ? branch.fee * usersCount : "N/A";

      return {
        activeUsersCount: usersCount - deletedUsersCount,
        branchName: branch.branchName,
        clientId: branch.clientId,
        companyName: branch.companyName,
        newUsersCount: newUsersCount,
        deletedUsersCount: deletedUsersCount,
        total: total,
      };
    });

    setBranches(data);
    setAllBranches(data);
  };

  const handleRowClick = (event, row) => {
    navigate(AppRoutes.billingBranchDetails.replace(":id", row.clientId), {
      state: {
        client: row,
      },
    });
  };

  const handleSearch = (searchText) => {
    if (!searchText) return setBranches(allBranches);

    setBranches(
      allBranches.filter((x) =>
        x.branchName.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  if (!userInfo?.isSuperAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  data={branches}
                  emptyDataComponent={<EmptyDataBox />}
                  onRowClick={handleRowClick}
                  onSearch={setSearchValue}
                  paginate
                  search
                  searchValue={searchValue}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default ClientBranchesPage;
