import React, { useContext, useEffect } from "react";

import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext } from "../../context/contextProvider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import GuestPage from "../GuestPage";
import { Link } from "react-router-dom";
import Page from "../Page";

const title = "403 - Forbidden";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: DoNotDisturbIcon,
    title: "403",
  },
];

const ForbiddenPage = (props) => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const ParentPage = (props) =>
    currentUser ? <Page {...props} /> : <GuestPage {...props} />;

  return (
    <ParentPage breadcrumbs={breadcrumbs} title={title}>
      <section className="body-error error-inside">
        <div className="center-error">
          <div className="row">
            <div className="col-lg-8">
              <div className="main-error mb-3">
                <h2 className="error-code text-dark text-center font-weight-semibold m-0">
                  403 <i className="fa-solid fa-ban"></i>
                </h2>
                <p className="error-explanation text-center">
                  We're sorry, but you don't have permission to view this page.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <h4 className="text">Here are some useful links</h4>
              <ul className="nav nav-list flex-column primary">
                {(!!currentUser && (
                  <>
                    <li className="nav-item">
                      <Link className="nav-link" to={AppRoutes.dashboard}>
                        <i className="fas fa-caret-right text-dark"></i>{" "}
                        Dashboard
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to={AppRoutes.contact}>
                        <i className="fas fa-caret-right text-dark"></i> Contact
                      </Link>
                    </li>
                  </>
                )) || (
                  <li className="nav-item">
                    <Link className="nav-link" to={AppRoutes.login}>
                      <i className="fas fa-caret-right text-dark"></i> Sign In
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </ParentPage>
  );
};

export default ForbiddenPage;
