import { useContext } from "react";
import {
  ServiceOrderColumnsEnum,
  SortDirections,
  ThemeColorsEnum,
  WorkOrderColumnsEnum,
} from "../constants/enums";
import { AuthContext, ModalContext } from "../context/contextProvider";

import ChatIcon from "@mui/icons-material/Chat";
import { Badge } from "react-bootstrap";
import CircleButton from "../components/buttons/CircleButton";

const useServiceReportsDataTable = () => {
  const { branchSettings } = useContext(AuthContext);

  const { showConfirmation } = useContext(ModalContext);

  const handleShowNotes = (workOrder, event) => {
    event.stopPropagation();
    showConfirmation("Notes", workOrder.notes, [
      {
        color: ThemeColorsEnum.quaternary,
        onClick: () => {},
        title: "OK",
      },
    ]);
  };

  const columns = [
    {
      header: {
        id: ServiceOrderColumnsEnum.title,
        name: "title",
        text: "Title/Ticket #",
        sortable: true,
      },
      cell: (row) => row.title,
      dataTitle: "Title/Ticket #",
      className: "max-width-100",
    },
    {
      header: {
        id: ServiceOrderColumnsEnum.customer,
        name: "customer",
        text: "Customer",
        sortable: true,
      },
      cell: (row) => row.customerDisplayName,
      dataTitle: "Customer",
      className: "nowrap",
    },
    {
      header: {
        id: ServiceOrderColumnsEnum.notes,
        name: "notes",
        text: "Notes",
        sortable: true,
      },
      cell: (row) =>
        row.notes ? (
          <CircleButton
            color={ThemeColorsEnum.secondary}
            size={30}
            // style={{ backgroundColor: "#eaeaea" }}
            IconComponent={() => (
              <ChatIcon className="cur-pointer" style={{ fontSize: 20 }} />
            )}
            onClick={(event) => {
              handleShowNotes(row, event);
            }}
          />
        ) : null,
      dataTitle: "Notes",
      className: "nowrap",
    },
    // categoryName
    {
      header: {
        id: ServiceOrderColumnsEnum.category,
        name: "categoryName",
        text: "Category",
        sortable: true,
      },
      cell: (row) => row.categoryName,
      dataTitle: "Category",
      className: "max-width-80",
    },
    // createdDate
    {
      header: {
        id: ServiceOrderColumnsEnum.enteredOn,
        name: "createdDate",
        text: "Entered on",
        sortable: true,
      },
      cell: (row) => row.createdDateFormatted,
      dataTitle: "Entered on",
      className: "nowrap",
    },
    // assignedToNames
    {
      header: {
        id: ServiceOrderColumnsEnum.assignedTo,
        name: "assignedToNames",
        text: "Tech(s)",
        sortable: true,
      },
      cell: (row) =>
        row.assignedToNames?.length > 0
          ? row?.assignedToNames?.map((name, index) => (
              <div key={name + index}>{name}</div>
            ))
          : "",
      dataTitle: "Tech(s)",
      className: "nowrap",
    },
    // orderStatus
    {
      header: {
        id: ServiceOrderColumnsEnum.status,
        name: "orderStatusId",
        text: "Status",
        sortable: true,
      },
      cell: (row) => (
        <Badge bg={null} className="badge-completed">
          Completed
        </Badge>
      ),
      dataTitle: "Status",
      className: "nowrap",
    },
    // completedDate
    {
      header: {
        id: ServiceOrderColumnsEnum.completedOn,
        name: "completedDate",
        text: "Completed on",
        sortable: true,
      },
      cell: (row) => row.completedDateFormatted,
      dataTitle: "Completed on",
      className: "nowrap",
    },
    // completedBy
    {
      header: {
        id: ServiceOrderColumnsEnum.completedBy,
        name: "completedByUserId",
        text: "Completed by",
        sortable: true,
      },
      cell: (row) => row.completedByName,
      dataTitle: "Completed by",
      className: "nowrap",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.rowClass === ThemeColorsEnum.completed,
      classNames: [ThemeColorsEnum.completed],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.due,
      classNames: [ThemeColorsEnum.due],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.overdue,
      classNames: [ThemeColorsEnum.overdue],
    },
  ];

  const defaultSortColumn = (completed) =>
    completed
      ? WorkOrderColumnsEnum.completedOn
      : branchSettings?.workOrdersSortColumnIndex ??
        WorkOrderColumnsEnum.dueDate;

  const defaultSortOrder = (completed) =>
    completed
      ? SortDirections.descending
      : branchSettings?.workOrdersSortDirection ?? SortDirections.ascending;

  return { columns, conditionalRowStyles, defaultSortColumn, defaultSortOrder };
};

export default useServiceReportsDataTable;
