import { StandaloneSearchBox, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useRef } from "react";
import { Form, InputGroup } from "react-bootstrap";

import { useField, useFormikContext } from "formik";
import { ElementSize } from "../../constants/enums";
import AppErrorMessage from "./ErrorMessage";
import FieldInfo from "./FieldInfo";
import FormFieldContainer from "./FormFieldContainer";
import AppFormLabel from "./FormLabel";

const libraries = ["places"];

const FormGoogleAddressField = ({
  className,
  disabled = false,
  hasFloatingLabel,
  icon,
  info,
  label = null,
  LabelComponent,
  onChange = () => {},
  onIconClick = () => {},
  onPlaceChange = () => {},
  placeholder,
  required,
  size = ElementSize.md,
  visible = true,
  ...otherProps
}) => {
  const inputRef = useRef();
  const [field] = useField(otherProps);
  field.onChange = handleTextChange;

  const formik = useFormikContext();
  const { errors, handleBlur, setFieldValue, touched, values } = formik;

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
  });

  useEffect(() => {
  }, [isLoaded]);

  if (!visible) return null;

  const classes = `form-control-${size} ${className ?? ""}`.trim();

  const handlePlaceChange = () => {
    const [place] = inputRef.current.getPlaces();

    setFieldValue(field.name, place.formatted_address);
    onPlaceChange(place.formatted_address, place, formik);
  };

  function handleTextChange(event) {
    const text = event.target.value;
    setFieldValue(field.name, text);
    onChange(text, formik);
  }

  return !!isLoaded ? (
    // <LoadScriptNext
    //   googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
    //   libraries={libraries}
    // >
    <FormFieldContainer>
      <AppFormLabel label={label} required={required} visible={!!label} />
      {LabelComponent}
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChange}
      >
        <InputGroup>
          <Form.Control
            className={classes}
            disabled={disabled}
            name={field.name}
            onBlur={handleBlur}
            onChange={handleTextChange}
            placeholder={hasFloatingLabel ? placeholder ?? label : placeholder}
            // {...field}
            value={values[field.name]}
            {...otherProps}
          />
          {icon && (
            <span
              className={`input-group-text ${
                onIconClick === null ? "" : "cur-pointer"
              }`}
              onClick={onIconClick}
            >
              <i className={icon}></i>
            </span>
          )}
        </InputGroup>
      </StandaloneSearchBox>
      <div>
        <AppErrorMessage
          error={errors[field.name]}
          visible={touched[field.name] && errors[field.name]}
        />
      </div>
      <FieldInfo visible={!!info}>{info}</FieldInfo>

      {/* {JSON.stringify(values, null, 4)} */}
    </FormFieldContainer>
  ) : (
    // </LoadScriptNext>
    <span>Loading...</span>
  );
};

export default FormGoogleAddressField;
