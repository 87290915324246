import React, { useState } from "react";
import { debounce, throttle } from "lodash";

import { useMemo } from "react";

const NSDataTableSearch = ({
  onSearch = () => {},
  searchValue,
  ...otherProps
}) => {
  const [searchText, setSearchText] = useState(searchValue);

  const handleChange = (event) => {
    setSearchText(event.target.value);
    // handleChangeWithThrottle(event);
    handleChangeWithDebounce(event);
  };

  const handleChangeWithDebounce = useMemo(
    () =>
      debounce((event) => {
        onSearch(event.target.value?.trim());
      }, 1000),
    []
  );

  const handleChangeWithThrottle = useMemo(
    () =>
      throttle((event) => {
        onSearch(event.target.value?.trim());
      }, 1000),
    []
  );

  return (
    <div className="dataTables_filter">
      <label>
        <input
          type="search"
          className="form-control pull-right"
          placeholder="Search..."
          onChange={handleChange}
          style={{
            borderColor: !!searchText ? "#95c93f" : "#242830",
            color: "#95c93f",
          }}
          value={searchText}
        />
      </label>
    </div>
  );
};

export default NSDataTableSearch;
