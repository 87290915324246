import { Navigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import BranchFormPage from "./BranchFormPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: SettingsRemoteIcon,
    title: "Branches",
    to: AppRoutes.branches,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const BranchCreatePage = (props) => {
  const userInfo = useCurrentUserInfo();
  const { state } = useLocation();

  useEffect(() => {}, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <BranchFormPage
      branch={state?.branch}
      breadcrumbs={breadcrumbs}
      title="Branches - New"
    />
  );
};

export default BranchCreatePage;
