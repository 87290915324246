import * as Yup from "yup";

import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { ThemeColorsEnum } from "../../../constants/enums";
import { LoadingContext } from "../../../context/contextProvider";
import Services from "../../../services/services";
import LoadingButton from "../../buttons/LoadingButton";
import Card from "../../cards/Card";
import AppForm from "../Form";
import FormTextField from "../FormTextField";
import SubmitButton from "../SubmitButton";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).required().label("Name"),
  shortName: Yup.string().min(1).required().label("Short Name"),
});

const ServiceCategoryForm = ({
  category,
  modal,
  onCancel = () => {},
  onSave = () => {},
  ...otherProps
}) => {
  const { busy, setBusy } = useContext(LoadingContext);

  const handleSubmit = async (values) => {
    setBusy(true);
    const response = await Services.api.serviceCategories.save({
      ...category,
      ...values,
    });
    setBusy(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the category",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast("Saved", ThemeColorsEnum.success);
    onSave(response.data);
  };

  return (
    <AppForm
      initialValues={{
        name: category?.name ?? "",
        shortName: category?.shortName ?? "",
        notes: category?.notes ?? "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
    >
      <Card.Body>
        <Row>
          <Col lg={4}>
            <FormTextField label="Name" name="name" required />
          </Col>
          <Col lg={4}>
            <FormTextField label="Short Name" name="shortName" required />
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <FormTextField
              as="textarea"
              className="custom-text-area"
              label="Notes"
              name="notes"
            />
          </Col>
        </Row>
      </Card.Body>

      {(!!modal && (
        <Card.Footer>
          <Row className="mt-3">
            <Col className="text-end">
              <LoadingButton
                className="me-2"
                onClick={onCancel}
                padding={10}
                color={ThemeColorsEnum.quaternary}
              >
                Cancel
              </LoadingButton>
              <SubmitButton loading={busy}>Save</SubmitButton>
            </Col>
          </Row>
        </Card.Footer>
      )) || (
        <div className="form-group mb-0 fixed-to-bottom">
          <Row>
            <Col lg={2} md={4} sm={6}>
              <SubmitButton className="btn-block" loading={busy}>
                Save
              </SubmitButton>
            </Col>
          </Row>
        </div>
      )}
    </AppForm>
  );
};

export default ServiceCategoryForm;
