import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const create = async (payload) => {
  const url = Endpoints.clients.create;
  const response = await httpService.post(url, {
    ...payload,
  });

  return response;
};

/**
 *
 * @param {int} id The client id
 * @returns
 */
const deleteClient = async (id) => {
  const url = Endpoints.clients.delete.replace("{id}", id);
  const response = await httpService.delete(url);

  return response;
};

const get = async (id) => {
  const url = Endpoints.clients.get.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

/**
 *
 * @param {SearchFilterRequest} filter
 * @returns
 */
const getClients = async (filter) => {
  const url = Endpoints.clients.filter;
  const response = await httpService.post(url, filter);

  return response;
};

const getCompanies = async () => {
  const url = Endpoints.clients.companies;
  const response = await httpService.get(url);

  return response;
};

const save = async (payload) => {
  return payload?.id > 0 ? await update(payload) : await create(payload);
};

const update = async (payload) => {
  const url = Endpoints.clients.update.replace("{id}", payload?.id);
  const response = await httpService.put(url, {
    ...payload,
  });

  return response;
};

//-----------------------------------------//
const clientsApiService = {
  delete: deleteClient,
  get,
  getClients,
  getCompanies,
  save,
};

export default clientsApiService;
//-----------------------------------------//
