import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { StatusEnum, ThemeColorsEnum } from "../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../context/contextProvider";

import { CSVLink } from "react-csv";
import { useLocation } from "react-router-dom";
import AddButton from "../../components/buttons/AddButton";
import LoadingButton from "../../components/buttons/LoadingButton";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import NSDataTablePagination from "../../components/dataTables/NSDataTablePagination";
import { AppRoutes } from "../../constants/appRoutes";
import Constants from "../../constants/constants";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import useWorkOrdersDataTable from "../../hooks/useWorkOrdersDataTable";
import { Search } from "../../models/searchFilterRequest";
import WorkOrderFilterRequest from "../../models/workOrderFilterRequest";
import Services from "../../services/services";
import Page from "../Page";
import WorkOrderDetails from "./WorkOrderDetails";
import { redirect } from "react-router-dom";

const WorkOrdersPage = ({
  breadcrumbs,
  dashboardType,
  completed,
  directDrops,
  title,
  ...otherProps
}) => {
  const { loading, setLoading } = useContext(LoadingContext);
  const { currentClient, cache, setCache } = useContext(AuthContext);
  const { showModal } = useContext(ModalContext);

  const dataTableInfo = useWorkOrdersDataTable(
    directDrops,
    handleDeleted,
    handleSaved,
    handleMoved
  );

  // ----- start parsing page cache
  let _currentPage = 1;
  let _pageSize = Constants.defaults.rowsPerPage;
  let _searchValue = "";
  let _sortOptions = {
    column: dataTableInfo.defaultSortColumn(completed),
    direction: dataTableInfo.defaultSortOrder(completed),
  };

  if (!directDrops && !completed) {
    _currentPage = cache?.deliveryOrdersActive?.currentPage ?? 1;
    _pageSize =
      cache?.deliveryOrdersActive?.pageSize ?? Constants.defaults.rowsPerPage;
    _searchValue = cache?.deliveryOrdersActive?.search ?? "";
    _sortOptions = {
      column:
        cache?.deliveryOrdersActive?.sortColumn ??
        dataTableInfo.defaultSortColumn(completed),
      direction:
        cache?.deliveryOrdersActive?.sortDirection ??
        dataTableInfo.defaultSortOrder(completed),
    };
  }
  if (!directDrops && completed) {
    _currentPage = cache?.deliveryOrdersCompleted?.currentPage ?? 1;
    _pageSize =
      cache?.deliveryOrdersCompleted?.pageSize ??
      Constants.defaults.rowsPerPage;
    _searchValue = cache?.deliveryOrdersCompleted?.search ?? "";
    _sortOptions = {
      column:
        cache?.deliveryOrdersCompleted?.sortColumn ??
        dataTableInfo.defaultSortColumn(completed),
      direction:
        cache?.deliveryOrdersCompleted?.sortDirection ??
        dataTableInfo.defaultSortOrder(completed),
    };
  }
  if (directDrops && !completed) {
    _currentPage = cache?.directDropsActive?.currentPage ?? 1;
    _pageSize =
      cache?.directDropsActive?.pageSize ?? Constants.defaults.rowsPerPage;
    _searchValue = cache?.directDropsActive?.search ?? "";
    _sortOptions = {
      column:
        cache?.directDropsActive?.sortColumn ??
        dataTableInfo.defaultSortColumn(completed),
      direction:
        cache?.directDropsActive?.sortDirection ??
        dataTableInfo.defaultSortOrder(completed),
    };
  }
  if (directDrops && completed) {
    _currentPage = cache?.directDropsCompleted?.currentPage ?? 1;
    _pageSize =
      cache?.directDropsCompleted?.pageSize ?? Constants.defaults.rowsPerPage;
    _searchValue = cache?.directDropsCompleted?.search ?? "";
    _sortOptions = {
      column:
        cache?.directDropsCompleted?.sortColumn ??
        dataTableInfo.defaultSortColumn(completed),
      direction:
        cache?.directDropsCompleted?.sortDirection ??
        dataTableInfo.defaultSortOrder(completed),
    };
  }
  // ----- end parsing page cache
  const [dashboardTypeState, setDashboardTypeState] = useState(dashboardType);
  // data-tables - start
  const [currentPage, setCurrentPage] = useState(_currentPage);
  const [pageSize, setPageSize] = useState(_pageSize);
  const [paginationData, setPaginationData] = useState({
    from: null,
    pages: null,
    records: null,
    to: null,
  });
  const [searchValue, setSearchValue] = useState(_searchValue);
  const [sortOptions, setSortOptions] = useState(_sortOptions);
  // data-tables - end
  const [orders, setOrders] = useState([]);

  const { state } = useLocation();
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    saveDatatableCache(currentPage, pageSize, searchValue, sortOptions);
    getOrdersAsync((currentPage - 1) * pageSize, pageSize, searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient,
    currentPage,
    pageSize,
    searchValue,
    sortOptions,
    state?.filter,
  ]);

  useEffect(() => {
    setDashboardTypeState(null);
  }, [currentClient]);

  const getOrdersAsync = async (
    start = 0,
    take = Constants.defaults.rowsPerPage,
    searchText = null,
    dashboardFilter = dashboardTypeState,
    forceReload = false
  ) => {
    // filter
    const filter = new WorkOrderFilterRequest();
    filter.search = new Search(searchText);
    filter.start = start;
    filter.length = take;
    filter.dashboardType = !!forceReload ? null : dashboardFilter ?? null;
    filter.status =
      state?.filter?.status ??
      (completed ? StatusEnum.completed : StatusEnum.active);
    filter.isDirectDrop = directDrops;
    filter.isSchedule = false;
    filter.order[0].column = sortOptions.column; // workOrdersDataTables.defaultSortColumn;
    filter.order[0].dir = sortOptions.direction; // workOrdersDataTables.defaultSortOrder;
    filter.completedOnFrom = state?.filter?.completedOnFrom;
    filter.completedOnTo = state?.filter?.completedOnTo;

    setLoading(true);
    const response = await Services.api.workOrders.getWorkOrders(
      JSON.stringify(forceReload ? filter : state?.filter ?? filter)
    );
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the orders",
        ThemeColorsEnum.error
      );

    setOrders(response?.data?.data);
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      pages: response.data?.pagesTotal,
      records: response.data?.recordsTotal,
      to:
        currentPage * pageSize > response.data?.recordsTotal
          ? response.data?.recordsTotal
          : currentPage * pageSize,
    });
  };

  function handleDeleted(workOrder) {
    setOrders(orders.filter((x) => x.id !== workOrder.id));
  }

  function handleMoved(workOrder) {
    setOrders(orders.filter((x) => x.id !== workOrder.id));
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleRefresh = async () =>
    await getOrdersAsync(0, Constants.defaults.rowsPerPage, null, null, true);

  const handleRowClick = async (event, row) => {
    event.stopPropagation();
    // opend the details modal
    const response = await Services.api.workOrders.get(row.id);
    const workOrder = response.data ?? row;
    showModal(
      `Dispatch Order #${row.id}`,
      <WorkOrderDetails workOrder={workOrder} />,
      [
        {
          color: ThemeColorsEnum.quaternary,
          title: "Close",
        },
      ]
    );
  };

  const handleRowsPerPageChange = (newPerPage, currentPage) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  function handleSaved(workOrder) {
    insertOrUpdateWorkOrder(workOrder);
  }

  const handleSearch = (searchValue) => {
    setCurrentPage(1);
    setSearchValue(searchValue);
  };

  const handleSort = (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });
  };

  const insertOrUpdateWorkOrder = (workOrder) => {
    const index = orders.findIndex((x) => x.id === workOrder?.id);

    if (index >= 0) {
      // replace the existing item in the array
      orders[index] = workOrder;
      setOrders([...orders]);
    } else {
      // append the new item to the array
      setOrders([...orders, workOrder]);
    }
  };

  const saveDatatableCache = (
    currentPage,
    pageSize,
    searchValue,
    sortOptions
  ) => {
    let newCache = { ...cache };

    if (directDrops && !completed) {
      newCache.directDropsActive = {
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchValue,
        sortColumn: sortOptions.column,
        sortDirection: sortOptions.direction,
      };
    }

    if (directDrops && completed) {
      newCache.directDropsCompleted = {
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchValue,
        sortColumn: sortOptions.column,
        sortDirection: sortOptions.direction,
      };
    }

    if (!directDrops && !completed) {
      newCache.deliveryOrdersActive = {
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchValue,
        sortColumn: sortOptions.column,
        sortDirection: sortOptions.direction,
      };
    }

    if (!directDrops && completed) {
      newCache.deliveryOrdersCompleted = {
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchValue,
        sortColumn: sortOptions.column,
        sortDirection: sortOptions.direction,
      };
    }

    setCache(newCache);
  };

  const DataTableButtons = (
    <>
      <AddButton
        to={
          directDrops
            ? AppRoutes.directDrops_create
            : AppRoutes.deliveryOrders_create
        }
        visible={userInfo.canCreateDeliveryOrders}
      />
      <CSVLink
        className="btn btn-secondary me-1"
        data={orders}
        headers={[
          { label: "Customer", key: "customerDisplayName" },
          { label: "Products", key: "productsListFormatted" },
          { label: "Due Date", key: "dueDateFormatted" },
          { label: "Entered on", key: "createdDateFormatted" },
          { label: "Status", key: "orderStatus" },
        ]}
        filename={`${
          directDrops ? "direct-drops" : "delivery-orders"
        }-${Services.utility.formatISODate(new Date())}.csv`}
        target="_blank"
      >
        Export to Excel
      </CSVLink>
      <LoadingButton
        color={ThemeColorsEnum.quaternary}
        loading={loading}
        onClick={handleRefresh}
      >
        Refresh
      </LoadingButton>
    </>
  );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body className="overflow-auto">
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  buttons={DataTableButtons}
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  conditionalRowStyles={dataTableInfo.conditionalRowStyles}
                  data={orders}
                  emptyDataComponent={<EmptyDataBox />}
                  onRowClick={handleRowClick}
                  onSearch={handleSearch}
                  onSort={handleSort}
                  paginate
                  search
                  searchValue={searchValue}
                  sortBy={sortOptions.column}
                  sortDirection={sortOptions.direction}
                />
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  showRecordsInfo
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default WorkOrdersPage;
