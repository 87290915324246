import { useContext, useMemo } from "react";
import { TattleColumnsEnum, ThemeColorsEnum } from "../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../context/contextProvider";

import { useNavigate } from "react-router-dom";
import DropdownSelect from "../components/forms/DropdownSelect";
import { AppRoutes } from "../constants/appRoutes";
import Services from "../services/services";
import useCurrentUserInfo from "./useCurrentUserInfo";

const useTattlesDataTable = (onDeleted = () => {}) => {
  const navigate = useNavigate();

  const { currentClient } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);

  const userInfo = useCurrentUserInfo();

  const handleDelete = async (tattle) => {
    setLoading(true);
    const response = await Services.api.tattles.delete(tattle.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the tattle",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Tattle deleted", ThemeColorsEnum.success);
    onDeleted(tattle);
  };

  const columns = useMemo(
    () => [
      {
        header: {
          className: "col-menu",
          id: TattleColumnsEnum.actionButton,
          name: "id",
        },
        cell: (row) => (
          <DropdownSelect
            color={ThemeColorsEnum.quaternary}
            fixedTitle
            items={menuItems(row)}
            itemLabelProperty="title"
            itemValueProperty="title"
            size="sm"
            style={{ width: "auto" }}
            title={<i className="fa fa-bars" />}
            // title={<MenuIcon />}
          />
        ),
        dataTitle: "Menu",
        className: "nowrap",
      },
      {
        header: {
          id: TattleColumnsEnum.id,
          name: "uniqueId",
          text: "Id",
          sortable: true,
        },
        cell: (row) => row.uniqueId,
        dataTitle: "Id",
        className: "nowrap",
      },
      {
        header: {
          id: TattleColumnsEnum.name,
          name: "name",
          text: "Name",
          sortable: true,
        },
        cell: (row) => row.name,
        dataTitle: "Name",
        className: "nowrap",
      },
      ...(userInfo.isSuperAdmin
        ? [
            {
              header: {
                id: TattleColumnsEnum.client,
                name: "client",
                text: "Branch",
                sortable: true,
              },
              cell: (row) => row.clientName,
              dataTitle: "Branch",
              className: "nowrap",
            },
          ]
        : []),
      {
        header: {
          id: TattleColumnsEnum.customer,
          name: "customer",
          text: "Customer",
          sortable: true,
        },
        cell: (row) => row.customerDisplayName,
        dataTitle: "Customer",
        className: "nowrap",
      },
      {
        header: {
          id: TattleColumnsEnum.product,
          name: "product",
          text: "Product",
          sortable: true,
        },
        cell: (row) => row.productShortName,
        dataTitle: "Product",
        className: "nowrap",
      },
      {
        header: {
          id: TattleColumnsEnum.enteredOn,
          name: "createdDate",
          text: "Entered on",
          sortable: true,
        },
        cell: (row) => row.createdDateFormatted,
        dataTitle: "Entered on",
        className: "nowrap",
      },
      {
        header: {
          id: TattleColumnsEnum.enteredBy,
          name: "createdByUserId",
          text: "Entered by",
          sortable: true,
        },
        cell: (row) => row.createdByName,
        dataTitle: "Entered by",
        className: "nowrap",
      },
      {
        header: {
          id: TattleColumnsEnum.notes,
          name: "notes",
          text: "Notes",
          sortable: true,
        },
        cell: (row) => row.notes,
        dataTitle: "Notes",
        className: "nowrap",
      },
    ],
    [userInfo] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const menuItems = (row) => [
    ...(userInfo.isSuperAdmin || currentClient?.id === row.clientId
      ? [
          // Edit
          {
            onClick: () =>
              navigate(AppRoutes.tattles_edit.replace(":id", row.id), {
                state: { tattle: row },
              }),
            title: "Edit",
          },
          { type: "divider" },
        ]
      : []),
    // Delete
    {
      className: "text-danger",
      onClick: () => {
        showConfirmation(
          "Confirmation",
          "Do you really want to delete this tattle?",
          [
            {
              color: ThemeColorsEnum.quaternary,
              title: "Cancel",
            },
            {
              color: ThemeColorsEnum.danger,
              onClick: () => handleDelete(row),
              title: "Delete",
            },
          ]
        );
      },
      title: "Delete",
    },
  ];

  return { columns };
};

export default useTattlesDataTable;
