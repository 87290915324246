import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { RolesEnum, StatusEnum, ThemeColorsEnum } from "../../constants/enums";
import { AuthContext, LoadingContext } from "../../context/contextProvider";

import BuildIcon from "@mui/icons-material/Build";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AppSelect from "../../components/forms/Select";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import { AppRoutes } from "../../constants/appRoutes";
import useCurrentPosition from "../../hooks/useCurrentPosition";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import ServiceOrderFilterRequest from "../../models/serviceOrderFilterRequest";
import Services from "../../services/services";
import Page from "../Page";

const title = "Service Orders - Map";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: BuildIcon,
    title: "Service Orders",
    to: AppRoutes.serviceOrders,
  },
  {
    IconComponent: LocationOnIcon,
    title: "Map",
  },
];

const ServiceOrdersMapPage = (props) => {
  const [customersLocations, setCustomersLocations] = useState([]);
  const [allCustomersLocations, setAllCustomersLocations] = useState([]);
  const [techsGeolocations, setTechsGeolocations] = useState([]);
  const [categories, setCategories] = useState([]);

  const { currentClient, branchModules } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const { position, positionLoaded } = useCurrentPosition();
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    initAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoriesAsync = async () => {
    const response = await Services.api.serviceCategories.all();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the categories",
        ThemeColorsEnum.error
      );

    setCategories(response.data);
  };

  const getGeolocationsAsync = async (categoryIds) => {
    const response =
      await Services.api.serviceOrders.getActiveServiceOrdersGeolocations(
        categoryIds
      );

    if (!response.ok)
      Services.utility.toast("Could not fetch map pins", ThemeColorsEnum.error);
    else {
      setCustomersLocations(response.data);
      if (allCustomersLocations?.length === 0)
        setAllCustomersLocations(response.data);
    }
  };

  const getTechsGeolocationsAsync = async () => {
    const response = await Services.api.users.getLastKnownLocations([
      RolesEnum.serviceTech,
    ]);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch techs locations",
        ThemeColorsEnum.error
      );
    setTechsGeolocations(response.data);
  };

  const handleSelectCategory = async (categories) => {
    if (categories) {
      setLoading(true);
      await getGeolocationsAsync(categories?.map((x) => x.id));
      setLoading(false);
    } else setCustomersLocations(allCustomersLocations);
  };

  const initAsync = async () => {
    setLoading(true);
    await getGeolocationsAsync();
    if (userInfo.isAdmin) await getTechsGeolocationsAsync();
    await getCategoriesAsync();
    setLoading(false);
  };

  if (!branchModules?.serviceOrders)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  const InfoWindow = ({ customerLocation, ...otherProps }) => {
    // filter
    const filter = new ServiceOrderFilterRequest();
    filter.customerId = customerLocation.customerId;
    filter.status = StatusEnum.active;

    return (
      <>
        <span className="info-window-text-title">
          {customerLocation.displayName}
        </span>
        <br />
        <Link to={AppRoutes.serviceOrders} state={{ filter: filter }}>
          Click here to view the order(s)
        </Link>
      </>
    );
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row className="mb-2">
        <Col></Col>
        <Col lg={4} md={6} sm={12}>
          <AppSelect
            itemLabelProperty="name"
            items={categories}
            itemValueProperty="id"
            clearable
            multi
            onChange={handleSelectCategory}
            placeholder="Category filter"
            searchable
          />
        </Col>
      </Row>
      {!!positionLoaded && (
        <Map
          centerPosition={position}
          className="services-map-container"
          zoom={7}
        >
          {customersLocations?.map((customerLocation, index) => (
            <AppMarker
              clickable
              info={<InfoWindow customerLocation={customerLocation} />}
              key={`${customerLocation.id}_${index}`}
              order={{ rowClass: customerLocation.pinStatus }}
              position={{
                lat: customerLocation.latitude,
                lng: customerLocation.longitude,
              }}
            />
          ))}
          {techsGeolocations?.map((techGeolocation, index) => (
            <AppMarker
              clickable
              icon={`${process.env.PUBLIC_URL}/img/ic_pin_truck.png`}
              info={
                <>
                  <span className="info-window-text-title">
                    {techGeolocation.displayName}
                  </span>
                  <br />
                  <span className="info-window-text">
                    {techGeolocation.createdDateFormatted}
                  </span>
                </>
              }
              key={`${techGeolocation.id}_${index}`}
              position={{
                lat: techGeolocation.geolocation?.location?.latitude,
                lng: techGeolocation.geolocation?.location?.longitude,
              }}
            />
          ))}
        </Map>
      )}
    </Page>
  );
};

export default ServiceOrdersMapPage;
