import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../context/contextProvider";
import {
  OrderStatusIdEnum,
  RolesEnum,
  ServiceOrderColumnsEnum,
  SortDirections,
  StatusEnum,
  ThemeColorsEnum,
} from "../constants/enums";

import { AppRoutes } from "../constants/appRoutes";
import { Badge } from "react-bootstrap";
import BranchPickerModal from "../components/modals/custom/BranchPickerModal";
import ChatIcon from "@mui/icons-material/Chat";
import CircleButton from "../components/buttons/CircleButton";
import DropdownSelect from "../components/forms/DropdownSelect";
import Services from "../services/services";
import UserPickerModal from "../components/modals/custom/UserPickerModal";
import { useContext } from "react";
import useCurrentUserInfo from "./useCurrentUserInfo";
import { useNavigate } from "react-router-dom";

const useServiceOrdersDataTable = (
  onDeleted = () => {},
  onSaved = () => {},
  onMoved = () => {}
) => {
  const navigate = useNavigate();
  const userInfo = useCurrentUserInfo();

  const { setLoading } = useContext(LoadingContext);
  const { branchSettings, currentUser, currentClient } =
    useContext(AuthContext);
  const { showConfirmation, showModal } = useContext(ModalContext);

  const assignOrder = async (userIds, serviceOrder) => {
    serviceOrder = {
      ...serviceOrder,
      assignedToIds: userIds,
    };

    setLoading(true);
    const response = await Services.api.serviceOrders.save(serviceOrder);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not assign the order",
        ThemeColorsEnum.error
      );

    onSaved(response?.data);
    Services.utility.toast("Order assigned", ThemeColorsEnum.success);
  };

  const completeOrder = async (serviceOrder) => {
    setLoading(true);
    const response = await Services.api.serviceOrders.complete(serviceOrder);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not complete the order",
        ThemeColorsEnum.error
      );

    onSaved(response?.data);
    Services.utility.toast("Order completed", ThemeColorsEnum.success);
  };

  const handleAssign = async (serviceOrder, event) => {
    showModal(
      "Pick a technician",
      null,
      null,
      <UserPickerModal
        initialText={serviceOrder?.assignedToName}
        multi
        onSave={assignOrder}
        role={RolesEnum.serviceTech}
        selectedValue={serviceOrder?.assignedToIds}
        serviceOrder={serviceOrder}
      />
    );
  };

  const handleComplete = (serviceOrder) => {
    showConfirmation(
      "Complete order",
      "Do you really want to complete this order?",
      [
        {
          color: ThemeColorsEnum.quaternary,
          title: "Cancel",
        },
        {
          color: ThemeColorsEnum.success,
          onClick: async () => await completeOrder(serviceOrder),
          title: "Complete",
        },
      ]
    );
  };

  const handleDelete = async (serviceOrder) => {
    setLoading(true);
    const response = await Services.api.serviceOrders.delete(serviceOrder.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the order",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Order deleted", ThemeColorsEnum.success);
    onDeleted(serviceOrder);
  };

  const handleDuplicate = async (serviceOrder) => {
    const response = await Services.api.serviceCategories.get(
      serviceOrder.categoryId
    );
    const category =
      response.data?.status === StatusEnum.active ? response.data : null;

    navigate(AppRoutes.serviceOrders_create, {
      state: {
        serviceOrder: {
          ...serviceOrder,
          id: 0,        
          categoryId: category?.id,
          dueDate: null,
          orderStatusId: OrderStatusIdEnum.new,
          status: StatusEnum.active,
          serviceOrderId: 0,
        },
      },
    });
  };

  const handleMove = (serviceOrder) => {
    showModal(
      "Pick a branch",
      null,
      null,
      <BranchPickerModal
        branches={currentUser?.clients.filter(
          (x) => x.id !== currentClient?.id
        )}
        onSave={(branchId) => moveOrder(serviceOrder, branchId)}
      />
    );
  };

  const handleShowNotes = (serviceOrder, event) => {
    event.stopPropagation();
    event.preventDefault();
    showConfirmation("Notes", serviceOrder.notes, [
      {
        color: ThemeColorsEnum.quaternary,
        onClick: () => {},
        title: "OK",
      },
    ]);
  };

  const handleStart = async (serviceOrder, event) => {
    // event.stopPropagation();

    showConfirmation(
      "Confirmation",
      "Do you really want to start this order?",
      [
        {
          color: ThemeColorsEnum.quaternary,
          title: "No",
        },
        {
          color: ThemeColorsEnum.danger,
          onClick: async () => await startOrder(serviceOrder),
          title: "Yes",
        },
      ]
    );
  };

  const handleUndoStart = async (serviceOrder) => {
    showConfirmation("Confirmation", "Do you really want to undo this?", [
      {
        color: ThemeColorsEnum.quaternary,
        title: "No",
      },
      {
        color: ThemeColorsEnum.danger,
        onClick: async () => await undoStart(serviceOrder),
        title: "Yes",
      },
    ]);
  };

  const moveOrder = async (serviceOrder, branchId) => {
    setLoading(true);
    const response = await Services.api.serviceOrders.move(
      serviceOrder?.id,
      branchId
    );
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not move the order",
        ThemeColorsEnum.error
      );

    onMoved(response?.data);
    Services.utility.toast("Order moved", ThemeColorsEnum.success);
  };

  const startOrder = async (serviceOrder) => {
    setLoading(true);
    const response = await Services.api.serviceOrders.start(serviceOrder?.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not start the order",
        ThemeColorsEnum.error
      );

    onSaved(response?.data);
    Services.utility.toast("Order started", ThemeColorsEnum.success);
  };

  const undoStart = async (serviceOrder) => {
    setLoading(true);
    const response = await Services.api.serviceOrders.undoStart(
      serviceOrder?.id
    );
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not undo this operation",
        ThemeColorsEnum.error
      );

    onSaved(response?.data);
    Services.utility.toast("Order reset", ThemeColorsEnum.success);
  };

  const menuItems = (row) => [
    // Start
    ...(row.orderStatusId === OrderStatusIdEnum.new
      ? [
          {
            onClick: (event) => handleStart(row),
            title: "Start",
          },
        ]
      : []),
    // Complete: if status is inProgress
    ...(row.orderStatusId === OrderStatusIdEnum.inProgress
      ? [
          {
            onClick: () => handleUndoStart(row),
            title: "Undo Start",
          },
          {
            onClick: () => handleComplete(row),
            title: "Complete",
          },
        ]
      : []),
    // Assign: if unassigned
    ...(row.status === StatusEnum.active &&
    (userInfo?.isAdmin ||
      (!row.isTechLocked && userInfo?.canAssignDeliveryOrders))
      ? [
          {
            onClick: () => handleAssign(row),
            title: "Assign",
          },
        ]
      : []),
    // Edit
    ...(userInfo?.canEditDeliveryOrders
      ? [
          {
            // href: AppRoutes.products_edit.replace(":id", row.id),
            onClick: () =>
              navigate(AppRoutes.serviceOrders_edit.replace(":id", row.id), {
                state: { serviceOrder: row },
              }),
            title: "Edit",
          },
        ]
      : []),
    // Duplicate
    ...(userInfo?.canCreateDeliveryOrders
      ? [
          {
            onClick: () => handleDuplicate(row),
            title: "Duplicate",
          },
        ]
      : []),
    // Move
    ...(row.status === StatusEnum.active &&
    userInfo?.canMoveDeliveryOrders &&
    currentUser?.clients?.length > 1
      ? [
          {
            onClick: () => handleMove(row),
            title: "Move to another branch",
          },
        ]
      : []),
    { type: "divider" },
    // Delete
    ...(userInfo?.canDeleteDeliveryOrders
      ? [
          {
            className: "text-danger",
            onClick: () => {
              showConfirmation(
                "Confirmation",
                "Do you really want to delete this order?",
                [
                  {
                    color: ThemeColorsEnum.quaternary,
                    title: "Cancel",
                  },
                  {
                    color: ThemeColorsEnum.danger,
                    onClick: () => handleDelete(row),
                    title: "Delete",
                  },
                ]
              );
            },
            title: "Delete",
          },
        ]
      : []),
  ];

  const columns = [
    {
      header: {
        className: "col-menu",
        id: ServiceOrderColumnsEnum.actionButton,
        name: "id",
      },
      cell: (row) => (
        <DropdownSelect
          color={ThemeColorsEnum.quaternary}
          fixedTitle
          items={menuItems(row)}
          itemLabelProperty="title"
          itemValueProperty="title"
          size="sm"
          style={{ width: "auto" }}
          title={<i className="fa fa-bars" />}
          // title={<MenuIcon />}
        />
      ),
      dataTitle: "Menu",
      className: "nowrap",
    },
    // {
    //   header: {
    //     id: ServiceOrderColumnsEnum.orderNumber,
    //     name: "id",
    //     text: "#",
    //     sortable: true,
    //   },
    //   cell: (row) => row.id,
    //   dataTitle: "#",
    //   className: "nowrap",
    // },
    {
      header: {
        id: ServiceOrderColumnsEnum.title,
        name: "title",
        text: "Title/Ticket #",
        sortable: true,
      },
      cell: (row) => row.title,
      dataTitle: "Title/Ticket #",
      className: "max-width-100",
    },
    {
      header: {
        id: ServiceOrderColumnsEnum.customer,
        name: "customer",
        text: "Customer",
        sortable: true,
      },
      cell: (row) => row.customerDisplayName,
      dataTitle: "Customer",
      className: "nowrap",
    },
    {
      header: {
        id: ServiceOrderColumnsEnum.notes,
        name: "notes",
        text: "Notes",
        sortable: true,
      },
      cell: (row) =>
        row.notes ? (
          <CircleButton
            color={ThemeColorsEnum.secondary}
            size={30}
            // style={{ backgroundColor: "#eaeaea" }}
            IconComponent={() => (
              <ChatIcon className="cur-pointer" style={{ fontSize: 20 }} />
            )}
            onClick={(event) => {
              handleShowNotes(row, event);
            }}
          />
        ) : null,
      dataTitle: "Notes",
      className: "nowrap",
    },
    // categoryName
    {
      header: {
        id: ServiceOrderColumnsEnum.category,
        name: "categoryName",
        text: "Category",
        sortable: true,
      },
      cell: (row) => row.categoryName,
      dataTitle: "Category",
      className: "max-width-80",
    },
    // assignedToNames
    {
      header: {
        id: ServiceOrderColumnsEnum.assignedTo,
        name: "assignedToNames",
        text: "Tech(s)",
        sortable: true,
      },
      cell: (row) =>
        row.assignedToNames?.length > 0
          ? row?.assignedToNames?.map((name, index) => (
              <div key={name + index}>{name}</div>
            ))
          : row.status !== StatusEnum.completed && (
              <Badge
                bg={ThemeColorsEnum.unassigned}
                className="badge-unassigned cur-pointer"
                onClick={(event) => {
                  event.stopPropagation();
                  handleAssign(row, event);
                }}
              >
                Assign
              </Badge>
            ),
      dataTitle: "Tech(s)",
      className: "nowrap",
    },
    // orderStatus
    {
      header: {
        id: ServiceOrderColumnsEnum.status,
        name: "orderStatusId",
        text: "Status",
        sortable: true,
      },
      cell: (row) => (
        <Badge bg={null} className={getBadgeClass(row.orderStatusId)}>
          {row.orderStatus}
        </Badge>
      ),
      dataTitle: "Status",
      className: "nowrap",
    },
    // dueDate
    {
      header: {
        id: ServiceOrderColumnsEnum.dueDate,
        name: "dueDate",
        text: "Due on",
        sortable: true,
      },
      cell: (row) => row.dueDateFormatted,
      dataTitle: "Due on",
      className: "nowrap",
    },
    // completedDate
    {
      header: {
        id: ServiceOrderColumnsEnum.completedOn,
        name: "completedDate",
        text: "Completed on",
        sortable: true,
      },
      cell: (row) => row.completedDateFormatted,
      dataTitle: "Completed on",
      className: "nowrap",
    },
    // completedBy
    {
      header: {
        id: ServiceOrderColumnsEnum.completedBy,
        name: "completedByUserId",
        text: "Completed by",
        sortable: true,
      },
      cell: (row) => row.completedByName,
      dataTitle: "Completed by",
      className: "nowrap",
    },
    // createdDate
    {
      header: {
        id: ServiceOrderColumnsEnum.enteredOn,
        name: "createdDate",
        text: "Entered on",
        sortable: true,
      },
      cell: (row) => row.createdDateFormatted,
      dataTitle: "Entered on",
      className: "nowrap",
    },
    // high priority
    {
      header: {
        id: ServiceOrderColumnsEnum.isHighPriority,
        name: "isHighPriority",
        text: "High Priority",
        sortable: true,
      },
      cell: (row) => (row.isHighPriority ? "Yes" : ""),
      dataTitle: "High Priority",
      className: "nowrap text-center",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.rowClass === ThemeColorsEnum.completed,
      classNames: [ThemeColorsEnum.completed],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.due && !row.isHighPriority,
      classNames: [ThemeColorsEnum.due],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.overdue && !row.isHighPriority,
      classNames: [ThemeColorsEnum.overdue],
    },
    {
      when: (row) => row.isHighPriority && row.rowClass !== ThemeColorsEnum.completed,
      classNames: [ThemeColorsEnum.highPrio],
    },
  ];

  const defaultSortColumn = (completed) =>
    completed
      ? ServiceOrderColumnsEnum.completedOn
      : branchSettings?.serviceOrdersSortColumnIndex ??
        ServiceOrderColumnsEnum.dueDate;

  const defaultSortOrder = (completed) =>
    completed
      ? SortDirections.descending
      : branchSettings?.serviceOrdersSortDirection ?? SortDirections.ascending;

  const getBadgeClass = (orderStatusId) => {
    switch (orderStatusId) {
      case OrderStatusIdEnum.completed:
        return "badge-completed";

      case OrderStatusIdEnum.inProgress:
        return "badge-in-progress";

      case OrderStatusIdEnum.new:
        return "badge-new";

      case OrderStatusIdEnum.pending:
        return "badge-pending";

      default:
        return;
    }
  };

  return {
    columns,
    conditionalRowStyles,
    defaultSortColumn,
    defaultSortOrder,
    getBadgeClass,
  };
};

export default useServiceOrdersDataTable;
