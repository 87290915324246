import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import { StatusCodes } from "http-status-codes";
import httpService from "../httpService";
import localStorageService from "../localStorageService";
import sessionStorageService from "../sessionStorageService";

const logOut = async () => {
  const response = await httpService.post(Endpoints.account.logOut, false);

  sessionStorageService.clear();
  localStorageService.clear();

  return response;
};

const saveTokens = async (response) => {
  // save the access_token
  localStorageService.setProperty(
    LocalStorageKeys.accessToken,
    response.data?.access_token
  );

  // save the refresh_token
  localStorageService.setProperty(
    LocalStorageKeys.refreshToken,
    response.data?.refresh_token
  );
};

const token = async (payload) => {
  const response = await httpService.post(Endpoints.auth.token, payload);

  if (response.ok && response.status === StatusCodes.OK) {
    saveTokens(response);
  }

  return response;
};

//-----------------------------------------//
const authApiService = {
  logOut,
  token,
};

export default authApiService;
//-----------------------------------------//
