import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  SortDirections,
  TattleColumnsEnum,
  ThemeColorsEnum,
} from "../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../context/contextProvider";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import { Alert } from "@mui/material";
import { Navigate } from "react-router-dom";
import AddButton from "../../components/buttons/AddButton";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import NSDataTablePagination from "../../components/dataTables/NSDataTablePagination";
import { AppRoutes } from "../../constants/appRoutes";
import Constants from "../../constants/constants";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import useTattlesDataTable from "../../hooks/useTattlesDataTable";
import Services from "../../services/services";
import Page from "../Page";
import TattleDetails from "./TattleDetails";

const title = "Tattles";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: SettingsRemoteIcon,
    title: title,
  },
];

const TattlesPage = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(Constants.defaults.rowsPerPage);
  const [paginationData, setPaginationData] = useState({
    from: null,
    pages: null,
    records: null,
    to: null,
  });
  const [tattles, setTattles] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [sortOptions, setSortOptions] = useState({
    column: TattleColumnsEnum.name,
    direction: SortDirections.ascending,
  });
  const [searchAllBranches, setSearchAllBranches] = useState(false);

  const { currentClient } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const { showModal } = useContext(ModalContext);

  const userInfo = useCurrentUserInfo();
  const dataTableInfo = useTattlesDataTable(handleDeleted);

  useEffect(
    () => {
      getTattlesAsync((currentPage - 1) * pageSize, pageSize, searchValue);
      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentClient,
      currentPage,
      pageSize,
      searchValue,
      sortOptions,
      searchAllBranches,
    ]
  );

  const getTattlesAsync = async (
    start = 0,
    take = Constants.defaults.rowsPerPage,
    searchText = null
  ) => {
    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(searchText);
    filter.start = start;
    filter.length = take;
    filter.order[0].column = sortOptions.column ?? TattleColumnsEnum.name;
    filter.order[0].dir = sortOptions.direction ?? SortDirections.ascending;

    setLoading(true);
    const response = await Services.api.tattles.getTattles(
      filter,
      searchAllBranches
    );
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the tattles",
        ThemeColorsEnum.error
      );

    setTattles(response.data?.data);
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      pages: response.data?.pagesTotal,
      records: response.data?.recordsTotal,
      to:
        currentPage * pageSize > response.data?.recordsTotal
          ? response.data?.recordsTotal
          : currentPage * pageSize,
    });
  };

  function handleDeleted(tattle) {
    setTattles(tattles.filter((x) => x.id !== tattle.id));
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (event, row) => {
    event.stopPropagation();
    // opend the details modal
    showModal(`Tattle ${row.uniqueId}`, <TattleDetails tattle={row} />, [
      {
        color: ThemeColorsEnum.quaternary,
        title: "Close",
      },
    ]);
  };

  const handleRowsPerPageChange = (newPerPage, currentPage) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  const handleSearchAllChange = (event) => {
    setCurrentPage(1);
    setSearchAllBranches(event.target?.checked);
  };

  const handleSort = (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });
  };

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body className="overflow-auto">
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {userInfo.isSuperAdmin && (
                  <Row>
                    <Col>
                      <Alert
                        className="mb-3"
                        severity={ThemeColorsEnum.warning}
                      >
                        <Form.Check
                          checked={searchAllBranches}
                          className="checkbox-custom checkbox-warning"
                          label="Search in all branches"
                          name="test"
                          onChange={handleSearchAllChange}
                          type="checkbox"
                          value={1}
                        />
                      </Alert>
                    </Col>
                  </Row>
                )}
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  buttons={<AddButton to={AppRoutes.tattles_create} />}
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  //   conditionalRowStyles={conditionalRowStyles}
                  data={tattles}
                  emptyDataComponent={<EmptyDataBox />}
                  onRowClick={handleRowClick}
                  onSearch={handleSearch}
                  onSort={handleSort}
                  paginate
                  search
                  sortBy={sortOptions.column}
                  sortDirection={sortOptions.direction}
                />
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  showRecordsInfo
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default TattlesPage;
