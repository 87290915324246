import Enumerable from "linq";
import React from "react";

const NSDataTableBody = ({
  cells,
  columns,
  conditionalRowStyles,
  emptyDataComponent,
  onRowClick,
  rows,
  ...otherProps
}) => {
  return (
    <tbody>
      {(rows?.length > 0 &&
        rows?.map((row) => {
          const additionalClassNames = `${Enumerable.from(conditionalRowStyles)
            .where((x) => x.when(row) === true)
            .select((x) => x.classNames)
            .toJoinedString()} ${onRowClick ? "cur-pointer" : ""}`.trim();

          return (
            <tr
              key={JSON.stringify(row)}
              className={additionalClassNames}
              onClick={(event) => !!onRowClick && onRowClick(event, row)}
            >
              {columns?.map((column, index) => (
                <td
                  key={JSON.stringify(column) + index}
                  className={column.className}
                  data-title={column.dataTitle}
                >
                  {column.cell(row)}
                </td>
              ))}
            </tr>
          );
        })) || (
        <tr>
          <td colSpan={columns?.length}>{emptyDataComponent}</td>
        </tr>
      )}
    </tbody>
  );
};

export default NSDataTableBody;
