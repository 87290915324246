import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const contact = async (payload) => {
  const currentUser = localStorageService.getProperty(
    LocalStorageKeys.currentUser
  );
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;

  payload = {
    ...payload,
    fromName: currentUser?.fullName,
    fromEmail: currentUser?.email,
    clientId: clientId,
    username: currentUser?.userName,
  };

  const response = await httpService.post(Endpoints.home.contact, payload);

  return response;
};

const getCountries = async () => {
  const url = Endpoints.home.countries;
  const response = await httpService.get(url);

  return response;
};

const getRegions = async (country) => {
  const url = Endpoints.home.regions.replace("{country}", country);
  const response = await httpService.get(url);

  return response;
};

const getSummary = async () => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.home.summary.replace("{clientId}", clientId);
  const response = await httpService.get(url);

  return response;
};

//-----------------------------------------//
const homeApiService = {
  contact,
  getCountries,
  getRegions,
  getSummary,
};

export default homeApiService;
//-----------------------------------------//
