import { Form } from "react-bootstrap";
import { useField, useFormikContext } from "formik";

import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import FieldInfo from "./FieldInfo";
import FormFieldContainer from "./FormFieldContainer";
import React from "react";
import { ThemeColorsEnum } from "../../constants/enums";

const FormCheckboxField = ({
  color = ThemeColorsEnum.default,
  description,
  disabled = false,
  info,
  label = null,
  LabelComponent,
  placeholder,
  required,
  visible = true,
  ...otherProps
}) => {
  const [field] = useField(otherProps);
  const formik = useFormikContext();
  const { errors, handleChange, touched, values } = formik;

  if (!visible) return null;

  const classes = `checkbox-custom checkbox-${color}`.trim();

  return (
    <FormFieldContainer>
      <AppFormLabel label={label} required={required} visible={!!label} />
      {LabelComponent}
      <Form.Check
        checked={!!field.value ?? false}
        className={classes}
        label={description ?? ""}
        name={field.name}
        // onChange={handleValueChange}
        onChange={handleChange(field.name)}
        type="checkbox"
        value={values[field.name]}
      />
      <div>
        <AppErrorMessage
          error={errors[field.name]}
          visible={touched[field.name] && errors[field.name]}
        />
      </div>
      <FieldInfo visible={!!info}>{info}</FieldInfo>
      {/* {JSON.stringify(values, null, 4)} */}
    </FormFieldContainer>
  );
};

export default FormCheckboxField;
