import { Col, InputGroup, Row } from "react-bootstrap";
import { ElementSize, ThemeColorsEnum } from "../../constants/enums";
import React, { useContext } from "react";
import { useField, useFormikContext } from "formik";

import AddIcon from "@mui/icons-material/Add";
import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import AppSelect from "./Select";
import CircleButton from "../buttons/CircleButton";
import CustomerForm from "./custom/CustomerForm";
import Enumerable from "linq";
import FieldInfo from "./FieldInfo";
import FormFieldContainer from "./FormFieldContainer";
import { ModalContext } from "../../context/contextProvider";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const FormCustomerSelect = ({
  allItems,
  autocomplete,
  clearable,
  disabled = false,
  hidePlusButton,
  icon,
  info,
  initialText,
  itemLabelProperty,
  items = [],
  itemValueProperty,
  label,
  LabelComponent,
  multi,
  // name,
  onBlur = () => {},
  onChange = () => {},
  onInputChange = () => {},
  placeholder,
  required,
  searchable = false,
  size = ElementSize.md,
  visible = true,
  ...otherProps
}) => {
  const { showModal, closeModal } = useContext(ModalContext);
  const userInfo = useCurrentUserInfo(false);

  const formik = useFormikContext();
  const [field] = useField({ name: otherProps.name, value: otherProps.value });

  const { errors, handleBlur, handleChange, setFieldValue, touched } =
    formik;

  if (!visible) return null;

  const handleAddCustomer = () => {
    showModal(
      `New Customer`,
      null,
      null,
      <CustomerForm modal onCancel={closeModal} onSave={handleCustomerSave} />,
      "lg"
    );
  };

  const handleCustomerSave = (customer) => {
    allItems.push(customer);
    items.push(customer);

    closeModal();

    handleSelect(customer);
  };

  const handleInputChange = (text) => {
    if (text?.length === 0) return;
    onInputChange(text);
  };

  const handleSelect = (item) => {

    if (multi)
      setFieldValue(
        field.name,
        Enumerable.from(item)
          .select((x) => x[itemValueProperty])
          .toArray()
      );
    else setFieldValue(field.name, item?.[itemValueProperty]);

    handleBlur(field.name);
    handleChange(field.name);
    onChange(item, formik);
  };

  return (
    <FormFieldContainer>
      <Row>
        <Col lg={10}>
          <AppFormLabel label={label} required={required} visible={!!label} />
          {LabelComponent}
        </Col>
        {!hidePlusButton && (
          <Col lg={2} className="text-right">
            {/* <Link className="text-default" to="#">
            <i className="fa fa-plus-circle"></i>
          </Link> */}
            <CircleButton
              color={ThemeColorsEnum.quaternary}
              size={25}
              // style={{ backgroundColor: "#eaeaea" }}
              IconComponent={() => (
                <AddIcon className="cur-pointer" style={{ fontSize: 20 }} />
              )}
              onClick={handleAddCustomer}
              visible={!!userInfo?.canCreateCustomers}
            />
          </Col>
        )}
      </Row>
      <InputGroup>
        <AppSelect
          allItems={allItems}
          autocomplete={autocomplete}
          clearable={clearable}
          initialText={initialText}
          itemLabelProperty={itemLabelProperty}
          items={items}
          itemValueProperty={itemValueProperty}
          multi={multi}
          name={field.name}
          onBlur={handleBlur(field.name)}
          onChange={handleSelect}
          onInputChange={handleInputChange}
          placeholder={placeholder}
          searchable={searchable}
          value={field.value}
          // value={selectedItem}
          // {...field}
          {...otherProps}
        />
        {icon && (
          <span className="input-group-text">
            <i className={icon}></i>
          </span>
        )}
      </InputGroup>
      <div>
        <AppErrorMessage
          error={errors?.[field.name]}
          visible={!!touched?.[field.name] && !!errors?.[field.name]}
        />
      </div>
      <FieldInfo visible={!!info}>{info}</FieldInfo>

      {/* <div>Value: {JSON.stringify(values?.[name])}</div> */}
      {/* <div>Touched: {JSON.stringify(touched?.[name])}</div> */}
      {/* <div>Error: {JSON.stringify(errors?.[name])}</div> */}
    </FormFieldContainer>
  );
};

export default FormCustomerSelect;
