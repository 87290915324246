import FakeHttpReponse from "../../models/fakeHttpResponse";
import settingsApiService from "./settingsApiService";

const initialize = async () => {
  const data = {
    branchModules: null,
    branchSettings: null,
    userSettings: null,
  };

  // branch settings
  let response = await settingsApiService.getBranchSettings();
  if (!response.ok) {
    console.error(response);
    return response;
  }
  data.branchSettings = response.data?.settings;

  // branch modules
  response = await settingsApiService.getBranchModules();
  if (!response.ok) {
    console.error(response);
    return response;
  }
  data.branchModules = response.data?.modules;

  // user settings
  response = await settingsApiService.getUserSettings();
  if (!response.ok) {
    console.error(response);
    return response;
  }
  data.userSettings = response.data?.settings;

  return new FakeHttpReponse(data);
};

//-----------------------------------------//
const utilityApiService = {
  initialize,
};

export default utilityApiService;
//-----------------------------------------//
