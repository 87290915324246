import React, { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext } from "../../context/contextProvider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { Navigate } from "react-router-dom";
import WorkOrderFormPage from "../workOrders/WorkOrderFormPage";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import { useLocation } from "react-router-dom";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: LocalGasStationIcon,
    title: "Delivery Orders",
    to: AppRoutes.deliveryOrders,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const DeliveryOrderCreatePage = (props) => {
  const { state } = useLocation();
  const [workOrder, setWorkOrder] = useState();
  const [initialCustomerLocations, setInitialCustomerLocation] = useState();
  const { branchModules } = useContext(AuthContext);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    setWorkOrder(state?.workOrder ?? props.location?.state?.workOrder);
	setInitialCustomerLocation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!branchModules?.workOrders || !userInfo.canCreateDeliveryOrders)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <WorkOrderFormPage
      breadcrumbs={breadcrumbs}
      title="Delivery Orders - New"
      workOrder={workOrder}
	  initialCustomerLocations={state?.initialCustomerLocations ?? []}
    />
  );
};

export default DeliveryOrderCreatePage;
