import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import React from "react";
import TruckFormPage from "./TruckFormPage";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: LocalShippingIcon,
    title: "Trucks",
    to: AppRoutes.trucks,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const TruckCreatePage = (props) => {
  return (
    <TruckFormPage
      breadcrumbs={breadcrumbs}
      title="Trucks - New"
    ></TruckFormPage>
  );
};

export default TruckCreatePage;
