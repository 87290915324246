import { AuthContext, LoadingContext } from "../../context/contextProvider";
import { Navigate, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import PeopleIcon from "@mui/icons-material/People";
import Schemas from "../../constants/schemas";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";
import UserFormPage from "./UserFormPage";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import { useParams } from "react-router-dom";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PeopleIcon,
    title: "Users",
    to: AppRoutes.users,
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const UserEditPage = (props) => {
  const { id } = useParams();

  const [user, setUser] = useState();
  const { currentClient } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const [initialClientId] = useState(currentClient?.id);

  const userInfo = useCurrentUserInfo();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialClientId > 0 && initialClientId !== currentClient?.id)
      return navigate(AppRoutes.users);

    if (id) getUserAsync(id);
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.users.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the user",
        ThemeColorsEnum.error
      );

    setUser(response.data);
  };

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <UserFormPage
      breadcrumbs={breadcrumbs}
      title="Users - Edit"
      user={user}
      validationSchema={Schemas.editUser}
    />
  );
};

export default UserEditPage;
