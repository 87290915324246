import { useLocation, useParams } from "react-router-dom";

import AlertFormPage from "./AlertFormPage";
import { AppRoutes } from "../../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingContext } from "../../../context/contextProvider";
import React from "react";
import Services from "../../../services/services";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { ThemeColorsEnum } from "../../../constants/enums";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: SpeakerNotesIcon,
    title: "Alerts",
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const AlertEditPage = () => {
  const { id } = useParams();
  const { state } = useLocation();

  const [alert, setAlert] = useState(state?.alert);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (id && !state?.alert) getAlertAsync(id);
  }, [id, state]); // eslint-disable-line react-hooks/exhaustive-deps

  const getAlertAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.alerts.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the alert",
        ThemeColorsEnum.error
      );

    setAlert(response.data);
  };

  return (
    <AlertFormPage
      alert={alert}
      breadcrumbs={breadcrumbs}
      title="Alerts - Edit"
    />
  );
};

export default AlertEditPage;
