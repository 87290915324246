import * as Yup from "yup";

import React, { useContext } from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import EmailIcon from "@mui/icons-material/Email";
import { Alert } from "@mui/material";
import Card from "../components/cards/Card";
import AppForm from "../components/forms/Form";
import FormTextField from "../components/forms/FormTextField";
import SubmitButton from "../components/forms/SubmitButton";
import { AppRoutes } from "../constants/appRoutes";
import { ThemeColorsEnum } from "../constants/enums";
import { LoadingContext } from "../context/contextProvider";
import Services from "../services/services";
import Page from "./Page";

const validationSchema = Yup.object().shape({
  subject: Yup.string().min(1).required().label("Subject"),
  message: Yup.string().min(1).required().label("Message"),
});

const title = "Contact";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: EmailIcon,
    title: title,
  },
];

const ContactPage = () => {
  const { busy, setBusy, setLoading } = useContext(LoadingContext);

  const handleSubmit = async (values, resetForm) => {
    setBusy(true);
    const response = await Services.api.home.contact(values);
    setBusy(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not send your message",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast(
      "Your message was sent successfully",
      ThemeColorsEnum.success
    );
    resetForm();
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <Alert className="mb-3" severity={ThemeColorsEnum.info}>
            If you have questions or you are experiencing any issues, please let
            us know.
          </Alert>
          <AppForm
            initialValues={{
              subject: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
          >
            <FormTextField label="Subject" name="subject" required />
            <FormTextField
              as="textarea"
              label="Message"
              name="message"
              required
            />
            <SubmitButton loading={busy} />
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ContactPage;
