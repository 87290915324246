import { AppRoutes } from "../../constants/appRoutes";
import CustomerLocationForm from "../../components/forms/custom/CustomerLocationForm";
import Page from "../Page";
import React from "react";
import { useNavigate } from "react-router-dom";

const CustomerLocationFormPage = ({
  breadcrumbs,
  customerLocation = {},
  title,
  ...otherProps
}) => {
  const navigate = useNavigate();

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <CustomerLocationForm
        customerLocation={customerLocation}
        onSave={() =>
          navigate(
            AppRoutes.customerLocations.replace(
              ":id",
              customerLocation?.customerId
            )
          )
        }
      />
    </Page>
  );
};

export default CustomerLocationFormPage;
