import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const getBranchModules = async () => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.settings.getClientModules.replace(
    "{clientId}",
    clientId
  );
  const response = await httpService.get(url);

  if (response.ok) {
    localStorageService.setProperty(
      LocalStorageKeys.branchModules,
      response.data?.modules
    );
  }

  return response;
};

const getBranchSettings = async () => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.settings.getClientSettings.replace(
    "{clientId}",
    clientId
  );
  const response = await httpService.get(url);

  if (response.ok) {
    localStorageService.setProperty(
      LocalStorageKeys.branchSettings,
      response.data?.settings
    );
  }

  return response;
};

const getTimezones = async () => {
  const response = await httpService.get(Endpoints.settings.getTimezones);
  return response;
};

/**
 *
 * @param {string} id The user id
 * @returns
 */
const getUserSettings = async (id) => {
  const currentUser = await localStorageService.getProperty(
    LocalStorageKeys.currentUser
  );

  const url = Endpoints.settings.getUserSettings.replace(
    "{userId}",
    id ?? currentUser?.id
  );
  const response = await httpService.get(url);

  if (response.ok && currentUser?.id === id) {
    localStorageService.setProperty(
      LocalStorageKeys.currentUserSettings,
      response.data
    );
  }

  return response;
};

const saveBranchModules = async (payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.settings.saveClientModules.replace(
    "{clientId}",
    clientId
  );
  const response = await httpService.post(url, payload);

  if (response.ok) {
    localStorageService.setProperty(
      LocalStorageKeys.branchModules,
      response.data?.modules
    );
  }

  return response;
};

const saveBranchSettings = async (payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.settings.saveClientSettings.replace(
    "{clientId}",
    clientId
  );
  const response = await httpService.post(url, payload);

  if (response.ok) {
    localStorageService.setProperty(
      LocalStorageKeys.branchSettings,
      response.data?.settings
    );
  }

  return response;
};

const saveUserSettings = async (payload) => {
  const currentUser = await localStorageService.getProperty(
    LocalStorageKeys.currentUser
  );

  const url = Endpoints.settings.saveUserSettings.replace(
    "{userId}",
    payload?.userId
  );
  const response = await httpService.post(url, payload);

  if (response.ok && currentUser?.id === payload?.userId)
    localStorageService.setProperty(
      LocalStorageKeys.currentUserSettings,
      response.data
    );

  return response;
};

//-----------------------------------------//
const settingsApiService = {
  getBranchModules,
  getBranchSettings,
  getTimezones,
  getUserSettings,
  saveBranchModules,
  saveBranchSettings,
  saveUserSettings,
};

export default settingsApiService;
//-----------------------------------------//
