import { LoadingContext, ModalContext } from "../context/contextProvider";
import { ProductColumnsEnum, ThemeColorsEnum } from "../constants/enums";

import { AppRoutes } from "../constants/appRoutes";
import DropdownSelect from "../components/forms/DropdownSelect";
import Services from "../services/services";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const useProductsDataTable = (onDeleted = () => {}) => {
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);

  const handleDelete = async (product) => {
    setLoading(true);
    const response = await Services.api.products.delete(product.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the product",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Product deleted", ThemeColorsEnum.success);
    onDeleted(product);
  };

  const menuItems = (row) => [
    // Edit
    {
      // href: AppRoutes.products_edit.replace(":id", row.id),
      onClick: () =>
        navigate(AppRoutes.products_edit.replace(":id", row.id), {
          state: { product: row },
        }),
      title: "Edit",
    },
    { type: "divider" },
    // Delete
    {
      className: "text-danger",
      onClick: () => {
        showConfirmation(
          "Confirmation",
          "Do you really want to delete this product?",
          [
            {
              color: ThemeColorsEnum.quaternary,
              title: "Cancel",
            },
            {
              color: ThemeColorsEnum.danger,
              onClick: () => handleDelete(row),
              title: "Delete",
            },
          ]
        );
      },
      title: "Delete",
    },
  ];

  const columns = [
    {
      header: {
        className: "col-menu",
        id: ProductColumnsEnum.actionButton,
        name: "id",
      },
      cell: (row) => (
        <DropdownSelect
          color={ThemeColorsEnum.quaternary}
          fixedTitle
          items={menuItems(row)}
          itemLabelProperty="title"
          itemValueProperty="title"
          size="sm"
          style={{ width: "auto" }}
          title={<i className="fa fa-bars" />}
          // title={<MenuIcon />}
        />
      ),
      dataTitle: "Menu",
      className: "nowrap",
    },
    {
      header: {
        id: ProductColumnsEnum.name,
        name: "name",
        text: "Name",
        sortable: true,
      },
      cell: (row) => row.name,
      dataTitle: "Name",
      className: "nowrap",
    },
    {
      header: {
        id: ProductColumnsEnum.shortName,
        name: "shortName",
        text: "Abbreviation",
        sortable: true,
      },
      cell: (row) => row.shortName,
      dataTitle: "Abbreviation",
      className: "nowrap",
    },
    {
      header: {
        id: ProductColumnsEnum.notes,
        name: "notes",
        text: "Notes",
        sortable: true,
      },
      cell: (row) => row.notes,
      dataTitle: "Notes",
      className: "nowrap",
    },
    {
      header: {
        id: ProductColumnsEnum.order,
        name: "order",
        text: "Order",
        sortable: true,
      },
      cell: (row) => row.order,
      dataTitle: "Order",
      className: "nowrap",
    },
  ];

  return { columns };
};

export default useProductsDataTable;
