import React, { useContext, useEffect, useState } from "react";
import { AuthContext, LoadingContext } from "../../context/contextProvider";

import DashboardIcon from "@mui/icons-material/Dashboard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import { Navigate } from "react-router-dom";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import useCurrentPosition from "../../hooks/useCurrentPosition";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import Page from "../Page";

const title = "Users - Map";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PeopleIcon,
    title: "Users",
    to: AppRoutes.users,
  },
  {
    IconComponent: LocationOnIcon,
    title: "Map",
  },
];

const UsersMapPage = (props) => {
  const [usersGeolocations, setUsersGeolocations] = useState([]);

  const { setLoading } = useContext(LoadingContext);
  const { currentClient } = useContext(AuthContext);

  const { position, positionLoaded } = useCurrentPosition();
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    getUsersPositionAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const getUsersPositionAsync = async () => {
    setLoading(true);
    const response = await Services.api.users.getLastKnownLocations();
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch users locations",
        ThemeColorsEnum.error
      );

    //Don't allow 0, 0 or null
    const geoLocations = response.data.filter((x) => {
      return x.geolocation?.location.latitude != 0.0 && 
      x.geolocation?.location.longitude != 0.0 ||
      x.geolocation?.location.latitude == null ||
      x.geolocation?.location.longitude == null
    });
    setUsersGeolocations(geoLocations);
  };

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      {!!positionLoaded && (
        <Map centerPosition={position} zoom={7}>
          {usersGeolocations?.map((userGeolocation, index) => (
            <AppMarker
              clickable
              icon={`${process.env.PUBLIC_URL}/img/ic_pin_truck.png`}
              info={
                <>
                  <span className="info-window-text-title">
                    {userGeolocation.displayName}
                  </span>
                  <br />
                  <span className="info-window-text">
                    {userGeolocation.createdDateFormatted}
                  </span>
                </>
              }
              key={userGeolocation.id}
              position={{
                lat: userGeolocation.geolocation?.location?.latitude,
                lng: userGeolocation.geolocation?.location?.longitude,
              }}
            />
          ))}
        </Map>
      )}
    </Page>
  );
};

export default UsersMapPage;
