import "react-toastify/dist/ReactToastify.css";

import React, { useState } from "react";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "./context/contextProvider";

import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ConfirmationDialog from "./components/modals/ConfirmationDialog";
import ModalDialog from "./components/modals/ModalDialog";
import NavigationContainer from "./components/navigation/NavigationContainer";
import Overlay from "./components/Overlay";
import Constants from "./constants/constants";
import { LocalStorageKeys } from "./constants/storageKeys";
import Services from "./services/services";

const App = () => {
  const [branchModules, setBranchModules] = useState(
    Services.localStorage.getProperty(LocalStorageKeys.branchModules)
  );
  const [branchSettings, setBranchSettings] = useState(
    Services.localStorage.getProperty(LocalStorageKeys.branchSettings)
  );
  const [busy, setBusy] = useState(false);
  const [currentClient, setCurrentClient] = useState(
    Services.localStorage.getProperty(LocalStorageKeys.currentClient)
  );
  const [currentUser, setCurrentUser] = useState(
    Services.localStorage.getProperty(LocalStorageKeys.currentUser)
  );
  const [cache, setCache] = useState({
    customers: { ...Constants.defaults.pageCache },
    deliveryOrdersActive: { ...Constants.defaults.pageCache },
    deliveryOrdersCompleted: { ...Constants.defaults.pageCache },
    directDropsActive: { ...Constants.defaults.pageCache },
    directDropsCompleted: { ...Constants.defaults.pageCache },
    products: { ...Constants.defaults.pageCache },
    serviceOrdersActive: { ...Constants.defaults.pageCache },
    serviceOrdersCompleted: { ...Constants.defaults.pageCache },
    users: { ...Constants.defaults.pageCache },
  });
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState({
    buttons: null,
    message: null,
    title: null,
    visible: false,
  });
  const [showModal, setShowModal] = useState({
    body: null,
    buttons: null,
    content: null,
    size: "md",
    title: null,
    visible: false,
  });
  const [userSettings, setUserSettings] = useState(
    Services.localStorage.getProperty(LocalStorageKeys.currentUserSettings)
  );
  const [enrolledUsers, setEnrolledUsers] = useState([]);

  const authContextValue = {
    branchModules,
    setBranchModules,
    branchSettings,
    setBranchSettings,
    currentUser,
    setCurrentUser,
    currentClient,
    setCurrentClient,
    userSettings,
    setUserSettings,
    cache,
    setCache,
    enrolledUsers,
    setEnrolledUsers,
  };

  const modalContextValue = {
    // Confirmation dialog with buttons
    showConfirmation: (title, message, buttons) =>
      setShowConfirmation({
        buttons,
        message,
        title,
        visible: true,
      }),
    closeConfirmation: () =>
      setShowConfirmation({
        buttons: null,
        message: null,
        title: null,
        visible: false,
      }),
    // Generic dialog with buttons
    showModal: (title, body, buttons, content, size = "md") =>
      setShowModal({
        body,
        buttons,
        content,
        size,
        title,
        visible: true,
      }),
    closeModal: () =>
      setShowModal({
        body: null,
        buttons: null,
        content: null,
        title: null,
        visible: false,
      }),
  };

  return (
    <Router forceRefresh={false}>
      <LoadingContext.Provider value={{ busy, setBusy, loading, setLoading }}>
        <AuthContext.Provider value={authContextValue}>
          <ModalContext.Provider value={modalContextValue}>
            <NavigationContainer />
            {/* <LoginPage /> */}
            <Overlay visible={loading} />

            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            {/* ------------------- PORTALS - start ------------------- */}
            {ReactDOM.createPortal(
              <ConfirmationDialog
                buttons={showConfirmation.buttons}
                message={showConfirmation.message}
                show={showConfirmation.visible}
                title={showConfirmation.title}
              />,
              document.getElementById("overlay-root")
            )}
            {ReactDOM.createPortal(
              <ModalDialog
                body={showModal.body}
                buttons={showModal.buttons}
                content={showModal.content}
                show={showModal.visible}
                size={showModal.size}
                title={showModal.title}
              />,
              document.getElementById("overlay-root")
            )}
            {/* ------------------- PORTALS - end ------------------- */}
          </ModalContext.Provider>
        </AuthContext.Provider>
      </LoadingContext.Provider>
    </Router>
  );
};

export default App;
