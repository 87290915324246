import Page from "../Page";
import {
	ScheduledOrderColumnsEnum,
	SortDirections,
	StatusEnum,
	ThemeColorsEnum,
	RolesEnum,
} from "../../constants/enums";
import { Col, Row } from "react-bootstrap";
import Card from "../../components/cards/Card";
import Services from "../../services/services";
import Button from "../../components/buttons/Button";
import Constants from "../../constants/constants";
import { AppRoutes } from "../../constants/appRoutes";
import { Navigate, useLocation } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Search } from "../../models/searchFilterRequest";
import { WorkOrderTypesEnum } from "../../constants/enums";
import AddButton from "../../components/buttons/AddButton";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import React, { useContext, useEffect, useState } from "react";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import NSDataTable from "../../components/dataTables/NSDataTable";
import LoadingButton from "../../components/buttons/LoadingButton";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import useSchedulesDataTable from "../../hooks/useSchedulesDataTable";
import { AuthContext, LoadingContext } from "../../context/contextProvider";
import EventAvailableTwoToneIcon from "@mui/icons-material/EventAvailableTwoTone";
import ScheduledOrderFilterRequest from "../../models/scheduledOrderFilterRequest";
import NSDataTablePagination from "../../components/dataTables/NSDataTablePagination";

const title = "Schedules";
const breadcrumbs = [
	{
		IconComponent: DashboardIcon,
		title: "Dashhboard",
		to: AppRoutes.dashboard,
	},
	{
		IconComponent: EventRepeatIcon,
		title: title,
	},
];


const ScheduledOrdersPage = (props) => {

	// data-tables - start
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(Constants.defaults.rowsPerPage);
	const [paginationData, setPaginationData] = useState({
		from: null,
		pages: null,
		records: null,
		to: null,
	});
	const [searchValue, setSearchValue] = useState("");
	const [sortOptions, setSortOptions] = useState({
		column: ScheduledOrderColumnsEnum.dueOn,
		direction: SortDirections.ascending,
	});
	// data-tables - end
	const [schedules, setSchedules] = useState([]);

	const { setLoading } = useContext(LoadingContext);
	const { currentClient } = useContext(AuthContext);

	const location = useLocation();
	const dataTableInfo = useSchedulesDataTable(handleDeleted);
	const userInfo = useCurrentUserInfo();

	useEffect(() => {
		getSchedulesAsync((currentPage - 1) * pageSize, pageSize, searchValue);
		return () => { };
	}, [currentClient, currentPage, pageSize, searchValue, sortOptions, location.state]); // eslint-disable-line react-hooks/exhaustive-deps

	const getSchedulesAsync = async (
		start = 0,
		take = Constants.defaults.rowsPerPage,
		searchText = null
	) => {
		// filter
		const filter = new ScheduledOrderFilterRequest();
		filter.search = new Search(searchText);
		filter.start = start;
		filter.length = take;
		filter.status = StatusEnum.active;
		filter.isDirectDrop = (location.state?.workOrderType === WorkOrderTypesEnum.directDrop) ? true : false;
		filter.workOrderType =
			(location.state?.workOrderType === WorkOrderTypesEnum.workOrder ? 0 :
				(location.state?.workOrderType === WorkOrderTypesEnum.directDrop ? 1 :
					(location.state?.workOrderType === WorkOrderTypesEnum.serviceOrder ? 2 : null)));
		filter.isSchedule = true;
		filter.order[0].column = sortOptions.column; // workOrdersDataTables.defaultSortColumn;
		filter.order[0].dir = sortOptions.direction; // workOrdersDataTables.defaultSortOrder;


		setLoading(true);
		const response = await Services.api.scheduledOrders.filterScheduledOrders(
			JSON.stringify(filter)
		);
		setLoading(false);

		if (!response.ok)
			return Services.utility.toast(
				"Could not fetch the schedules",
				ThemeColorsEnum.error
			);

		setSchedules(response.data?.data);
		setPaginationData({
			from: (currentPage - 1) * pageSize + 1,
			pages: response.data?.pagesTotal,
			records: response.data?.recordsTotal,
			to:
				currentPage * pageSize > response.data?.recordsTotal
					? response.data?.recordsTotal
					: currentPage * pageSize,
		});
	};
	const handleRunSchedules = async () => {
		if (userInfo?.role !== RolesEnum.superadmin) {
			Services.utility.toast("You do not have permission to run schedules.", ThemeColorsEnum.error);
			return;
		}

		try {
			setLoading(true);
			const response = await Services.api.scheduledOrders.checkWorkOrderSchedules();
			if (!response || !response?.ok) {
				Services.utility.toast("Schedules run successfully. Response: ".concat(response.data), ThemeColorsEnum.success);
			}
			else {
				Services.utility.toast("Response from server was not ok", ThemeColorsEnum.error);
			}
			
		} catch (error) {
			Services.utility.toast("Failed to run schedules.", ThemeColorsEnum.error);
		} finally {
			setLoading(false);
		}
	};

	function handleDeleted(schedule) {
		setSchedules(schedules.filter((x) => x.id !== schedule.id));
	}

	const handlePageChange = async (page) => {
		setCurrentPage(page);
	};

	const handleRowsPerPageChange = (newPerPage, currentPage) => {
		setCurrentPage(1);
		setPageSize(newPerPage);
	};

	const handleSearch = (searchValue) => {
		setSearchValue(searchValue);
	};

	const handleSort = (column, direction) => {
		setCurrentPage(1);
		setSortOptions({ column: column, direction: direction });
	};

	// if (!currentClient.hasSchedules)
	//   return (
	//     <Navigate
	//       to={{
	//         pathname: AppRoutes.deliveryOrders,
	//       }}
	//     />
	//   );

	const DataTableButtons = (
		<>
			<AddButton
				state={location.state}
				to={AppRoutes.scheduledOrders_create}
				visible={userInfo.canCreateDeliveryOrders}
			/>
			{/* <LoadingButton
          color={ThemeColorsEnum.quaternary}
          loading={loading}
          onClick={handleRefresh}>
          Refresh
        </LoadingButton> */}
		</>
	);

	return (
		<Page breadcrumbs={breadcrumbs} title={title}>
			<Row>
				<Col>
					<Card>
						<Card.Body className="overflow-auto">
							<div className="dataTables_wrapper dt-bootstrap5 no-footer">
								{/* **** BOOTSTRAP PAGINATION **** */}
								<NSDataTablePagination
									from={paginationData?.from}
									lastPage={paginationData?.pages}
									page={currentPage}
									pageSize={pageSize}
									total={paginationData?.records}
									to={paginationData?.to}
									onPageChange={handlePageChange}
									onPageSizeChange={(newPerPage) =>
										handleRowsPerPageChange(newPerPage, currentPage)
									}
								/>
								{/* **** DATA TABLE **** */}
								<NSDataTable
									buttons={DataTableButtons}
									className="dataTable table-no-more"
									// dense
									columns={dataTableInfo.columns}
									//conditionalRowStyles={dataTableInfo.conditionalRowStyles}
									data={schedules}
									emptyDataComponent={<EmptyDataBox />}
									onSearch={handleSearch}
									onSort={handleSort}
									paginate
									search
									sortBy={sortOptions.column}
									sortDirection={sortOptions.direction}
								/>
								{/* **** BOOTSTRAP PAGINATION **** */}
								<NSDataTablePagination
									from={paginationData?.from}
									lastPage={paginationData?.pages}
									page={currentPage}
									pageSize={pageSize}
									showRecordsInfo
									total={paginationData?.records}
									to={paginationData?.to}
									onPageChange={handlePageChange}
									onPageSizeChange={(newPerPage) =>
										handleRowsPerPageChange(newPerPage, currentPage)
									}
								/>
							</div>
						</Card.Body>
					</Card>
				</Col>
				{userInfo.isSuperAdmin && <Col lg={12}>
					<Button
						className="btn-block btn-lg"
						color={ThemeColorsEnum.warning}
						IconComponent={EventAvailableTwoToneIcon}
						onClick={handleRunSchedules}
						title="Run Schedules"
						//to={AppRoutes.deliveryOrders}
					/>
				</Col>}
			</Row>
		</Page>
	);
};

export default ScheduledOrdersPage;
