import { SearchFilterRequest } from "./searchFilterRequest";
import { StatusEnum } from "../constants/enums";

class ServiceOrderFilterRequest extends SearchFilterRequest {
  constructor() {
    super();

    this.assignedToUserId = null;
    this.completedByUserId = null;
    this.completedOnFrom = null;
    this.completedOnTo = null;
    this.customerId = null;
    this.dashboardType = null;
    this.orderStatusId = null;
    this.status = StatusEnum.active;
  }
}

export default ServiceOrderFilterRequest;
