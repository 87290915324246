import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../context/contextProvider";
import {
  StatusEnum,
  ThemeColorsEnum,
  UserColumnsEnum,
} from "../constants/enums";

import { AppRoutes } from "../constants/appRoutes";
import BranchPickerModal from "../components/modals/custom/BranchPickerModal";
import DropdownSelect from "../components/forms/DropdownSelect";
import Services from "../services/services";
import UserPreferencesModal from "../components/modals/custom/UserPreferencesModal";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const useUsersDataTable = (
  status = StatusEnum.active,
  onDeleted = () => {}
) => {
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);
  const { showConfirmation, showModal } = useContext(ModalContext);
  const { currentUser, setUserSettings } = useContext(AuthContext);

  const attachUserToBranch = async (branchId, user) => {
    setLoading(true);
    const response = await Services.api.users.attachToBranch(
      user?.id,
      branchId
    );
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not add the user to the new branch",
        ThemeColorsEnum.error
      );

    Services.utility.toast(
      "The user was added successfully",
      ThemeColorsEnum.success
    );
  };

  const handleAttachToBranch = async (user, event) => {
    setLoading(true);
    const response = await Services.api.users.availableBranches(user?.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the branches",
        ThemeColorsEnum.error
      );

    const branches = response.data;
    showModal(
      "Pick a branch",
      null,
      null,
      <BranchPickerModal
        branches={branches}
        onSave={attachUserToBranch}
        user={user}
      />
    );
  };

  const handleDelete = async (user, fromAllBranches = false) => {
    setLoading(true);
    const response = await Services.api.users.delete(user.id, fromAllBranches);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the user",
        ThemeColorsEnum.error
      );

    Services.utility.toast("User deleted", ThemeColorsEnum.success);
    onDeleted(user);
  };

  const handleReactivate = async (user) => {
    setLoading(true);
    const response = await Services.api.users.reactivate(user?.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not reactivate the user",
        ThemeColorsEnum.error
      );

    Services.utility.toast(
      "The user was reactivated successfully",
      ThemeColorsEnum.success
    );
    onDeleted(user);
  };

  const handleShowPreferences = (user) => {
    showModal(
      `User Preferences - ${user?.fullName}`,
      null,
      null,
      <UserPreferencesModal onSave={savePreferences} user={user} />
    );
  };

  const savePreferences = async (data) => {
    setLoading(true);
    const response = await Services.api.settings.saveUserSettings(data);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save preferences",
        ThemeColorsEnum.error
      );

    // update current user settings globally
    if (currentUser?.id === response.data?.userId)
      setUserSettings(response.data);

    Services.utility.toast("Preferences saved", ThemeColorsEnum.success);
  };

  const menuItems = (row) =>
    status === StatusEnum.active
      ? [
          // Edit
          {
            onClick: () =>
              navigate(AppRoutes.users_edit.replace(":id", row.id), {
                state: { user: row },
              }),
            title: "Edit",
          },
          { type: "divider" },
          // Attach to branch
          ...(currentUser?.id !== row.id
            ? [
                {
                  onClick: (event) => handleAttachToBranch(row, event),
                  title: "Add to another branch",
                },
              ]
            : []),
          // Preferences
          {
            onClick: (event) => handleShowPreferences(row, event),
            title: "Preferences",
          },
          // Delete
          ...(currentUser?.id !== row.id
            ? [
                { type: "divider" },
                {
                  className: "text-danger",
                  onClick: () => {
                    showConfirmation(
                      "Confirmation",
                      "Do you really want to delete this user?",
                      [
                        {
                          color: ThemeColorsEnum.quaternary,
                          title: "Cancel",
                        },
                        {
                          color: ThemeColorsEnum.danger,
                          onClick: () => handleDelete(row, false),
                          title: "Delete",
                        },
                      ]
                    );
                  },
                  title: "Delete",
                },
                {
                  className: "text-danger",
                  onClick: () => {
                    showConfirmation(
                      "Confirmation",
                      "Do you really want to delete this user from all branches?",
                      [
                        {
                          color: ThemeColorsEnum.quaternary,
                          title: "Cancel",
                        },
                        {
                          color: ThemeColorsEnum.danger,
                          onClick: () => handleDelete(row, true),
                          title: "Delete",
                        },
                      ]
                    );
                  },
                  title: "Delete from all branches",
                },
              ]
            : []),
        ]
      : [
          // Reactivate
          {
            onClick: () => handleReactivate(row),
            title: "Reactivate",
          },
        ];

  const columns = [
    {
      header: {
        className: "col-menu",
        id: UserColumnsEnum.actionButton,
        name: "id",
      },
      cell: (row) => (
        <DropdownSelect
          color={ThemeColorsEnum.quaternary}
          fixedTitle
          items={menuItems(row)}
          itemLabelProperty="title"
          itemValueProperty="title"
          size="sm"
          style={{ width: "auto" }}
          title={<i className="fa fa-bars" />}
          // title={<MenuIcon />}
        />
      ),
      dataTitle: "Menu",
      className: "nowrap",
    },
    {
      header: {
        id: UserColumnsEnum.userName,
        name: "userName",
        text: "Username",
        sortable: true,
      },
      cell: (row) => row.userName,
      dataTitle: "Username",
      className: "nowrap",
    },
    {
      header: {
        id: UserColumnsEnum.fullName,
        name: "fullName",
        text: "Full Name",
        sortable: true,
      },
      cell: (row) => row.fullName,
      dataTitle: "Full Name",
      className: "",
    },
    {
      header: {
        id: UserColumnsEnum.email,
        name: "email",
        text: "Email",
        sortable: true,
      },
      cell: (row) => row.email,
      dataTitle: "Email",
      className: "",
    },
    {
      header: {
        id: UserColumnsEnum.phone,
        name: "phone",
        text: "Phone",
        sortable: true,
      },
      cell: (row) => row.phone,
      dataTitle: "Phone",
      className: "nowrap",
    },
    {
      header: {
        id: UserColumnsEnum.roles,
        name: "roles",
        text: "Roles",
        sortable: true,
      },
      cell: (row) => row.roles,
      dataTitle: "Roles",
      className: "nowrap",
    },
    {
      header: {
        id: UserColumnsEnum.primaryClient,
        name: "primaryClient",
        text: "Main Branch",
        sortable: true,
      },
      cell: (row) => row.primaryClient,
      dataTitle: "Main Branch",
      className: "nowrap",
    },
    {
      header: {
        id: UserColumnsEnum.device,
        name: "device",
        text: "Device",
        sortable: true,
      },
      cell: (row) => row.latestDevice,
      dataTitle: "Device",
      className: "max-width-120",
    },
    {
      header: {
        id: UserColumnsEnum.createdDate,
        name: "createdDate",
        text: "Added on",
        sortable: true,
      },
      cell: (row) => row.createdDate,
      dataTitle: "Added on",
      className: "nowrap",
    },
    {
      header: {
        id: UserColumnsEnum.deletedDate,
        name: "deletedDate",
        text: "Deleted on",
        sortable: true,
      },
      cell: (row) => row.deletedDate,
      dataTitle: "Deleted on",
      className: "nowrap",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) =>
        status === StatusEnum.active &&
        row.rowClass === ThemeColorsEnum.externalUser,
      classNames: [ThemeColorsEnum.externalUser],
    },
  ];

  return { columns, conditionalRowStyles };
};

export default useUsersDataTable;
