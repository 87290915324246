import React from "react";
import DetailsRow from "../../components/DetailsRow";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";

const TattleDetails = ({ tattle, ...otherProps }) => {
  const userInfo = useCurrentUserInfo();

  const tattleLocation = {
    lat: tattle?.latitude,
    lng: tattle?.longitude,
  };

  return (
    <>
      <Map
        centerPosition={tattleLocation}
        className="map-container-modal mb-3"
        zoom={7}
      >
        <AppMarker
          clickable
          info={
            <>
              <span className="info-window-text-title">{tattle.name}</span>
              <br />
              <span className="info-window-text">
                {tattle.customerDisplayName}
              </span>
            </>
          }
          key={tattle.uniqueId}
          position={tattleLocation}
        />
      </Map>
      <dl className="horizontal">
        <DetailsRow title="Id" details={tattle?.id} />
        <DetailsRow title="Tattle ID" details={tattle?.uniqueId} />
        <DetailsRow title="Name" details={tattle?.name} />
        <DetailsRow
          title="Branch"
          details={tattle?.clientName}
          visible={userInfo.isSuperAdmin}
        />
        <DetailsRow title="Customer" details={tattle?.customerDisplayName} />
        <DetailsRow title="Product" details={tattle?.productShortName} />
        <DetailsRow
          title="Direct Drop"
          details={tattle?.isDirectDrop ? "Yes" : "No"}
        />
        <DetailsRow
          title="Entered"
          details={`${Services.utility.formatFullDateAndTime(
            tattle?.createdDate
          )} (${tattle?.createdByName})`}
        />
        <DetailsRow
          title="Coordinates"
          details={`${tattle?.latitude},${tattle?.longitude}`}
        />
      </dl>
    </>
  );
};

export default TattleDetails;
