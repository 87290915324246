import { OrderStatusIdEnum, ThemeColorsEnum } from "../constants/enums";

const getBadgeClassByOrderStatus = (orderStatusId) => {
  switch (orderStatusId) {
    case OrderStatusIdEnum.completed:
      return "badge-completed";

    case OrderStatusIdEnum.inProgress:
      return "badge-in-progress";

    case OrderStatusIdEnum.new:
      return "badge-new";

    case OrderStatusIdEnum.pending:
      return "badge-pending";

    default:
      return;
  }
};

const getMarker = (workOrder) => {
  switch (workOrder?.rowClass) {
    case ThemeColorsEnum.completed:
      return `${process.env.PUBLIC_URL}/img/ic_pin_green.png`;

    case ThemeColorsEnum.due:
      return `${process.env.PUBLIC_URL}/img/ic_pin_yellow.png`;

    case ThemeColorsEnum.overdue:
      return `${process.env.PUBLIC_URL}/img/ic_pin_red.png`;

    default:
      return `${process.env.PUBLIC_URL}/img/ic_pin_gray.png`;
  }
};

//------------------------------------------------
const workOrdersService = { getBadgeClassByOrderStatus, getMarker };

export default workOrdersService;
//------------------------------------------------
