import "../assets/components/css/autocomplete.css";

import HelpIcon from "@mui/icons-material/Help";
import React, { useContext, useEffect, useState } from "react";
import { ThemeColorsEnum } from "../constants/enums";
import { LocalStorageKeys } from "../constants/storageKeys";
import { AuthContext } from "../context/contextProvider";
import useCurrentPosition from "../hooks/useCurrentPosition";
import localStorageService from "../services/localStorageService";
import Services from "../services/services";
import Page from "./Page";

const title = "";
const breadcrumbs = [
  {
    IconComponent: HelpIcon,
    title: "",
  },
];

const TestPage = (props) => {
  const { position, positionLoaded } = useCurrentPosition();
  const [zoom, setZoom] = useState(7);
  const [evergreenCustomers, setEvergreenCustomers] = useState([]);
  const [innisfailCustomers, setInnisfailCustomers] = useState([]);
  const [westviewCustomers, setWestviewCustomers] = useState([]);
  const { currentClient, currentUser } = useContext(AuthContext);

  const accessToken = localStorageService.getProperty(
    LocalStorageKeys.accessToken
  );
  const refreshToken = localStorageService.getProperty(
    LocalStorageKeys.refreshToken
  );

  const payload = {
    access_token: accessToken,
    refresh_token: refreshToken,
  };

  useEffect(() => {
    initAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getEvergreenGeolocationsAsync = async () => {
    const response = await Services.api.customers.getAllCustomersLocations(
      2062
    );

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the pins",
        ThemeColorsEnum.error
      );

    setEvergreenCustomers(response.data);
  };

  const getInnisfailGeolocationsAsync = async () => {
    const response = await Services.api.customers.getAllCustomersLocations(
      2059
    );

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the pins",
        ThemeColorsEnum.error
      );

    setInnisfailCustomers(response.data);
  };

  const getWestviewGeolocationsAsync = async () => {
    const response = await Services.api.customers.getAllCustomersLocations(
      2080
    );

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the pins",
        ThemeColorsEnum.error
      );

    setWestviewCustomers(response.data);
  };

  const initAsync = async () => {
    if (!!currentUser?.clients?.find((x) => x.id === 2062))
      await getEvergreenGeolocationsAsync();
    if (!!currentUser?.clients?.find((x) => x.id === 2059))
      await getInnisfailGeolocationsAsync();
    if (!!currentUser?.clients?.find((x) => x.id === 2080))
      await getWestviewGeolocationsAsync();
  };

  return <Page breadcrumbs={breadcrumbs} title={title}></Page>;
};

export default TestPage;
