import { NodePositions } from "../constants/enums";
import Services from "../services/services";
import { useEffect } from "react";

const useImportCss = (
  resourceUrl,
  condition = true,
  position = NodePositions.Append
) => {
  useEffect(() => {
    if (condition) {
      const css = Services.utility.appendStyleSheet(resourceUrl, position);
      return () => {
        Services.utility.removeStyleSheet(css);
      };
    }
  }, [resourceUrl, condition, position]);
};

export default useImportCss;
