import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import { LoadingContext } from "../../context/contextProvider";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import TattleFormPage from "./TattleFormPage";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: SettingsRemoteIcon,
    title: "Tattles",
    to: AppRoutes.products,
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const TattleEditPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();

  const [tattle, setTattle] = useState(state?.tattle);
  const { setLoading } = useContext(LoadingContext);

  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    if (id && !state?.tattle) getTattleAsync(id);
  }, [id, state]); // eslint-disable-line react-hooks/exhaustive-deps

  const getTattleAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.tattles.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the tattle",
        ThemeColorsEnum.error
      );

    setTattle(response.data);
  };

  if (!userInfo?.isAdmin)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <TattleFormPage
      breadcrumbs={breadcrumbs}
      tattle={tattle}
      title="Tattles - Edit"
    />
  );
};

export default TattleEditPage;
