import { AuthContext, LoadingContext } from "../../context/contextProvider";
import { Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";
import {
  ServiceCategoryColumnsEnum,
  SortDirections,
  ThemeColorsEnum,
} from "../../constants/enums";

import AddButton from "../../components/buttons/AddButton";
import { AppRoutes } from "../../constants/appRoutes";
import Card from "../../components/cards/Card";
import Constants from "../../constants/constants";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import NSDataTable from "../../components/dataTables/NSDataTable";
import NSDataTablePagination from "../../components/dataTables/NSDataTablePagination";
import Page from "../Page";
import Services from "../../services/services";
import useCategoriesDataTable from "../../hooks/useCategoriesDataTable";

const title = "Service Categories";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: FormatListBulletedIcon,
    title: title,
  },
];

const ServiceOrderCategoriesPage = (props) => {
  // data-tables - start
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(Constants.defaults.rowsPerPage);
  const [paginationData, setPaginationData] = useState({
    from: null,
    pages: null,
    records: null,
    to: null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [sortOptions, setSortOptions] = useState({
    column: ServiceCategoryColumnsEnum.name,
    direction: SortDirections.ascending,
  });
  // data-tables - end
  const [categories, setCategories] = useState([]);

  const { setLoading } = useContext(LoadingContext);
  const { currentClient } = useContext(AuthContext);

  const dataTableInfo = useCategoriesDataTable(handleDeleted);

  useEffect(() => {
    getCategoriesAsync((currentPage - 1) * pageSize, pageSize, searchValue);
    return () => {};
  }, [currentClient, currentPage, pageSize, searchValue, sortOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCategoriesAsync = async (
    start = 0,
    take = Constants.defaults.rowsPerPage,
    searchText = null
  ) => {
    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(searchText);
    filter.start = start;
    filter.length = take;
    filter.order[0].column =
      sortOptions.column ?? ServiceCategoryColumnsEnum.name;
    filter.order[0].dir = sortOptions.direction ?? SortDirections.ascending;

    setLoading(true);
    const response = await Services.api.serviceCategories.getCategories(filter);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the categories",
        ThemeColorsEnum.error
      );

    setCategories(response.data?.data);
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      pages: response.data?.pagesTotal,
      records: response.data?.recordsTotal,
      to:
        currentPage * pageSize > response.data?.recordsTotal
          ? response.data?.recordsTotal
          : currentPage * pageSize,
    });
  };

  function handleDeleted(category) {
    setCategories(categories.filter((x) => x.id !== category.id));
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, currentPage) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  const handleSort = (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  buttons={
                    <AddButton to={AppRoutes.serviceOrderCategories_create} />
                  }
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  //   conditionalRowStyles={conditionalRowStyles}
                  data={categories}
                  emptyDataComponent={<EmptyDataBox />}
                  onSearch={handleSearch}
                  onSort={handleSort}
                  paginate
                  search
                  sortBy={sortOptions.column}
                  sortDirection={sortOptions.direction}
                />
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  showRecordsInfo
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default ServiceOrderCategoriesPage;
