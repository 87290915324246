import { Link } from "react-router-dom";
import React from "react";

const BreadcrumbItem = ({
  href,
  icon,
  IconComponent,
  title,
  ...otherProps
}) => {
  const clickableItem = href && (
    <Link to={href}>
      <span>
        {(icon && <i className={icon}></i>) || IconComponent ? (
          <IconComponent />
        ) : null}
        <span>{title}</span>
      </span>
    </Link>
  );

  const textItem = (
    <span>
      {(icon && <i className={icon}></i>) || IconComponent ? (
        <IconComponent />
      ) : null}
      <span>{title}</span>
    </span>
  );

  // either return the link or just the text
  return <li>{clickableItem ?? textItem}</li>;
};

export default BreadcrumbItem;
