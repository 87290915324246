import React, { useContext, useEffect, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext } from "../../context/contextProvider";
import BuildIcon from "@mui/icons-material/Build";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Navigate } from "react-router-dom";
import ServiceOrderFormPage from "./ServiceOrderFormPage";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import { useLocation } from "react-router-dom";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: BuildIcon,
    title: "Service Orders",
    to: AppRoutes.serviceOrders,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const ServiceOrderCreatePage = (props) => {
  const { state } = useLocation();
  const [serviceOrder, setServiceOrder] = useState();

  const { branchModules } = useContext(AuthContext);
  const userInfo = useCurrentUserInfo();

  useEffect(() => {
    setServiceOrder(state?.serviceOrder);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!branchModules?.serviceOrders || !userInfo.canCreateDeliveryOrders)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <ServiceOrderFormPage
      breadcrumbs={breadcrumbs}
      serviceOrder={serviceOrder}
      title="Service Orders - New"
    />
  );
};

export default ServiceOrderCreatePage;
