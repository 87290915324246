import React, { useEffect, useMemo, useState } from "react";
import Select, { components } from "react-select";

import { ElementSize } from "../../constants/enums";

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#0c2340",
    borderColor: "#0c2340 !important",
    borderRadius: "0.25rem",
    borderWidth: 0,
    boxShadow: state.isFocused
      ? "0 1px 1px rgb(0 0 0 / 8%) inset, 0 0 8px rgb(223 105 26 / 30%) !important;"
      : null,
  }),
  groupHeading: (provided, state) => ({
    ...provided,
    color: "#fcba03",
  }),
  input: (provided, state) => ({
    ...provided,
    color: "#eeeeee",
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: "#0c2340",
  }),
  option: (provided, state) => {
    state.innerProps.onMouseDown = (event) =>
      (event.target.style.backgroundColor = "#40db81");

    return {
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused ? "#A9C833" : "revert",
      color: state.isSelected ? "#ffffff" : "#eeeeee",
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    };
  },
  placeholder: (provided, state) => ({
    ...provided,
    // display: "none",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    //alignContent: "stretch",
    color: "#eeeeee",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: "revert",
    opacity: state.isDisabled ? 0.75 : 1,
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: ".375rem .75rem",
    "& .react-select__input-container": {
      margin: 0,
    },
  }),
};

const debugName = "timeZoneId";

/**
 * https://react-select.com/home
 *
 * @param {*} param0
 * @returns
 */
const AppSelect = ({
  allItems,
  autocomplete,
  className,
  clearable,
  disabled = false,
  multi,
  initialText,
  itemLabelProperty,
  items,
  itemValueProperty,
  name,
  onBlur = () => {},
  onChange = () => {},
  onInputChange = () => {},
  placeholder,
  searchable = false,
  size = ElementSize.md,
  value,
  ...otherProps
}) => {
  const [selectedItem, setSelectedItem] = useState(findSelectedItem(value));

  const containerClass = `${className} react-select-container`.trim();

  useEffect(() => {
    setSelectedItem(findSelectedItem(value));
  }, [allItems, items, value]); // eslint-disable-line react-hooks/exhaustive-deps

  function findSelectedItem(selectedValue) {
    const allOptions = allItems ?? items;

    if ((selectedValue !== 0 ) && !selectedValue) return null;

    let match = null;
    if (multi)
      match = allOptions?.filter((x) =>
        selectedValue?.includes(x?.[itemValueProperty])
      );
    else {
      match = allOptions?.find((x) => x?.[itemValueProperty] === selectedValue);
      
    }

    if (!match || match?.length === 0) {
      const options = allOptions?.flatMap((x) => x.options);

      if (multi)
        match = options?.filter((x) =>
          selectedValue?.includes(x?.[itemValueProperty])
        );
      else
        match = options?.find((x) => x?.[itemValueProperty] === selectedValue);
    }

  
    return match;
  }

  const handleChange = (item) => {
    setSelectedItem(item);
    onChange(item);
  };

  const handleInputChange = (text, event) => {
    if (event.action === "input-change") {
      onInputChange(text);
    }
  };

  const Input = useMemo(
    () => (props) => {
      // add attribues to the component below
      return <components.Input name={name} {...props} />;
    },
    [name]
  );

  return (
    <Select
      // blurInputOnSelect
      className={containerClass}
      classNamePrefix="react-select"
      components={{ Input }}
      // defaultValue={defaultValue}
      filterOption={(option, search) => {
        if (!search) return true;

        return autocomplete
          ? option
          : option?.label?.toLowerCase()?.includes(search?.toLowerCase());
      }}
      getOptionLabel={(option) => option[itemLabelProperty]}
      getOptionValue={(option) => option[itemValueProperty]}
      isClearable={clearable ?? searchable}
      isDisabled={disabled}
      isMulti={multi}
      isSearchable={searchable}
      name={name}
      onBlur={onBlur}
      onChange={handleChange}
      onInputChange={handleInputChange}
      options={items}
      placeholder={placeholder}
      styles={customStyles}
      value={selectedItem}
      {...otherProps}
    />
  );
};

export default AppSelect;
