import * as Yup from "yup";

import React, { useContext, useEffect } from "react";
import { Col, Row } from "react-bootstrap";

import { ThemeColorsEnum } from "../../../constants/enums";
import Schemas from "../../../constants/schemas";
import { LoadingContext } from "../../../context/contextProvider";
import useBranchInfo from "../../../hooks/useBranchInfo";
import Services from "../../../services/services";
import LoadingButton from "../../buttons/LoadingButton";
import Card from "../../cards/Card";
import AppForm from "../Form";
import FormGoogleAddressField from "../FormGoogleAddressField";
import FormTextField from "../FormTextField";
import SubmitButton from "../SubmitButton";

const validationSchema = Yup.object().shape({
  company: Yup.string().min(1).required().label("Name"),
  email: Yup.string().email().min(1).nullable().label("Email"),
  phone: Schemas.fields.phoneNumber,
  locationName: Yup.string()
    .when(["locationLatitude", "locationLongitude"], {
      is: (lat, lng) => !!lat || !!lng,
      then: Yup.string().min(1).required(),
    })
    .label("Location name"),
});

const CustomerForm = ({
  customer,
  hideLocationForm,
  modal,
  onCancel = () => {},
  onSave = () => {},
  ...otherProps
}) => {
  const { busy, setBusy } = useContext(LoadingContext);

  const { isPetroleumBranch, isPropaneBranch } = useBranchInfo();

  useEffect(() => {
    timeout(500);
  }, []);

  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  const handleAddressChange = (text, place, { setFieldValue }) => {
    setFieldValue("locationName", place.name);
    setFieldValue("locationLatitude", place.geometry.location.lat());
    setFieldValue("locationLongitude", place.geometry.location.lng());
  };

  const handleSubmit = async (values) => {
    setBusy(true);
    const response = await Services.api.customers.save({
      ...customer,
      ...values,
    });
    setBusy(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the customer.",
        ThemeColorsEnum.error,
        response
      );

    // now save the location if present
    if (!!values.locationName) {
      setBusy(true);
      const location = {
        id: values.locationId,
        fullAddress: values.locationFullAddress,
        name: values.locationName,
        latitude: values.locationLatitude,
        longitude: values.locationLongitude,
      };
      const saveLocationResponse = await Services.api.customers.saveLocation(
        response.data?.id,
        location
      );
      setBusy(false);

      if (!saveLocationResponse.ok)
        Services.utility.toast(
          "Could not save the location.",
          ThemeColorsEnum.error,
          saveLocationResponse
        );
    }

    Services.utility.toast("Saved", ThemeColorsEnum.success);
    onSave(response.data);
  };

  return (
    <AppForm
      initialValues={{
        address1: customer?.address1 ?? "",
        address2: customer?.address2 ?? "",
        city: customer?.city ?? "",
        comment1: customer?.comment1 ?? "",
        comment2: customer?.comment2 ?? "",
        company: customer?.company ?? "",
        contactName: customer?.contactName ?? "",
        email: customer?.email ?? "",
        // latitude: customer?.geolocation?.location?.latitude ?? "",
        // longitude: customer?.geolocation?.location?.longitude ?? "",
        ltd: customer?.ltd ?? "",
        memberNumber: customer?.memberNumber ?? "",
        notes: customer?.notes ?? "",
        permanentInstructions: customer?.permanentInstructions ?? "",
        phone: customer?.phone ?? "",
        postalCode: customer?.postalCode ?? "",
        ramCard: customer?.ramCard ?? "",
        route: customer?.route ?? "",
        serialNumber: customer?.serialNumber ?? "",
        tankSize: customer?.tankSize ?? "",
        // location
        locationId: customer?.location?.id,
        locationName: customer?.location?.name ?? "",
        locationFullAddress: customer?.location?.fullAddress ?? "",
        locationLatitude: customer?.location?.latitude ?? "",
        locationLongitude: customer?.location?.longitude ?? "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Card.Body>
        <Row>
          <Col lg={4}>
            <FormTextField label="Name" name="company" required />
          </Col>
          <Col lg={4}>
            <FormTextField label="Member #" name="memberNumber" />
          </Col>
          {isPetroleumBranch && (
            <Col lg={4}>
              <FormTextField label="Ltd" name="ltd" />
            </Col>
          )}
          <Col lg={4}>
            <FormTextField label="Phone" name="phone" />
          </Col>
          {/* </Row> */}

          {/* <Row> */}
          {/* {isPetroleumBranch && ( */}
          <Col lg={4}>
            <FormTextField label="Email" name="email" type="email" />
          </Col>
          {/* )} */}
          <Col lg={4}>
            <FormTextField label="Contact" name="contactName" />
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <FormTextField
              as="textarea"
              className="custom-text-area"
              label="Hazard Assessment/Permanent Instructions"
              name="permanentInstructions"
            />
          </Col>
          <Col lg={6}>
            <FormTextField
              as="textarea"
              className="custom-text-area"
              label="Notes"
              name="notes"
            />
          </Col>
        </Row>

        <Row>
          {isPetroleumBranch && (
            <>
              <Col lg={4}>
                <FormTextField label="Address" name="address1" />
              </Col>
              <Col lg={4}>
                <FormTextField label="Address 2" name="address2" />
              </Col>
              <Col lg={4}>
                <FormTextField label="City" name="city" />
              </Col>
              <Col lg={4}>
                <FormTextField label="Postal Code" name="postalCode" />
              </Col>
            </>
          )}
          {isPropaneBranch && (
            <>
              <Col lg={4}>
                <FormTextField label="Serial #" name="serialNumber" />
              </Col>
              <Col lg={4}>
                <FormTextField label="Tank Size" name="tankSize" />
              </Col>
              <Col lg={4}>
                <FormTextField label="Route" name="route" />
              </Col>
              <Col lg={4}>
                <FormTextField label="Ram Card" name="ramCard" />
              </Col>
            </>
          )}
        </Row>

        <Row>
          <Col lg={4}>
            <FormTextField
              as="textarea"
              className="custom-text-area"
              label="Comment 1"
              maxLength={255}
              name="comment1"
              visible={isPropaneBranch}
            />
          </Col>
          <Col lg={4}>
            <FormTextField
              as="textarea"
              className="custom-text-area"
              label="Comment 2"
              maxLength={255}
              name="comment2"
              visible={isPropaneBranch}
            />
          </Col>
        </Row>

        {/* <Row>
          <Col lg={4}>
          <FormTextField label="Latitude" name="latitude" />
          </Col>
          <Col lg={4}>
          <FormTextField label="Longitude" name="longitude" />
          </Col>
        </Row> */}
      </Card.Body>

      {/* MAIN LOCATION */}
      {!modal && !hideLocationForm && (
        <Row>
          <Col lg={6}>
            <Card className="mt-1">
              <Card.Header>
                <h2 className="card-title">Main Location</h2>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    <FormGoogleAddressField
                      label="Full Address"
                      name="locationFullAddress"
                      onPlaceChange={handleAddressChange}
                      placeholder="Search address..."
                    />
                  </Col>
                  <Col>
                    <FormTextField label="Name" name="locationName" required />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormTextField
                      label="Latitude"
                      name="locationLatitude"
                      // required
                      type="number"
                    />
                  </Col>
                  <Col>
                    <FormTextField
                      label="Longitude"
                      name="locationLongitude"
                      // required
                      type="number"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {(!!modal && (
        <Card.Footer>
          <Row className="mt-3">
            <Col className="text-end">
              <LoadingButton
                className="me-2"
                onClick={onCancel}
                padding={10}
                color={ThemeColorsEnum.quaternary}
              >
                Cancel
              </LoadingButton>
              <SubmitButton loading={busy}>Save</SubmitButton>
            </Col>
          </Row>
        </Card.Footer>
      )) || (
        <div className="form-group mb-0 fixed-to-bottom">
          <Row>
            <Col lg={2} md={4} sm={6}>
              <SubmitButton className="btn-block" loading={busy}>
                Save
              </SubmitButton>
            </Col>
          </Row>
        </div>
      )}
    </AppForm>
  );
};

export default CustomerForm;
