import React from "react";

const CopyrightFooter = () => {
  return (
    <p className="text-center text-muted mt-3 mb-3">
      &copy; Copyright North Star Systems {new Date().getFullYear()}. All Rights
      Reserved.
    </p>
  );
};

export default CopyrightFooter;
