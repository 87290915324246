import Constants from "../constants/constants";
import { StatusEnum } from "../constants/enums";

class SearchFilterRequest {
  constructor() {
    this.draw = null;
    this.start = 0;
    this.length = Constants.defaults.rowsPerPage;
    // this.columns = null;
    this.order = [new Order()]; // Order[]
    this.search = new Search(); // Search[]
    this.status = StatusEnum.active;
  }
}

class Order {
  constructor(column = null, direction = null) {
    this.column = column;
    this.dir = direction;
  }
}

class Search {
  constructor(searchText = null) {
    this.value = searchText;
    this.regex = null;
  }
}

export { Order, Search, SearchFilterRequest };
