import React from "react";

const EmptyDataBox = ({
  text = "There are no records to display.",
  ...otherProps
}) => {
  return (
    <div className={`empty-result-info ${otherProps.className ?? ""}`}>
      <span>{text}</span>
    </div>
  );
};

export default EmptyDataBox;
