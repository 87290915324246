import * as Yup from "yup";

import { Col, Row } from "react-bootstrap";
import { AlertTypes, ThemeColorsEnum } from "../../../constants/enums";

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../../components/cards/Card";
import AppForm from "../../../components/forms/Form";
import FormDatePickerField from "../../../components/forms/FormDatePickerField";
import FormSelectField from "../../../components/forms/FormSelectField";
import FormTextField from "../../../components/forms/FormTextField";
import SubmitButton from "../../../components/forms/SubmitButton";
import { AppRoutes } from "../../../constants/appRoutes";
import { LoadingContext } from "../../../context/contextProvider";
import Services from "../../../services/services";
import Page from "../../Page";

const validationSchema = Yup.object().shape({
  title: Yup.string().min(1).required().label("Title"),
  expiryDate: Yup.date()
    .required()
    .label("Expiry date")
    .typeError("Expiry date is a required field"),
  startDate: Yup.date()
    .required()
    .label("Start date")
    .typeError("Start date is a required field"),
  type: Yup.string().min(1).required().label("Alert type"),
});

const AlertFormPage = ({ alert = {}, breadcrumbs, title, ...otherProps }) => {
  const { loading, setLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setLoading(true);
    const response = await Services.api.alerts.save({ ...alert, ...values });
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the alert",
        ThemeColorsEnum.error,
        response
      );

    navigate(AppRoutes.alerts);
    Services.utility.toast("Saved", ThemeColorsEnum.success);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={{
              title: alert?.title ?? "",
              expiryDate: alert.expiryDate ?? "",
              notes: alert?.notes ?? "",
              startDate: alert.startDate ?? "",
              type: alert?.type ?? "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Row>
              <Col lg={4}>
                <FormTextField label="Title" name="title" required />
              </Col>
              <Col lg={4}>
                <FormSelectField
                  itemLabelProperty="label"
                  items={AlertTypes}
                  itemValueProperty="value"
                  label="Type"
                  name="type"
                  required
                />
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="Notes"
                  name="notes"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <FormDatePickerField
                  label="Start Date"
                  name="startDate"
                  placeholder="Select a date"
                  required
                />
              </Col>
              <Col lg={4}>
                <FormDatePickerField
                  label="Expiry Date"
                  name="expiryDate"
                  placeholder="Select a date"
                  required
                />
              </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block">Save</SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default AlertFormPage;
