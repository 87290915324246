import { useField, useFormikContext } from "formik";

import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import AppTimePicker from "./TimePicker";
import { ElementSize } from "../../constants/enums";
import FormFieldContainer from "./FormFieldContainer";


const FormTimePickerField = ({
  allItems,
  disabled = false,
  icon,
  label,
  LabelComponent,
  // name,
  onBlur = () => {},
  onChange = () => {},
  onIconClick = () => {},
  placeholder,
  required,
  size = ElementSize.md,
  visible = true,
  ...otherProps
}) => {
  const formik = useFormikContext();
  const [field] = useField({ name: otherProps.name, value: otherProps.value });

  const { errors, setFieldValue, touched } = formik;

  if (!visible) return null;

  const handleChange = (dateTime) => {
    setFieldValue(field.name, dateTime);
    onChange(dateTime);
  };

  return (
    <FormFieldContainer>
      <AppFormLabel label={label} required={required} visible={!!label} />
      {LabelComponent}
      <AppTimePicker
        name={field.name}
        onChange={handleChange}
        placeholder={placeholder}
        value={field.value} 
      />
      <div>
        <AppErrorMessage
          error={errors?.[field.name]}
          visible={!!touched?.[field.name] && !!errors?.[field.name]}
        />
      </div>
    </FormFieldContainer>
  );
};

export default FormTimePickerField;
