import { Col, Form, Row, FloatingLabel } from "react-bootstrap";
import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import AppSelect from "./Select";
import Enumerable from "linq";
import { UnitOfMeasurementsEnum } from "../../constants/enums";
import useBranchInfo from "../../hooks/useBranchInfo";
import { AuthContext } from "../../context/contextProvider";
import { RemoveButton } from "../buttons/RemoveButton";
import '../../assets/components/css/dropDown.css';


const WorkOrderProduct = ({
  className,
  completing,
  directDrop = false,
  onBlur = () => { },
  onSelected = () => { },
  products,
  workOrderProduct,
  onProductRemoved,
  ListIndex,
  ...otherProps
}) => {
  // state variables-------------
  const [tankSize, setTankSize] = useState(
    workOrderProduct?.tankSize?.toString() ?? ""
  );
  const [currentPercentage, setCurrentPercentage] = useState(
    workOrderProduct?.currentPercentage?.toString() ?? ""
  );
  const [quantity, setQuantity] = useState(
    workOrderProduct?.quantity?.toString() ?? ""
  );
  const [unitOfMeasurement, setUnitOfMeasurement] = useState(
    workOrderProduct?.unitOfMeasurement
  );
  const [fill, setFill] = useState(workOrderProduct?.fill);
  const [productId, setProductId] = useState(workOrderProduct?.productId);
  const [price, setPrice] = useState(workOrderProduct?.price?.toString() ?? "");
  // state variables-------------

  const { isPetroleumBranch, isPropaneBranch } = useBranchInfo();

  const {
    branchSettings
  } = useContext(AuthContext);


  const enumAfterFilter = [];
  for (const [key, value] of Object.entries(UnitOfMeasurementsEnum)) {
        if (value === workOrderProduct?.unitOfMeasurement) {
            enumAfterFilter.push({ key, value });
    }
  }

  const unitOfMeasurements =
    completing ?
      (branchSettings.alwaysConvertToLiters ? [{key: "liters", value: UnitOfMeasurementsEnum.liters}]
        : enumAfterFilter)
      : Enumerable.from(UnitOfMeasurementsEnum).toArray();

  // const [ unitOfMeasurements, setUnitOfMeasurements ] = useState(completing
  //   ? (Enumerable.from(UnitOfMeasurementsEnum)
  //     .where((x) => x.value === workOrderProduct?.unitOfMeasurement)
  //     .toArray())
  //   : Enumerable.from(UnitOfMeasurementsEnum).toArray());

  const sortedProducts = Enumerable.from(products)
    .orderByDescending((x) => x.order !== null)
    .thenBy((x) => x.name)
    .thenBy((x) => x.order)
    .toArray();


  useEffect(() => {
    if (branchSettings.alwaysConvertToLiters && completing) {
      setUnitOfMeasurement(UnitOfMeasurementsEnum.liters);
      if (workOrderProduct.unitOfMeasurement === "G") {
          //convert to imperial gallon
          setQuantity(workOrderProduct?.quantity * 4.54609);
      }
    }
    else {
      setQuantity(workOrderProduct?.quantity?.toString() ?? "");
      setUnitOfMeasurement(workOrderProduct?.unitOfMeasurement);
    }
  }, [branchSettings.alwaysConvertToLiters, completing]);


  useEffect(
    () => {
      const productData = {
        currentPercentage: currentPercentage,
        price: price,
        productId: productId,
        quantity: quantity,
        tankSize: tankSize,
        unitOfMeasurement: unitOfMeasurement,
        fill: fill,
      };
      onSelected(productData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quantity, unitOfMeasurement, productId, tankSize, currentPercentage, price, fill]
  );

  return (
    // <InputGroup>
    <Row className={className}>
      {!!isPropaneBranch && (
        <Col className="pe-1">
          <FloatingLabel
            controlId="TankSizeFloatingLabel"
            label="Tank Size"
          >
          <Form.Control
            name="tankSize"
            onBlur={onBlur}
            onChange={(event) => setTankSize(event.target?.value)}
            placeholder=""
            type="number"
            value={tankSize}
          />
          </FloatingLabel>
        </Col>
      )}
      {!!isPropaneBranch && (
        <Col className="px-1">
          <FloatingLabel 
            controlId="FloatingLabelPercentage"
            label="%"
          >  
          <Form.Control
            name="currentPercentage"
            onBlur={onBlur}
            onChange={(event) => setCurrentPercentage(event.target?.value)}
            placeholder=""
            type="number"
            value={currentPercentage}
          />
          </FloatingLabel>
        </Col>
      )}
      {!!isPetroleumBranch && (
        <Col className="pe-1">
          <FloatingLabel
            controlId="FloatingQuantitySelect"
            label={directDrop ? "Litres" : "Quantity"}
          >
          <Form.Control
            name="quantity"
            onBlur={onBlur}
            onChange={(event) => {
                setQuantity(event.target?.value)}}
            placeholder=""
            type="number"
            value={quantity}
          />
          </FloatingLabel>
        </Col>
      )}
      {!!isPetroleumBranch && !directDrop && (
        <>
          <Col className="px-1">
          <FloatingLabel
            controlId="floatingProductSelect"
            label="Unit"
        >
            <AppSelect
              className="floatingDropDown"
              itemLabelProperty="value"
              items={unitOfMeasurements}
              itemValueProperty="value"
              name="unitOfMeasurement"
              onBlur={onBlur}
              onChange={(item) => setUnitOfMeasurement(item?.value)}
              placeholder="Unit"
              title="Unit"
              value={unitOfMeasurement}
            />
            </FloatingLabel>
            <Form.Check
              checked={fill}
              className="checkbox-custom"
              label="Fill"
              name="fill"
              onChange={(e) => setFill(!JSON.parse(e.target?.value))}
              type="checkbox"
              value={fill}
            />
          </Col>
      </>
      )}
      <Col className="px-1" styles="custom-dropDown">
        <FloatingLabel
            controlId="floatingProductSelect"
            label="Product"
        >
        <AppSelect
          className="floatingDropDown"
          itemLabelProperty="shortName"
          items={sortedProducts}
          itemValueProperty="id"
          name="productId"
          onBlur={onBlur}
          onChange={(item) => {
            setProductId(item?.id);
          }}
          placeholder=""
          title="Product"
          value={productId}
        />
        </FloatingLabel>
      </Col>
      <Col className="px-1">
        <FloatingLabel
          controlId="floatingPrice"
          label="Price (¢)"
          className="mb-3"
        >
        <Form.Control
          name="price"
         // onBlur={onBlur}
          onChange={(event) => {
                        setPrice(event.target?.value)}}
          placeholder=""
          value={price}
          input type="number"
          step="0.01"
          min="0"
        />
        </FloatingLabel>
      </Col>
      <Col xs="auto">
        <RemoveButton handleClick={() => {
                    onProductRemoved(ListIndex);
                    }
                } ariaLabel={"RemoveProduct"}/>
      </Col>
    </Row>
    // </InputGroup>
  );
};

export default WorkOrderProduct;


WorkOrderProduct.propTypes = { 
    ListIndex: PropTypes.number,
};


const overrideStyles = {
singleValue: (provided, state) => ({
    ...provided,
    minHeight: 150,
    color: "#eeeeee",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    fontSize: "revert",
    opacity: state.isDisabled ? 0.75 : 1,
  }),
};
