import { useField, useFormikContext } from "formik";
import { Form, InputGroup } from "react-bootstrap";

import React from "react";
import { ElementSize } from "../../constants/enums";
import AppErrorMessage from "./ErrorMessage";
import FieldInfo from "./FieldInfo";
import FormFieldContainer from "./FormFieldContainer";
import AppFormLabel from "./FormLabel";

const FormFileInputField = ({
  className,
  disabled = false,
  hasFloatingLabel,
  icon,
  info,
  label = null,
  LabelComponent,
  onFileSelect = () => {},
  onIconClick = () => {},
  placeholder,
  required,
  size = ElementSize.md,
  visible = true,
  ...otherProps
}) => {
  const [field] = useField(otherProps);

  const formik = useFormikContext();
  const { errors, handleBlur, setFieldValue, touched } = formik;

  if (!visible) return null;

  const handleFileSelected = (event) => {
    setFieldValue(field.name, event.target.files?.[0]);
    onFileSelect(event.target.files?.[0], formik);
  };

  const classes = `form-control-${size} ${className ?? ""}`.trim();
  return (
    <FormFieldContainer>
      <AppFormLabel label={label} required={required} visible={!!label} />
      {LabelComponent}
      <InputGroup>
        <Form.Control
          type="file"
          className={classes}
          disabled={disabled}
          name={field.name}
          onBlur={handleBlur}
          onChange={handleFileSelected}
          placeholder={hasFloatingLabel ? placeholder ?? label : placeholder}
        />
        {icon && (
          <span
            className={`input-group-text ${
              onIconClick === null ? "" : "cur-pointer"
            }`}
            onClick={onIconClick}
          >
            <i className={icon}></i>
          </span>
        )}
      </InputGroup>
      <div>
        <AppErrorMessage
          error={errors[field.name]}
          visible={touched[field.name] && errors[field.name]}
        />
      </div>
      <FieldInfo visible={!!info}>{info}</FieldInfo>
    </FormFieldContainer>
  );
};

export default FormFileInputField;
