import { Col, Row } from "react-bootstrap";
import React, { useContext, useState } from "react";

import AppSelect from "../../forms/Select";
import Card from "../../cards/Card";
import LoadingButton from "../../buttons/LoadingButton";
import { ModalContext } from "../../../context/contextProvider";
import { ThemeColorsEnum } from "../../../constants/enums";

const BranchPickerModal = ({
  branches,
  onSave = () => {},
  user,
  ...otherProps
}) => {
  const [selectedBranch, setSelectedBranch] = useState();

  const { closeModal } = useContext(ModalContext);

  const handleSave = () => {
    closeModal();
    onSave(selectedBranch?.id, user);
  };

  return (
    <>
      <Card.Body>
        <AppSelect
          itemLabelProperty="displayName"
          items={branches}
          itemValueProperty="id"
          onChange={setSelectedBranch}
          placeholder="Select a branch"
          searchable
        />
      </Card.Body>
      <Card.Footer>
        <Row className="mt-3">
          <Col className="text-end">
            <LoadingButton
              className="ms-2"
              onClick={() => {
                closeModal();
              }}
              padding={10}
              color={ThemeColorsEnum.quaternary}
            >
              Cancel
            </LoadingButton>
            <LoadingButton
              className="ms-2"
              onClick={handleSave}
              padding={10}
              color={ThemeColorsEnum.primary}
            >
              Save
            </LoadingButton>
          </Col>
        </Row>
      </Card.Footer>
    </>
  );
};

export default BranchPickerModal;
