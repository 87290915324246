import React, { useEffect, useState } from "react";
import { StatusEnum, ThemeColorsEnum } from "../../constants/enums";

import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "react-bootstrap";
import DetailsRow from "../../components/DetailsRow";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import useBranchInfo from "../../hooks/useBranchInfo";
import Services from "../../services/services";

const ServiceOrderDetails = ({ serviceOrder, ...otherProps }) => {
  const [techsGeolocations, setTechsGeolocations] = useState([]);

  const customerLocation = {
    lat: serviceOrder?.customerLatitude,
    lng: serviceOrder?.customerLongitude,
  };

  const { isPropaneBranch } = useBranchInfo();

  useEffect(() => {
    serviceOrder?.assignedToIds?.map((id) => getTechLocationAsync(id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTechLocationAsync = async (id) => {
    const response = await Services.api.users.getLastKnownLocation(id);
    if (response.ok && response.data)
      setTechsGeolocations([...techsGeolocations, response.data]);
    //     setTechsGeolocations({
    //     lat: response.data?.geolocation?.location?.latitude,
    //     lng: response.data?.geolocation?.location?.longitude,
    //   });
  };

  return (
    <div style={{ overflow: "auto" }}>
      <Map
        centerPosition={customerLocation}
        className="map-container-modal mb-3"
        zoom={7}
      >
        <AppMarker
          clickable
          info={
            <span className="info-window-text-title">
              {serviceOrder?.customerDisplayName}
            </span>
          }
          order={serviceOrder}
          position={customerLocation}
        />
        {techsGeolocations?.map((techGeolocation, index) => (
          <AppMarker
            clickable
            icon={`${process.env.PUBLIC_URL}/img/ic_pin_truck.png`}
            info={
              <>
                <span className="info-window-text-title">
                  {techGeolocation.createdByName}
                </span>
                <br />
                <span className="info-window-text">
                  {techGeolocation.createdDateFormatted}
                </span>
              </>
            }
            key={`${techGeolocation.id}_${index}`}
            position={{
              lat: techGeolocation.geolocation?.location?.latitude,
              lng: techGeolocation.geolocation?.location?.longitude,
            }}
          />
        ))}
      </Map>
      <dl className="horizontal">
        <DetailsRow title="Id" details={serviceOrder.id} />
        <DetailsRow
          title="Status"
          details={
            <>
              {serviceOrder.rowClass === ThemeColorsEnum.due ||
              serviceOrder.rowClass === ThemeColorsEnum.overdue ||
              serviceOrder.rowClass === ThemeColorsEnum.completed ? (
                <FontAwesomeIcon
                  className={`align-middle text-${serviceOrder.rowClass}`}
                  icon={faCircle}
                  size="2x"
                />
              ) : null}
              <Badge
                bg={null}
                className={`align-middle ${Services.workOrders.getBadgeClassByOrderStatus(
                  serviceOrder.orderStatusId
                )} ms-3`}
              >
                {serviceOrder.orderStatus}
              </Badge>
            </>
          }
        />
        <DetailsRow
          title="Due on"
          details={Services.utility.formatFullDate(serviceOrder?.dueDate)}
          visible={serviceOrder?.status === StatusEnum.active}
        />
        <DetailsRow
          title="Completed on"
          details={`${Services.utility.formatFullDateAndTime(
            serviceOrder?.completedDate
          )} (${serviceOrder?.completedByName})`}
          visible={!!serviceOrder?.completedDate}
        />
        <DetailsRow title="Category" details={serviceOrder?.categoryName} />
        <DetailsRow
          title="Title"
          details={serviceOrder?.title}
          visible={!!serviceOrder?.title}
        />
        <DetailsRow
          title="Technician(s)"
          details={
            serviceOrder?.assignedToNames?.length > 0 ? (
              serviceOrder?.assignedToNames?.map((name, index) => (
                <div key={name + index}>{name}</div>
              ))
            ) : (
              <em className="text-muted">Unassigned</em>
            )
          }
        />
        <DetailsRow
          title="Customer"
          details={serviceOrder?.customerDisplayName}
        />
        <DetailsRow
          title="Customer Location"
          details={serviceOrder?.customerLocationName}
        />
        <DetailsRow
          title="Member #"
          details={serviceOrder?.customerMemberNumber}
          visible={!!serviceOrder?.customerMemberNumber}
        />
        <DetailsRow
          title="Contact"
          details={serviceOrder?.customerContactName}
          visible={!!serviceOrder?.customerContactName}
        />
        <DetailsRow
          title="Phone"
          details={serviceOrder?.customerPhoneNumber}
          visible={!!serviceOrder?.customerPhoneNumber}
        />
        <DetailsRow
          title="Serial #"
          details={serviceOrder?.customerSerialNumber}
          visible={!!serviceOrder?.customerSerialNumber}
        />
        {/* Propane only */}
        <DetailsRow
          title="Tank Size"
          details={serviceOrder?.customerTankSize}
          visible={!!serviceOrder?.customerTankSize && isPropaneBranch}
        />
        <DetailsRow
          title="Route"
          details={serviceOrder?.customerRoute}
          visible={!!serviceOrder?.customerRoute && isPropaneBranch}
        />
        <DetailsRow
          title="Ram Card"
          details={serviceOrder?.customerRamCard}
          visible={!!serviceOrder?.customerRamCard && isPropaneBranch}
        />
        <DetailsRow
          title="Comment 1"
          details={serviceOrder?.customerComment1}
          visible={!!serviceOrder?.customerComment1 && isPropaneBranch}
        />
        <DetailsRow
          title="Comment 2"
          details={serviceOrder?.customerComment2}
          visible={!!serviceOrder?.customerComment2 && isPropaneBranch}
        />
        {/* End propane only */}
      </dl>
    </div>
  );
};

export default ServiceOrderDetails;
