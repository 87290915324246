const AlertColumnsEnum = {
  actionButton: 0,
  title: 1,
  notes: 2,
  type: 3,
  createdDate: 4,
  startDate: 5,
  expiryDate: 6,
  status: 7,
};

const AlertTypes = [
  { label: "Default", value: "dark" },
  { label: "Error", value: "danger" },
  { label: "Info", value: "info" },
  { label: "Success", value: "success" },
  { label: "Warning", value: "warning" },
];

const BillableUserColumnsEnum = {
  userId: 0,
  userName: 1,
  fullName: 2,
  clientId: 3,
  branchName: 4,
  addedOn: 5,
  fee: 6,
  expiresOn: 7,
};

const BranchColumnsEnum = {
  actionButton: 0,
  companyName: 1,
  branchName: 2,
  city: 3,
  service: 4,
  phone: 5,
  email: 6,
  provinceState: 7,
  country: 8,
  expirationDate: 9,
  demo: 10,
  usersCount: 11,
  createdDate: 12,
};

const BranchUserColumnsEnum = {
  userName: 0,
  fullName: 1,
  roles: 2,
  createdDate: 3,
  deletedDate: 4,
};

const ClientBranchColumnsEnum = {
  companyName: 0,
  branchName: 1,
  users: 2,
  newUsers: 3,
  deletedUsers: 4,
  total: 5,
};

const ClientTypesEnum = {
  petroleum: 1,
  propane: 2,
  service: 3,
};

const CustomerColumnsEnum = {
  actionButton: 0,
  company: 1,
  contact: 2,
  memberNumber: 3,
  phone: 4,
  email: 5,
  city: 6,
  serialNumber: 7,
  tankSize: 8,
  route: 9,
  ramCard: 10,
  comment1: 11,
  comment2: 12,
};

const CustomerLocationColumnsEnum = {
  actionButton: 0,
  name: 1,
  fullAddress: 2,
  coordinates: 3,
};

const CustomRequestHeadersEnum = {
  apiVersion: "X-Api-Version",
  appVersion: "X-App-Version",
  coordinates: "X-Coordinates",
  device: "X-Device",
  deviceId: "X-DeviceId",
  deviceInfo: "X-DeviceInfo",
  oneSignalPlayerId: "X-OneSignal-PlayerId",
  timeZoneOffset: "X-TimeZone-Offset",
};

const DashboardTypesEnum = {
  myWorkOrders: 0,
  unassigned: 1,
  due: 2,
  overdue: 3,
  completed: 4,
  myServiceOrders: 5,
  unassignedServiceOrders: 6,
  dueServiceOrders: 7,
  overdueServiceOrders: 8,
  completedServiceOrders: 9,
};

const ElementSize = {
  xxl: "xxl",
  xl: "xl",
  lg: "lg",
  md: "md",
  sm: "sm",
  xs: "xs",
};

const EnrolledClientColumnsEnum = {
  companyName: 0,
  city: 1,
  phone: 2,
  email: 3,
  provinceState: 4,
  country: 5,
};

const NodePositions = {
  Prepend: "prepend",
  Append: "append",
};

const OrderStatusIdEnum = {
  new: 1,
  inProgress: 2,
  completed: 3,
  pending: 4,
  template: 5,
};

const ProductColumnsEnum = {
  actionButton: 0,
  name: 1,
  shortName: 2,
  notes: 3,
  order: 4,
  numberOfWorkOrders: 5,
};

const RolesEnum = {
  superAdmin: "SuperAdmin",
  admin: "Manager",
  driver: "Driver",
  thirdPartyService: "Third Party Service",
  serviceTech: "Service Tech",
  workAlone: "Work Alone",
  viewOnly: "Read-only",
};

const ScheduledCheckInColumnsEnum = {
  actionButton: 0,
  enteredOn: 1,
  enteredBy: 2,
  completedOn: 3,
  geolocation: 4,
};

const ScheduledOrderColumnsEnum = {
  actionButton: 0,
  customer: 1,
  customerLocation: 2,
  notes: 3,
  products: 4,
  assignedTo: 5,
  location: 6,
  dueOn: 7,
  createOn: 8,
  interval: 9,
  startDate: 10,
  orderType: 11,
};

const ServiceCategoryColumnsEnum = {
  actionButton: 0,
  name: 1,
  shortName: 2,
  notes: 3,
  serviceOrdersCount: 4,
};

const ServiceOrderColumnsEnum = {
  actionButton: 0,
  orderNumber: 1,
  title: 2,
  customer: 3,
  notes: 4,
  category: 5,
  assignedTo: 6,
  status: 7,
  dueDate: 8,
  completedOn: 9,
  completedBy: 10,
  enteredOn: 11,
  isHighPriority: 12,
  notesHidden: 13,
};

const SortDirections = {
  ascending: "asc",
  descending: "desc",
};

const StatusEnum = {
  active: "A",
  completed: "C",
  deleted: "D",
  pending: "P",
};

const TattleColumnsEnum = {
  actionButton: 0,
  id: 1,
  name: 2,
  customer: 3,
  product: 4,
  enteredOn: 5,
  enteredBy: 6,
  notes: 7,
  client: 8,
};

const ThemeColorsEnum = {
  completed: "completed",
  danger: "danger",
  dark: "dark",
  default: "default",
  due: "due",
  error: "error",
  externalUser: "external-user",
  info: "info",
  overdue: "overdue",
  primary: "primary",
  quaternary: "quaternary",
  quinary: "quinary",
  secondary: "secondary",
  success: "success",
  tertiary: "tertiary",
  unassigned: "unassigned",
  warning: "warning",
  highPrio: "high-prio",
};

const TripStatusIdEnum = {
  new: 1,
  inProgress: 2,
  completed: 3,
};

const TruckColumnsEnum = {
  actionButton: 0,
  name: 1,
  products: 2,
  year: 3,
  make: 4,
  model: 5,
  notes: 6,
};

const TripColumnsEnum = {
  actionButton: 0,
  users: 1,
  purpose: 2,
  status: 3,
  eta: 4,
  completedOn: 5,
  completedBy: 6,
  enteredOn: 7,
  enteredBy: 8,
};

const UnitOfMeasurementsEnum = {
  liters: "L",
  gallons: "G",
  yards: "Yards",
};

const UserColumnsEnum = {
  actionButton: 0,
  userName: 1,
  fullName: 2,
  email: 3,
  phone: 4,
  roles: 5,
  primaryClient: 6,
  device: 7,
  createdDate: 8,
  deletedDate: 9,
};

const WorkOrderColumnsEnum = {
  actionButton: 0,
  customer: 1,
  notes: 2,
  products: 3,
  enteredOn: 4,
  assignedTo: 5,
  status: 6,
  dueDate: 7,
  completedOn: 8,
  completedBy: 9,
  location: 10,
  tattleNotes: 11,
  truck: 12,
  isHighPriority: 13,
  confirmation: 14,
};

const WorkOrderTypesEnum = {
  workOrder: 0,
  directDrop: 1,
  serviceOrder: 2,
};

export {
  AlertColumnsEnum,
  AlertTypes,
  BillableUserColumnsEnum,
  BranchColumnsEnum,
  BranchUserColumnsEnum,
  ClientBranchColumnsEnum,
  ClientTypesEnum,
  CustomRequestHeadersEnum,
  CustomerColumnsEnum,
  CustomerLocationColumnsEnum,
  DashboardTypesEnum,
  ElementSize,
  EnrolledClientColumnsEnum,
  NodePositions,
  OrderStatusIdEnum,
  ProductColumnsEnum,
  RolesEnum,
  ScheduledCheckInColumnsEnum,
  ScheduledOrderColumnsEnum,
  ServiceCategoryColumnsEnum,
  ServiceOrderColumnsEnum,
  SortDirections,
  StatusEnum,
  TattleColumnsEnum,
  ThemeColorsEnum,
  TripColumnsEnum,
  TripStatusIdEnum,
  TruckColumnsEnum,
  UnitOfMeasurementsEnum,
  UserColumnsEnum,
  WorkOrderColumnsEnum,
  WorkOrderTypesEnum,
};
