import { Navigate, useLocation, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";

import { AppRoutes } from "../../constants/appRoutes";
import CustomerFormPage from "./CustomerFormPage";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import { LoadingContext } from "../../context/contextProvider";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: HolidayVillageIcon,
    title: "Customers",
    to: AppRoutes.customers,
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const CustomerEditPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();
  const userInfo = useCurrentUserInfo();

  const [customer, setCustomer] = useState(state?.customer);
  const [hideLocationForm, setHideLocationForm] = useState(true);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (id && !state?.customer) getCustomerAsync(id);
  }, [id, state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (customer?.id) getCustomerLocationAsync(customer.id);
  }, [customer?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerAsync = async (id) => {
    setLoading(true);
    let response = await Services.api.customers.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the customer",
        ThemeColorsEnum.error
      );

    setCustomer(response.data);
  };

  const getCustomerLocationAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.customers.getLocations(id);
    setLoading(false);

    if (response?.data?.length === 1)
      setCustomer({ ...customer, location: response.data[0] });

    setHideLocationForm(response?.data?.length > 1);
  };

  if (!userInfo?.canEditCustomers)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <CustomerFormPage
      breadcrumbs={breadcrumbs}
      customer={customer}
      hideLocationForm={hideLocationForm}
      title="Customers - Edit"
    />
  );
};

export default CustomerEditPage;
