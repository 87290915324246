import React, { useContext } from "react";

import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext } from "../../context/contextProvider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Navigate, useLocation } from "react-router-dom";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import WorkOrdersPage from "../workOrders/WorkOrdersPage";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: OilBarrelIcon,
    title: "Direct Drops",
    to: AppRoutes.directDrops,
  },
  {
    IconComponent: PlaylistAddCheckIcon,
    title: "Completed",
  },
];

const DirectDropsCompletedPage = (props) => {
  const { state } = useLocation();
  const { branchModules } = useContext(AuthContext);

  if (!branchModules?.directDrops)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <WorkOrdersPage
      completed
      breadcrumbs={breadcrumbs}
      directDrops
      dashboardType={state?.dashboardType}
      title="Direct Drops - Completed"
    />
  );
};

export default DirectDropsCompletedPage;
