import { useEffect, useState } from "react";

const useCurrentPosition = () => {
  const [positionLoaded, setPositionLoaded] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    lat: 50.4452,
    lng: -104.6189,
  });

  useEffect(() => {
    getCurrentPosition();
  }, []);

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentPosition({
          lat: position?.coords?.latitude,
          lng: position?.coords?.longitude,
        });
        setPositionLoaded(true);
      },
      (error) => {
        console.error(error);
        setPositionLoaded(true);
      }
    );
  };

  return { position: currentPosition, positionLoaded };
};

export default useCurrentPosition;
