import { Document, Page as PdfPage, pdfjs } from "react-pdf";
import React, { useContext, useEffect, useState } from "react";

import { AppRoutes } from "../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HelpIcon from "@mui/icons-material/Help";
import { LoadingContext } from "../context/contextProvider";
import Page from "./Page";
import UserGuideMobile from "../assets/pdf/Live Dispatch Mobile User Guide.pdf";
import UserGuideWeb from "../assets/pdf/Live Dispatch Web App User Guides.pdf";
import { useLocation } from "react-router-dom";

const title = "User Guides";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: HelpIcon,
    title: title,
  },
];

const UserGuidesPage = (props) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const { setLoading } = useContext(LoadingContext);
  const {
    state: { platform },
  } = useLocation();

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Document
        file={UserGuideWeb}
        // options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </Page>
  );
};

export default UserGuidesPage;
