import * as Yup from "yup";

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  CustomerColumnsEnum,
  OrderStatusIdEnum,
  RolesEnum,
  SortDirections,
  ThemeColorsEnum,
  WorkOrderTypesEnum,
} from "../../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../../context/contextProvider";
import {
  Search,
  SearchFilterRequest,
} from "../../../models/searchFilterRequest";

import dayjs from "dayjs";
import { debounce } from "lodash";
import { useMemo } from "react";
import Services from "../../../services/services";
import LoadingButton from "../../buttons/LoadingButton";
import Card from "../../cards/Card";
import AppForm from "../Form";
import FormCustomerLocationSelect from "../FormCustomerLocationSelect";
import FormCustomerSelect from "../FormCustomerSelect";
import FormDatePickerField from "../FormDatePickerField";
import FormSelectField from "../FormSelectField";
import SubmitButton from "../SubmitButton";

const validationSchema = Yup.object().shape({
  completedOnFrom: Yup.date()
    .required()
    .label("Completed from")
    .typeError("Completed from date is a required field"),
  completedOnTo: Yup.date()
    .required()
    .label("Completed to")
    .typeError("Completed to date is a required field"),
});

const DispatchReportFilterForm = ({
  filter,
  onCancel = () => {},
  onSave = () => {},
  ...otherProps
}) => {
  const [customers, setCustomers] = useState([]);
  const [customerPredictions, setCustomerPredictions] = useState([]);
  const [customerLocations, setCustomerLocations] = useState([]);
  const [products, setProducts] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [filterState, setFilterState] = useState(filter);

  const { branchModules, currentClient } = useContext(AuthContext);
  const { closeModal } = useContext(ModalContext);
  const { busy, setBusy, setLoading } = useContext(LoadingContext);

  const workOrderTypes = [
    ...(!!branchModules?.workOrders
      ? [{ label: "Delivery Orders", value: WorkOrderTypesEnum.workOrder }]
      : []),
    ...(!!branchModules?.directDrops
      ? [{ label: "Direct Drops", value: WorkOrderTypesEnum.directDrop }]
      : []),
  ];

  useEffect(() => {
    initAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filter?.customerId > 0) {
      setCustomers([
        {
          id: filter.customerId,
          company: filter.customerName,
        },
      ]);
      getCustomerLocationsAsync(filter.customerId);
    }
  }, [filter]);

  const getCustomerLocationsAsync = async (customerId) => {
    const response = await Services.api.customers.getLocations(customerId);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the locations",
        ThemeColorsEnum.error
      );

    setCustomerLocations(response.data);

    return response.data;
  };

  const getProductsAsync = async () => {
    const response = await Services.api.products.all();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the products",
        ThemeColorsEnum.error
      );

    setProducts(response.data);
  };

  const getUsersAsync = async () => {
    const response = await Services.api.users.getByRole(RolesEnum.driver);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the drivers",
        ThemeColorsEnum.error
      );

    const finalData = Services.utility.getDataForUserPicker(response.data);
    setDrivers(finalData);
  };

  const handleCustomerInputChange = async (text) => {
    if (!text) return setCustomerPredictions(customers);
    text = text.toLowerCase();

    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(text);
    filter.start = 0;
    filter.length = 20;
    filter.order[0].column = CustomerColumnsEnum.company;
    filter.order[0].dir = SortDirections.ascending;

    // setLoading(true);
    const response = await Services.api.customers.getCustomers(filter);
    // setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not get the customers",
        ThemeColorsEnum.error
      );

    setCustomerPredictions(response.data?.data);
    // const matches = Services.utility.getCustomerPredictions(customers, text);
    // setCustomerPredictions(matches);
  };

  const handleCustomerInputChangeWithDebounce = useMemo(
    () =>
      debounce((text) => {
        handleCustomerInputChange(text?.trim());
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleCustomerSelect = async (customer, { setFieldValue }) => {
    if (!customer) {
      setCustomerLocations([]);
      setFieldValue("customerLocationId", null);
      // handleSelectChange("customerLocationName", "");
    } else {
      setFieldValue("customerLocationId", null);
      // handleSelectChange("customerLocationName", "");
      const locations = await getCustomerLocationsAsync(customer.id);
      if (locations?.length === 1) {
        setFieldValue("customerLocationId", locations[0].id);
        handleSelectChange("customerLocationName", locations[0].name);
      }
    }
    handleSelectChange("customerName", customer?.company);
  };

  const handleSelectChange = async (fieldName, text) => {
    const newFilter = { ...filterState, [fieldName]: text };
    setFilterState(newFilter);
  };

  const handleSubmit = async (values) => {
    onSave({
      ...filterState,
      // ...values,
      assignedToUserId: values.assignedToUserId,
      completedOnFrom: values.completedOnFrom,
      completedOnTo: values.completedOnTo,
      completedByUserId: values.completedByUserId,
      customerId: values.customerId,
      customerLocationId: values?.customerLocationId,
      orderStatusId: values.orderStatusId,
      productId: values.productId,
    });
    closeModal();
  };

  const initAsync = async () => {
    setLoading(true);
    setCustomerPredictions([]);
    setCustomerLocations([]);
    await getProductsAsync();
    await getUsersAsync();
    setLoading(false);
  };

  return (
    <AppForm
      initialValues={{
        assignedToUserId: filterState?.assignedToUserId ?? null,
        completedOnFrom:
          filterState?.completedOnFrom ?? dayjs().startOf("year"),
        completedOnTo: filterState?.completedOnTo ?? dayjs(),
        completedByUserId: filterState?.completedByUserId ?? null,
        customerId: filterState?.customerId ?? null,
        customerLocationId: filterState?.customerLocationId ?? "",
        // customerLocationName: filterState?.customerLocationName ?? "",
        orderStatusId:
          filterState?.orderStatusId ?? OrderStatusIdEnum.completed,
        productId: filterState?.productId ?? null,
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Card>
        <Card.Body>
          <Row>
            <Col lg={4}>
              <FormCustomerSelect
                allItems={customers}
                autocomplete
                hidePlusButton
                itemLabelProperty="company"
                items={customerPredictions}
                itemValueProperty="id"
                label="Customer"
                name="customerId"
                onChange={handleCustomerSelect}
                onInputChange={handleCustomerInputChangeWithDebounce}
                placeholder="Search by anything..."
                searchable
              />
            </Col>
            <Col lg={4}>
              <FormCustomerLocationSelect
                hidePlusButton
                itemLabelProperty="name"
                items={customerLocations}
                itemValueProperty="id"
                label="Customer Location"
                name="customerLocationId"
                onChange={(location) =>
                  handleSelectChange(
                    "customerLocationName",
                    location?.name ?? null
                  )
                }
                searchable
              />
            </Col>
            <Col lg={4}>
              <FormSelectField
                itemLabelProperty="shortName"
                items={products}
                itemValueProperty="id"
                label="Product"
                name="productId"
                onChange={(item) =>
                  handleSelectChange("productName", item?.shortName)
                }
                searchable
              />
            </Col>
            <Col lg={4}>
              <FormSelectField
                itemLabelProperty="label"
                items={drivers}
                itemValueProperty="value"
                label="Assigned to"
                name="assignedToUserId"
                onChange={(item) =>
                  handleSelectChange("assignedToUserName", item?.label)
                }
                searchable
              />
            </Col>
            <Col lg={4}>
              <FormSelectField
                itemLabelProperty="label"
                items={drivers}
                itemValueProperty="value"
                label="Completed by"
                name="completedByUserId"
                onChange={(item) =>
                  handleSelectChange("completedByUserName", item?.label)
                }
                searchable
              />
            </Col>
            <Col lg={4}>
              <FormSelectField
                itemLabelProperty="label"
                items={workOrderTypes}
                itemValueProperty="value"
                label="Order Type"
                name="workOrderType"
                onChange={(item) =>
                  handleSelectChange("workOrderTypeName", item?.label)
                }
                searchable
              />
            </Col>
            <Col lg={4}>
              <FormDatePickerField
                label="Completed - From"
                name="completedOnFrom"
                placeholder="Select a date"
                required
              />
            </Col>
            <Col lg={4}>
              <FormDatePickerField
                label="Completed - To"
                name="completedOnTo"
                placeholder="Select a date"
                required
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Row className="mt-3">
            <Col className="text-end">
              <LoadingButton
                className="me-2"
                onClick={onCancel}
                padding={10}
                color={ThemeColorsEnum.quaternary}
              >
                Cancel
              </LoadingButton>
              <SubmitButton loading={busy}>Apply Filter</SubmitButton>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </AppForm>
  );
};

export default DispatchReportFilterForm;
