import * as Yup from "yup";

import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import { useNavigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import AppForm from "../../components/forms/Form";
import FormTextField from "../../components/forms/FormTextField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import { LoadingContext } from "../../context/contextProvider";
import Services from "../../services/services";
import Page from "../Page";

const validationSchema = Yup.object().shape({
  name: Yup.string().min(1).max(50).required().label("Name"),
  shortName: Yup.string().min(1).max(10).required().label("Abbreviation"),
});

const ProductFormPage = ({
  breadcrumbs,
  product = {},
  title,
  ...otherProps
}) => {
  const { busy, setBusy } = useContext(LoadingContext);

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setBusy(true);
    const response = await Services.api.products.save({
      ...product,
      ...values,
    });
    setBusy(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the product",
        ThemeColorsEnum.error,
        response
      );

    navigate(AppRoutes.products);
    Services.utility.toast("Saved", ThemeColorsEnum.success);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={{
              name: product?.name ?? "",
              notes: product?.notes ?? "",
              order: product?.order ?? "",
              shortName: product?.shortName ?? "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) =>
              handleSubmit(values, resetForm)
            }
          >
            <Row>
              <Col lg={4}>
                <FormTextField label="Name" name="name" required />
              </Col>
              <Col lg={4}>
                <FormTextField label="Abbreviation" name="shortName" required />
              </Col>
              <Col lg={4}>
                <FormTextField label="Order" name="order" type="number" />
              </Col>
              <Col lg={8}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="Notes"
                  name="notes"
                />
              </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" loading={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default ProductFormPage;
