import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  ProductColumnsEnum,
  SortDirections,
  ThemeColorsEnum,
} from "../../constants/enums";
import { AuthContext, LoadingContext } from "../../context/contextProvider";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import DashboardIcon from "@mui/icons-material/Dashboard";
import PropaneIcon from "@mui/icons-material/Propane";
import AddButton from "../../components/buttons/AddButton";
import Card from "../../components/cards/Card";
import EmptyDataBox from "../../components/dataTables/EmptyDataBox";
import NSDataTable from "../../components/dataTables/NSDataTable";
import NSDataTablePagination from "../../components/dataTables/NSDataTablePagination";
import { AppRoutes } from "../../constants/appRoutes";
import Constants from "../../constants/constants";
import useProductsDataTable from "../../hooks/useProductsDataTable";
import Services from "../../services/services";
import Page from "../Page";

const title = "Products";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PropaneIcon,
    title: "Products",
  },
];

const ProductsPage = (props) => {
  const { setLoading } = useContext(LoadingContext);
  const { currentClient, cache, setCache } = useContext(AuthContext);

  // data-tables - start
  const [currentPage, setCurrentPage] = useState(
    cache?.products?.currentPage ?? 1
  );
  const [pageSize, setPageSize] = useState(
    cache?.products?.pageSize ?? Constants.defaults.rowsPerPage
  );
  const [paginationData, setPaginationData] = useState({
    from: null,
    pages: null,
    records: null,
    to: null,
  });
  const [searchValue, setSearchValue] = useState(cache?.products?.search ?? "");
  const [sortOptions, setSortOptions] = useState({
    column: cache?.products?.sortColumn ?? ProductColumnsEnum.company,
    direction: cache?.products?.sortDirection ?? SortDirections.ascending,
  });
  // data-tables - end

  const [products, setProducts] = useState([]);

  const dataTableInfo = useProductsDataTable(handleDeleted);

  useEffect(() => {
    setCache({
      ...cache,
      products: {
        currentPage: currentPage,
        pageSize: pageSize,
        search: searchValue,
        sortColumn: sortOptions.column,
        sortDirection: sortOptions.direction,
      },
    });
    getProductsAsync((currentPage - 1) * pageSize, pageSize, searchValue);
    return () => {};
  }, [currentClient, currentPage, pageSize, searchValue, sortOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  const getProductsAsync = async (
    start = 0,
    take = Constants.defaults.rowsPerPage,
    searchText = null
  ) => {
    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(searchText);
    filter.start = start;
    filter.length = take;
    filter.order[0].column = sortOptions.column ?? ProductColumnsEnum.name;
    filter.order[0].dir = sortOptions.direction ?? SortDirections.ascending;

    setLoading(true);
    const response = await Services.api.products.getProducts(filter);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the products",
        ThemeColorsEnum.error
      );

    setProducts(response.data?.data);
    setPaginationData({
      from: (currentPage - 1) * pageSize + 1,
      pages: response.data?.pagesTotal,
      records: response.data?.recordsTotal,
      to:
        currentPage * pageSize > response.data?.recordsTotal
          ? response.data?.recordsTotal
          : currentPage * pageSize,
    });
  };

  function handleDeleted(product) {
    setProducts(products.filter((x) => x.id !== product.id));
  }

  const handlePageChange = async (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (newPerPage, currentPage) => {
    setCurrentPage(1);
    setPageSize(newPerPage);
  };

  const handleSearch = (searchValue) => {
    setSearchValue(searchValue);
  };

  const handleSort = (column, direction) => {
    setCurrentPage(1);
    setSortOptions({ column: column, direction: direction });
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
                {/* **** DATA TABLE **** */}
                <NSDataTable
                  buttons={<AddButton to={AppRoutes.products_create} />}
                  className="dataTable table-no-more"
                  // dense
                  columns={dataTableInfo.columns}
                  //   conditionalRowStyles={conditionalRowStyles}
                  data={products}
                  emptyDataComponent={<EmptyDataBox />}
                  onSearch={handleSearch}
                  onSort={handleSort}
                  paginate
                  search
                  searchValue={searchValue}
                  sortBy={sortOptions.column}
                  sortDirection={sortOptions.direction}
                />
                {/* **** BOOTSTRAP PAGINATION **** */}
                <NSDataTablePagination
                  from={paginationData?.from}
                  lastPage={paginationData?.pages}
                  page={currentPage}
                  pageSize={pageSize}
                  showRecordsInfo
                  total={paginationData?.records}
                  to={paginationData?.to}
                  onPageChange={handlePageChange}
                  onPageSizeChange={(newPerPage) =>
                    handleRowsPerPageChange(newPerPage, currentPage)
                  }
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default ProductsPage;
