import React from "react";
import { ThemeColorsEnum } from "../../constants/enums";

const LoadingButton = ({
  children: title,
  color = ThemeColorsEnum.primary,
  disabled,
  icon = "fa fa-spinner fa-spin me-2",
  loading,
  onClick,
  type = "button",
  ...otherProps
}) => {
  disabled = disabled ?? loading;

  return (
    <button
      type={type}
      className={`btn btn-${color} ${otherProps.className ?? ""}`} // mt-2 mb-2
      onClick={onClick}
      disabled={disabled ? "disabled" : null}
    >
      {loading && <i className={icon} />} {title}
    </button>
  );
};

export default LoadingButton;
