import * as Yup from "yup";

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  CustomerColumnsEnum,
  OrderStatusIdEnum,
  RolesEnum,
  SortDirections,
  StatusEnum,
  ThemeColorsEnum,
  UnitOfMeasurementsEnum,
} from "../../constants/enums";
import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../context/contextProvider";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import Enumerable from "linq";
import { debounce } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "../../components/cards/Card";
import AppForm from "../../components/forms/Form";
import FormCheckboxField from "../../components/forms/FormCheckboxField";
import FormCustomerLocationSelect from "../../components/forms/FormCustomerLocationSelect";
import FormCustomerSelect from "../../components/forms/FormCustomerSelect";
import FormDatePickerField from "../../components/forms/FormDatePickerField";
import FormSelectField from "../../components/forms/FormSelectField";
import FormTextField from "../../components/forms/FormTextField";
import FormWorkOrderProducts from "../../components/forms/FormWorkOrderProducts";
import SubmitButton from "../../components/forms/SubmitButton";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import { AppRoutes } from "../../constants/appRoutes";
import Schemas from "../../constants/schemas";
import useBranchInfo from "../../hooks/useBranchInfo";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import Page from "../Page";
import { useEffectAsync } from '../../hooks/useEffectAsync';

const getCustomerLocationsAsync = async (customerId) => {
  const response = await Services.api.customers.getLocations(customerId);

  if (!response.ok)
    return Services.utility.toast(
      "Could not download the locations",
      ThemeColorsEnum.error
    );

  //setCustomerLocations(response.data);

  return response.data;
};

const getPaymentMethodsAsync = async () => {
  const response = await Services.api.workOrders.getPaymentMethods();

  if (!response.ok)
    return Services.utility.toast(
      "Could not download the payment methods",
      ThemeColorsEnum.error
    );

  //setPaymentMethods(response.data);
  return response.data;
};

const getProductsAsync = async () => {
  const response = await Services.api.products.all();

  if (!response.ok)
    return Services.utility.toast(
      "Could not download the products",
      ThemeColorsEnum.error
    );

  //setProducts(response.data);
  return response.data;
};

const getTrucksAsync = async () => {
  const response = await Services.api.trucks.all();

  if (!response.ok)
    return Services.utility.toast(
      "Could not download the trucks",
      ThemeColorsEnum.error
    );

  //setTrucks(response.data);
  return response.data;
};

const getUsersAsync = async () => {
  const response = await Services.api.users.getByRole(RolesEnum.driver);

  if (!response.ok)
    return Services.utility.toast(
      "Could not download the drivers",
      ThemeColorsEnum.error
    );

  const finalData = Services.utility.getDataForUserPicker(response.data);
  //setDrivers(finalData);
  return finalData;
};

const WorkOrderFormPage = ({
  breadcrumbs,
  directDrop,
  title,
  workOrder = {},
  ...otherProps
}) => {
  const [customers, setCustomers] = useState([]);
  const [customerPredictions, setCustomerPredictions] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [products, setProducts] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [customerLocations, setCustomerLocations] = useState([]);
  let location = useLocation();

  const [hideNewLocationButton, setHideNewLocationButton] = useState(
    !workOrder?.customerId
  );
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: workOrder?.customerLatitude,
    longitude: workOrder?.customerLongitude,
    //name: workOrder?.customerLocationName,
  });
  const { busy, setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);
  const { branchSettings, currentClient } = useContext(AuthContext);

  const { isPropaneBranch, isPetroleumBranch } = useBranchInfo();
  const userInfo = useCurrentUserInfo();

  const navigate = useNavigate();
  const baseValidationSchema = useMemo(
    () =>
      isPropaneBranch ? Yup.object().shape(Schemas.propaneOrderShape)
        : isPetroleumBranch ? Yup.object().shape(Schemas.petroleumOrderShape)
          : Yup.object().shape(Schemas.servicesOrOtherOrderShape),
    [isPropaneBranch, isPetroleumBranch],
  );

  const [validationSchema, setValidationSchema] =
    useState(baseValidationSchema);

  useEffectAsync(async () => {
    await initAsync();
  }, []);

  useEffect(() => {
    initAsync();
  }, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffectAsync(async () => {
    if (workOrder.id > 0) {
      selectedLocation.latitude = workOrder?.customerLatitude;
      selectedLocation.longitude = workOrder?.customerLongitude;
      //selectedLocation.name = workOrder?.customerLocationName;
      initialValues.customerLocationId = workOrder?.customerLocationId;
      initialValues.locationName = workOrder?.customerLocationName;
    }
    //if (workOrder?.customerId > 0) {
    //  setCustomers([
    //    { id: workOrder.customerId, company: workOrder.customerDisplayName },
    //  ]);
    //  setHideNewLocationButton(false);
    //  //getCustomerLocationsAsync(workOrder.customerId);
    //}
  }, [workOrder]);

  //useEffectAsync(async () => {
  //  if (workOrder?.customerId) {
  //    await initAsync();
  //  }
  //}, [workOrder]);

  useEffect(() => {
    if (customerLocations?.length > 0) { //CL from workorder
      setValidationSchema(
        baseValidationSchema.concat(
          Yup.object({
            customerLocationId: Yup.number()
              .required()
              .label("Customer location")
              .typeError("Customer location is a required field"),
          })
        )
      );
    } //else setValidationSchema(baseValidationSchema);
  }, [customerLocations, baseValidationSchema]);

  const handleCustomerInputChange = async (text) => {
    if (!text) return setCustomerPredictions(customers);
    text = text.toLowerCase();

    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(text);
    filter.start = 0;
    filter.length = 20;
    filter.order[0].column = CustomerColumnsEnum.company;
    filter.order[0].dir = SortDirections.ascending;

    // setLoading(true);
    const response = await Services.api.customers.getCustomers(filter);
    // setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not get the customers",
        ThemeColorsEnum.error
      );

    setCustomerPredictions(response.data?.data);
    // const matches = Services.utility.getCustomerPredictions(customers, text);
    // setCustomerPredictions(matches);
  };

  const handleCustomerInputChangeWithDebounce = useMemo(
    () =>
      debounce((text) => {
        handleCustomerInputChange(text?.trim());
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );


  const handleSelectCustomerLocation = async (customer_location, { setFieldValue }) => {
    if (customer_location) {
      setFieldValue("customerLocationId", customer_location.id);
      setSelectedLocation(customer_location);
    }
  };

  const handleSelectCustomer = async (customer, { setFieldValue, values }) => {
    if (!customer) {
      setCustomerLocations([]);
      setFieldValue("customerDisplayName", "");
      setFieldValue("customerLocationId", null);
      setHideNewLocationButton(true);
      setSelectedLocation(null);
      return;
    }

    // autofill permanent instructions
    setFieldValue("instructions", customer?.permanentInstructions ?? "");
    setHideNewLocationButton(false);

    const locations = await getCustomerLocationsAsync(customer.id);
    setCustomerLocations(locations);
    if (locations?.length >= 1) {
      await setFieldValue("customerLocationId", locations[0].id);
    }

    setFieldValue("customerDisplayName", customer?.displayName ?? "");
    if (isPropaneBranch) {
      // need to autofill the tank size
      const productsWithNewTankSize = values["productList"]?.map((product) => ({
        ...product,
        tankSize: customer?.tankSize,
      }));
      setFieldValue("productList", productsWithNewTankSize);
    }
  };

  const handleSubmit = async (values) => {

    if (values.orderStatusId !== OrderStatusIdEnum.completed) {
      setLoading(true);
      const response = await Services.api.workOrders.isDuplicate(values);
      setLoading(false);

      if (response.data === true) {
        return showConfirmation(
          "Confirmation",
          `There is already an order for ${values["customerDisplayName"]} on the same day.\n\nDo you still want to save the order?`,
          [
            {
              color: ThemeColorsEnum.quaternary,
              title: "No",
            },
            {
              title: "Yes",
              onClick: async () => await submitAsync(values),
            },
          ]
        );
      }
    }

    await submitAsync(values);
  };

  const initAsync = async () => {
    workOrder = location.state?.workOrder;
    setLoading(true);
    setCustomerPredictions(customerPredictions ?? []);
    setCustomerLocations(customerLocations ?? []);
    setProducts(await getProductsAsync());
    setTrucks(await getTrucksAsync());
    setPaymentMethods(await getPaymentMethodsAsync());
    setDrivers(await getUsersAsync());
    if (workOrder?.customerId > 0) {
      setCustomerLocations(await getCustomerLocationsAsync(workOrder.customerId));
      setCustomers([
        {
          id: workOrder.customerId,
          company: workOrder.customerDisplayName,
        },
      ]);
      setHideNewLocationButton(false);
    }
    setLoading(false);
  };

  const submitAsync = async (values) => {
    setLoading(true);
    const response = await Services.api.workOrders.save({
      ...workOrder,
      ...values,
    });
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the order",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast("Saved", ThemeColorsEnum.success);

    const returnPage =
      response.data?.status === StatusEnum.completed
        ? directDrop
          ? AppRoutes.directDrops_completed
          : AppRoutes.deliveryOrders_completed
        : directDrop
          ? AppRoutes.directDrops
          : AppRoutes.deliveryOrders;
    navigate(returnPage);
  };
  const initialValues = {
    id: workOrder?.id ?? 0,
    assignedToName: workOrder?.assignedToName ?? "",
    assignedToUserId: workOrder?.assignedToUserId ?? "",
    confirmation: workOrder?.confirmation ?? "",
    customerDisplayName: workOrder?.customerDisplayName ?? "",
    customerId: workOrder?.customerId,
    customerLocationId: workOrder?.customerLocationId ?? "",
    dueDate: workOrder?.dueDate ?? "",
    instructions: workOrder?.customerPermanentInstructions ?? "",
    isDriverLocked: workOrder?.isDriverLocked,
    isDirectDrop: directDrop,
    isHighPriority: workOrder?.isHighPriority,
    locationName: workOrder?.locationName ?? "",
    notes: workOrder?.notes ?? "",
    orderStatusId: workOrder?.orderStatusId,
    paymentOptionId: workOrder?.paymentOptionId,
    productList:
      workOrder?.productList ??
      (directDrop
        ? [
          {
            id: 0,
            quantity: directDrop
              ? branchSettings?.directDropDefaultQuantity
              : "",
            unitOfMeasurement: UnitOfMeasurementsEnum.liters,
          },
        ]
        : Enumerable.from(products)
          .orderByDescending((x) => !!x.order)
          .thenBy((x) => x.order)
          .thenBy((x) => x.shortName)
          .select((x) => ({
            id: 0,
            productId: x.id,
            quantity: "",
            unitOfMeasurement: UnitOfMeasurementsEnum.liters,
            fill: false,
          }))
          .take(5)
          .toArray()),
    // productList: workOrder?.productList ?? [
    //   {
    //     id: 0,
    //     currentPercentage: "",
    //     productId: null,
    //     quantity: directDrop ? branchSettings?.directDropDefaultQuantity : "",
    //     unitOfMeasurement: UnitOfMeasurementsEnum.liters,
    //   },
    // ],
    truckId: workOrder?.truckId,
  };
  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Row>
              <Col lg={3}>
                <FormCustomerSelect
                  allItems={customers}
                  autocomplete
                  itemLabelProperty="company"
                  items={customerPredictions}
                  itemValueProperty="id"
                  label="Customer"
                  name="customerId"
                  onChange={handleSelectCustomer}
                  onInputChange={handleCustomerInputChangeWithDebounce}
                  placeholder="Search by anything..."
                  required
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormCustomerLocationSelect
                  hidePlusButton={hideNewLocationButton}
                  itemLabelProperty="name"
                  items={customerLocations}
                  itemValueProperty="id"
                  label="Customer Location"
                  name="customerLocationId"
                  onChange={handleSelectCustomerLocation}
                  required={customerLocations?.length > 0}
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormDatePickerField
                  // icon="fa-solid fa-calendar-days"
                  label="Due Date"
                  name="dueDate"
                  placeholder="Select a date"
                  required
                />
              </Col>
              <Col lg={3}>
                <FormSelectField
                  className="custom-select-field"
                  disabled={!userInfo?.isAdmin && workOrder?.isDriverLocked}
                  itemLabelProperty="label"
                  items={drivers}
                  itemValueProperty="value"
                  label="Driver"
                  name="assignedToUserId"
                  searchable
                />
                <FormCheckboxField
                  description="Only Managers can change the driver"
                  name="isDriverLocked"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <Map
                  centerPosition={{
                    lat: selectedLocation?.latitude,
                    lng: selectedLocation?.longitude,
                  }}
                  className="map-container-modal mb-3"
                  zoom={7}
                >
                  <AppMarker
                    clickable
                    info={
                      <span className="info-window-text-title">
                        {selectedLocation?.name}
                      </span>
                    }
                    order={workOrder}
                    position={{
                      lat: selectedLocation?.latitude,
                      lng: selectedLocation?.longitude,
                    }}
                  />
                </Map>
              </Col>
            </Row>

            <Row>
              <Col lg={9}>
                <FormWorkOrderProducts
                  availableProducts={products}
                  directDrop={directDrop}
                  errorFieldName="products"
                  label="Products"
                  name="productList"
                  required
                />
              </Col>
              <Col lg={3}>
                <FormTextField
                  label="Confirmation Info"
                  name="confirmation"
                  visible={!!directDrop}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={4}>
                <FormTextField
                  autoComplete="on"
                  label="Location Notes"
                  name="locationName"
                />
              </Col>
              <Col lg={4}>
                <FormSelectField
                  className="custom-select-field"
                  itemLabelProperty="name"
                  items={trucks}
                  itemValueProperty="id"
                  label="Unit"
                  name="truckId"
                />
              </Col>
              <Col lg={4}>
                <FormSelectField
                  className="custom-select-field"
                  itemLabelProperty="name"
                  items={paymentMethods}
                  itemValueProperty="id"
                  label="Payment Method"
                  name="paymentOptionId"
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="Hazard Assessment/Permanent Instructions"
                  name="instructions"
                />
              </Col>
              <Col lg={6}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="One-time Notes"
                  name="notes"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <FormCheckboxField
                  description="This is a high priority order"
                  label="High Priority"
                  name="isHighPriority"
                />
              </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" loading={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default WorkOrderFormPage;
