import { Form } from "react-bootstrap";
import React from "react";
import { ThemeColorsEnum } from "../../constants/enums";

const AppFormLabel = ({
  label,
  required,
  styleType,
  className,
  visible = true,
  ...otherProps
}) => {
  if (!visible) return null;
  if (className) className = `${className} text-${styleType}`;	
  else className = `text-${styleType}`;

  return (
    <Form.Label className={className}>
      {label}
      {required && (
        <span className={`text-${ThemeColorsEnum.danger} ms-1`}>*</span>
      )}
    </Form.Label>
  );
};

export default AppFormLabel;
