import { ClientBranchColumnsEnum } from "../constants/enums";
import "../extensions/number.extension";

const useClientBranchesDataTable = () => {
  const columns = [
    {
      header: {
        id: ClientBranchColumnsEnum.companyName,
        name: "companyName",
        text: "Company",
      },
      cell: (row) => row.companyName,
      dataTitle: "Company",
      className: "nowrap",
    },
    {
      header: {
        id: ClientBranchColumnsEnum.branchName,
        name: "branchName",
        text: "Branch Name",
      },
      cell: (row) => row.branchName,
      dataTitle: "Branch Name",
      className: "nowrap",
    },
    {
      header: {
        id: ClientBranchColumnsEnum.users,
        name: "users",
        text: "Users",
      },
      cell: (row) => row.activeUsersCount,
      dataTitle: "Users",
      className: "nowrap",
    },
    {
      header: {
        id: ClientBranchColumnsEnum.newUsers,
        name: "newUsers",
        text: "New",
      },
      cell: (row) => row.newUsersCount,
      dataTitle: "New",
      className: "nowrap",
    },
    {
      header: {
        id: ClientBranchColumnsEnum.deletedUsers,
        name: "deletedUsers",
        text: "Deleted",
      },
      cell: (row) => row.deletedUsersCount,
      dataTitle: "Deleted",
      className: "nowrap",
    },
    {
      header: {
        id: ClientBranchColumnsEnum.total,
        name: "total",
        text: "Total $",
      },
      cell: (row) => row.total.toCurrency(),
      dataTitle: "Total $",
      className: "nowrap",
    },
  ];

  return { columns };
};

export default useClientBranchesDataTable;
