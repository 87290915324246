import LoadingButton from "../buttons/LoadingButton";
import React from "react";
import { useFormikContext } from "formik";

const SubmitButton = ({
  children: title = "Submit",
  color,
  loading,
  ...otherProps
}) => {
  const { handleSubmit } = useFormikContext();

  return (
    <LoadingButton
      color={color}
      type="submit"
      loading={loading}
      onClick={handleSubmit}
      {...otherProps}
    >
      {title}
    </LoadingButton>
  );
};

export default SubmitButton;
