import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingContext } from "../../context/contextProvider";
import ProductFormPage from "./ProductFormPage";
import PropaneIcon from "@mui/icons-material/Propane";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PropaneIcon,
    title: "Products",
    to: AppRoutes.products,
  },
  {
    IconComponent: EditIcon,
    title: "Edit",
  },
];

const ProductEditPage = (props) => {
  const { id } = useParams();
  const { state } = useLocation();

  const [product, setProduct] = useState(state?.product);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    if (id && !state?.product) getProductAsync(id);
  }, [id, state]); // eslint-disable-line react-hooks/exhaustive-deps

  const getProductAsync = async (id) => {
    setLoading(true);
    const response = await Services.api.products.get(id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the product",
        ThemeColorsEnum.error
      );

    setProduct(response.data);
  };

  return (
    <ProductFormPage
      breadcrumbs={breadcrumbs}
      product={product}
      title="Products - Edit"
    />
  );
};

export default ProductEditPage;
