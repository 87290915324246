import { BranchUserColumnsEnum, ThemeColorsEnum } from "../constants/enums";
import Services from "../services/services";

const useBranchUsersDataTable = () => {
  const columns = [
    {
      header: {
        id: BranchUserColumnsEnum.userName,
        name: "username",
        text: "Username",
      },
      cell: (row) => row.userName,
      dataTitle: "Username",
      className: "nowrap",
    },
    {
      header: {
        id: BranchUserColumnsEnum.fullName,
        name: "fullName",
        text: "Full Name",
      },
      cell: (row) => row.fullName,
      dataTitle: "Full Name",
      className: "nowrap",
    },
    {
      header: {
        id: BranchUserColumnsEnum.createdDate,
        name: "createdDate",
        text: "Added on",
      },
      cell: (row) => Services.utility.formatFullDate(row.createdDate),
      dataTitle: "Added on",
      className: "nowrap",
    },
    {
      header: {
        id: BranchUserColumnsEnum.deletedDate,
        name: "deletedDate",
        text: "Deleted on",
      },
      cell: (row) =>
        !!row.deletedDate
          ? Services.utility.formatFullDate(row.deletedDate)
          : null,
      dataTitle: "Deleted on",
      className: "nowrap",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.isDeleted,
      classNames: [ThemeColorsEnum.overdue],
    },
    {
      when: (row) => row.isNew,
      classNames: [ThemeColorsEnum.completed],
    },
  ];

  return { columns, conditionalRowStyles };
};

export default useBranchUsersDataTable;
