import * as Yup from "yup";

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { AuthContext } from "../../context/contextProvider";

import DashboardIcon from "@mui/icons-material/Dashboard";
import HolidayVillageIcon from "@mui/icons-material/HolidayVillage";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import AppForm from "../../components/forms/Form";
import FormFileInputField from "../../components/forms/FormFileInputField";
import FormSelectField from "../../components/forms/FormSelectField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import Services from "../../services/services";
import Page from "../Page";

const title = "Customers - Import";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: HolidayVillageIcon,
    title: "Customers",
    to: AppRoutes.customers,
  },
  {
    IconComponent: UploadFileIcon,
    title: "Import",
  },
];

const FILE_SIZE = 50000000;

const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .required()
    .label("File")
    .test(
      "fileSize",
      "File too large",
      (value) => value === null || (value && value.size <= FILE_SIZE)
    )
    .test(
      "fileFormat",
      "Unsupported file type",
      (value) => value === null || (value && value.type === "text/csv")
    ),
});

const alpha = Array.from(Array(26)).map((e, i) => i + 65);
let spreadsheetColumns = alpha.map((x, index) => ({
  text: String.fromCharCode(x),
  value: index,
}));
spreadsheetColumns = [{ text: "Ignore", value: -1 }, ...spreadsheetColumns];

const initialValues = {
  file: "",

  companyNameColumn: -1,
  contactNameColumn: -1,
  phoneNumberColumn: -1,
  emailColumn: -1,
  memberNumberColumn: -1,
  ltdNumberColumn: -1,
  notesColumn: -1,
  latitudeColumn: -1,
  longitudeColumn: -1,
  serialNumberColumn: -1,
  sizeColumn: -1,
  routeColumn: -1,
  ramCardColumn: -1,
  comment1Column: -1,
  comment2Column: -1,
  instructionsColumn: -1,
  address1Column: -1,
  address2Column: -1,
  cityColumn: -1,
  provinceColumn: -1,
  postalCodeColumn: -1,
};

const CustomersImportPage = (props) => {
  const [busy, setBusy] = useState(false);
  const [file, setFile] = useState();

  const { currentClient } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {}, [currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFileSelected = (file, formik) => {
    setFile(file);
  };

  const handleSubmit = async (values, formik) => {
    setBusy(true);
    const response = await Services.api.customers.import(values);
    setBusy(false);

    formik?.setFieldValue("file", file); // for some reason the file gets reset to null otherwise

    if (!response.ok)
      return Services.utility.toast(
        "Could not import the customers",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast(response.data, ThemeColorsEnum.success);
    return navigate(AppRoutes.customers);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <AppForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        <Card>
          <Card.Body>
            <Row>
              <Col xxl={4} lg={6} md={12}>
                <FormFileInputField
                  label="Select a File"
                  name="file"
                  onFileSelect={handleFileSelected}
                />
                <div className="mt-2">
                  <a
                    className="downloadSample"
                    href={`${process.env.PUBLIC_URL}/Downloads/Customers-sample.csv`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <i className="fa fa-download"></i> Download an example
                  </a>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>
            <h2 className="card-title">Mapping</h2>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col>
                <div className="well">
                  In your spreadsheet, in which columns will I find the
                  following fields?
                  <br />
                  Pick <span className="highlight">Ignore</span> if it doesn't
                  exist or if you want to ignore it.
                </div>
                <div className="alert alert-warning my-3">
                  <strong>Mandatory Rules</strong>
                  <ul>
                    <li>
                      The headers must be in row <strong>#1</strong>
                    </li>
                    <li>
                      The first customer must begin from row <strong>#2</strong>
                    </li>
                  </ul>
                  {/* <p>
                    <a
                      className="btn btn-info btn-sm"
                      href="/HowTo/ImportCustomers"
                    >
                      Read Instructions
                    </a>
                  </p> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Name"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="companyNameColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Contact"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="contactNameColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Phone"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="phoneNumberColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Email"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="emailColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Member #"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="memberNumberColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Ltd #"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="ltdNumberColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Notes"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="notesColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Latitude"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="latitudeColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Longitude"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="longitudeColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Serial #"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="serialNumberColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Tank Size"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="sizeColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Route"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="routeColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Ram Card"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="ramCardColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Comment 1"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="comment1Column"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Comment 2"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="comment2Column"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Instructions"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="instructionsColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Address 1"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="address1Column"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Address 2"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="address2Column"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="City"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="cityColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Province"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="provinceColumn"
                  placeholder="Select matching column"
                />
              </Col>
              <Col xxl={2} lg={3}>
                <FormSelectField
                  label="Postal Code"
                  itemLabelProperty="text"
                  itemValueProperty="value"
                  items={spreadsheetColumns}
                  name="postalCodeColumn"
                  placeholder="Select matching column"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <div className="form-group mb-0 fixed-to-bottom">
          <Row>
            <Col lg={2} md={4} sm={6}>
              <SubmitButton className="btn-block" loading={busy}>
                Upload
              </SubmitButton>
            </Col>
          </Row>
        </div>
      </AppForm>
    </Page>
  );
};

export default CustomersImportPage;
