import { AppRoutes } from "../../constants/appRoutes";
import { Navigate } from "react-router-dom";
import React from "react";

const NavigateToLogin = () => {
  return (
    <Navigate
      to={{
        pathname: AppRoutes.login,
      }}
    />
  );
};

export default NavigateToLogin;
