import {
  CustomerLocationColumnsEnum,
  ThemeColorsEnum,
} from "../constants/enums";
import { LoadingContext, ModalContext } from "../context/contextProvider";
import { useContext, useMemo } from "react";

import { AppRoutes } from "../constants/appRoutes";
import DropdownSelect from "../components/forms/DropdownSelect";
import Services from "../services/services";
import useCurrentUserInfo from "./useCurrentUserInfo";
import { useNavigate } from "react-router-dom";

const useCustomerLocationsDataTable = (
  onDeleted = () => {},
  onSaved = () => {}
) => {
  const navigate = useNavigate();
  const userInfo = useCurrentUserInfo();

  const { setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);

  const handleDelete = async (customerLocation) => {
    setLoading(true);
    const response = await Services.api.customers.deleteLocation(
      customerLocation.customerId,
      customerLocation.id
    );
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the location",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Location deleted", ThemeColorsEnum.success);
    onDeleted(customerLocation);
  };

  const menuItems = (customerLocation) =>
    userInfo?.canEditCustomers
      ? [
          {
            title: "Edit",
            onClick: () =>
              navigate(
                AppRoutes.customerLocations_edit
                  .replace(":id", customerLocation.customerId)
                  .replace(":locationId", customerLocation.id),
                {
                  state: { customerLocation: customerLocation },
                }
              ),
          },
          ...(userInfo?.canDeleteCustomers
            ? [
                { type: "divider" },
                // Delete
                {
                  className: "text-danger",
                  onClick: () => {
                    showConfirmation(
                      "Confirmation",
                      "Do you really want to delete this location?",
                      [
                        {
                          color: ThemeColorsEnum.quaternary,
                          title: "Cancel",
                        },
                        {
                          color: ThemeColorsEnum.danger,
                          onClick: () => handleDelete(customerLocation),
                          title: "Delete",
                        },
                      ]
                    );
                  },
                  title: "Delete",
                },
              ]
            : []),
        ]
      : [];

  const columns = useMemo(
    () => [
      {
        header: {
          className: "col-menu",
          id: CustomerLocationColumnsEnum.actionButton,
          name: "id",
        },
        cell: (row) => (
          <DropdownSelect
            color={ThemeColorsEnum.quaternary}
            fixedTitle
            items={menuItems(row)}
            itemLabelProperty="title"
            itemValueProperty="title"
            size="sm"
            style={{ width: "auto" }}
            title={<i className="fa fa-bars" />}
            // title={<MenuIcon />}
          />
        ),
        dataTitle: "Menu",
        className: "nowrap",
      },
      {
        header: {
          id: CustomerLocationColumnsEnum.name,
          name: "name",
          text: "Name",
          sortable: true,
        },
        cell: (row) => row.name,
        dataTitle: "Name",
        className: "max-width-100",
      },
      {
        header: {
          id: CustomerLocationColumnsEnum.fullAddress,
          name: "fullAddress",
          text: "Full Address",
          sortable: true,
        },
        cell: (row) => row.fullAddress,
        dataTitle: "Full Address",
        className: "max-width-100",
      },
      {
        header: {
          id: CustomerLocationColumnsEnum.coordinates,
          name: "coordinates",
          text: "Coordinates",
          sortable: true,
        },
        cell: (row) => formatCoordinates(row.latitude, row.longitude),
        dataTitle: "Coordinates",
        className: "max-width-100",
      },
    ],
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const formatCoordinates = (lat, lng) => {
    if (!lat || !lng) return "";

    return `${lat},${lng}`;
  };

  return { columns };
};

export default useCustomerLocationsDataTable;
