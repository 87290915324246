import React, { useEffect, useState } from "react";

import DetailsRow from "../../components/DetailsRow";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import Services from "../../services/services";

const UserDetails = ({ user, ...otherProps }) => {
  const [userLocation, setUserLocation] = useState();

  useEffect(() => {
    getUserLocationAsync(user?.id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getUserLocationAsync = async (id) => {
    const response = await Services.api.users.getLastKnownLocation(id);
    if (response.ok)
      setUserLocation({
        lat: response.data?.geolocation?.location?.latitude,
        lng: response.data?.geolocation?.location?.longitude,
        createdDateFormatted: response.data?.createdDateFormatted,
      });
  };

  return (
    <div>
      <Map
        centerPosition={userLocation}
        className="map-container-modal mb-3"
        zoom={7}
      >
        <AppMarker
          clickable
          icon={`${process.env.PUBLIC_URL}/img/ic_pin_truck.png`}
          info={
            <>
              <span className="info-window-text-title">{user?.fullName}</span>
              <br />
              <span className="info-window-text">
                {userLocation?.createdDateFormatted}
              </span>
            </>
          }
          position={userLocation}
          visible={!!userLocation}
        />
      </Map>
      <dl className="horizontal">
        <DetailsRow title="Id" details={user?.id} />
        <DetailsRow title="Username" details={user?.userName} />
        <DetailsRow title="Email" details={user?.email} />
        <DetailsRow title="Full Name" details={user?.fullName} />
        <DetailsRow title="Phone #" details={user?.phone} />
        <DetailsRow title="Roles" details={user?.roles} />
        <DetailsRow title="Main Branch" details={user?.primaryClient} />
      </dl>
    </div>
  );
};

export default UserDetails;
