const LocalStorageKeys = {
  accessToken: "access_token",
  appPreferences: "app_preferences",
  branchModules: "branch_modules",
  branchSettings: "branch_settings",
  currentClient: "current_client",
  currentUser: "current_user",
  currentUserSettings: "current_user_settings",
  hideVersionAlert: "hide_version_alert",
  hideAlertId: "hide_alert_id",
  lastKnownLocation: "last_known_location",
  refreshToken: "refresh_token",
  timezones: "timezones",
  welcomeNotificationDismissed: "welcome_notification_dismissed",
};

const SessionStorageKeys = {
  credentialsSettings: "credentials_settings",
  udid: "udid",
};

export { LocalStorageKeys, SessionStorageKeys };
