import { Col, Row, Table } from "react-bootstrap";

import Enumerable from "linq";
import NSDataTableBody from "./NSDataTableBody";
import NSDataTableHeader from "./NSDataTableHeader";
import NSDataTableSearch from "./NSDataTableSearch";
import React from "react";
import useImportCss from "../../hooks/useImportCss";

const NSDataTable = ({
  buttons,
  className,
  columns,
  conditionalRowStyles,
  data,
  dense,
  emptyDataComponent,
  onRowClick,
  onSearch = () => {},
  onSort = () => {},
  paginate,
  search,
  searchValue,
  sortBy,
  sortDirection,
  ...otherProps
}) => {
  // Import styles
  // useImportCss(
  //   `${process.env.PUBLIC_URL}/theme/vendor/datatables/media/css/dataTables.bootstrap5.css`
  // );
  useImportCss(
    "https://cdn.datatables.net/1.12.0/css/dataTables.bootstrap5.min.css"
  );
  useImportCss(`${process.env.PUBLIC_URL}/css/data-tables.css`);

  const headers = Enumerable.from(columns)
    .select((x) => x.header)
    .toArray();
  const cells = Enumerable.from(columns)
    .select((x) => x.cell)
    .toArray();

  const tableClassNames = `${className} ${dense ? "dense" : ""}`.trim();

  return (
    <>
      <Row className="align-items-center">
        <Col md={6} sm={12}>
          {buttons}
        </Col>
        <Col md={6} sm={12}>
          {!!search && (
            <NSDataTableSearch onSearch={onSearch} searchValue={searchValue} />
          )}
        </Col>
      </Row>

      <Table className={tableClassNames} {...otherProps} striped hover>
        <NSDataTableHeader
          headers={headers}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSort={onSort}
        />
        <NSDataTableBody
          cells={cells}
          columns={columns}
          conditionalRowStyles={conditionalRowStyles}
          emptyDataComponent={emptyDataComponent}
          onRowClick={onRowClick}
          rows={data}
        />
      </Table>
    </>
  );
};

export default NSDataTable;
