import {
  AlertColumnsEnum,
  AlertTypes,
  StatusEnum,
  ThemeColorsEnum,
} from "../constants/enums";
import { LoadingContext, ModalContext } from "../context/contextProvider";

import { AppRoutes } from "../constants/appRoutes";
import DropdownSelect from "../components/forms/DropdownSelect";
import Services from "../services/services";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const useAlertsDataTable = (onDeleted = () => {}) => {
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);

  const handleDelete = async (alert) => {
    setLoading(true);
    const response = await Services.api.alerts.delete(alert.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the alert",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Alert deleted", ThemeColorsEnum.success);
    onDeleted(alert);
  };

  const menuItems = (row) => [
    // Edit
    {
      // href: AppRoutes.alerts_edit.replace(":id", row.id),
      onClick: () =>
        navigate(AppRoutes.alerts_edit.replace(":id", row.id), {
          state: { alert: row },
        }),
      title: "Edit",
    },
    { type: "divider" },
    // Delete
    {
      className: "text-danger",
      onClick: () => {
        showConfirmation(
          "Confirmation",
          "Do you really want to delete this alert?",
          [
            {
              color: ThemeColorsEnum.quaternary,
              title: "Cancel",
            },
            {
              color: ThemeColorsEnum.danger,
              onClick: () => handleDelete(row),
              title: "Delete",
            },
          ]
        );
      },
      title: "Delete",
    },
  ];

  const columns = [
    {
      header: {
        className: "col-menu",
        id: AlertColumnsEnum.actionButton,
        name: "id",
      },
      cell: (row) => (
        <DropdownSelect
          color={ThemeColorsEnum.quaternary}
          fixedTitle
          items={menuItems(row)}
          itemLabelProperty="title"
          itemValueProperty="title"
          size="sm"
          style={{ width: "auto" }}
          title={<i className="fa fa-bars" />}
          visible={row.status === StatusEnum.active}
          // title={<MenuIcon />}
        />
      ),
      dataTitle: "Menu",
      className: "nowrap",
    },
    {
      header: {
        id: AlertColumnsEnum.title,
        name: "title",
        text: "Title",
        sortable: false,
      },
      cell: (row) => row.title,
      dataTitle: "Title",
      className: "nowrap",
    },
    {
      header: {
        id: AlertColumnsEnum.notes,
        name: "notes",
        text: "Notes",
        sortable: false,
      },
      cell: (row) => Services.utility.truncateString(row.notes, 100),
      dataTitle: "Notes",
      // className: "nowrap",
    },
    {
      header: {
        id: AlertColumnsEnum.type,
        name: "type",
        text: "Type",
        sortable: false,
      },
      cell: (row) => {
        return (
          <span className={`badge badge-md badge-${row.type}`}>
            {AlertTypes.find((x) => x.value === row.type)?.label}
          </span>
        );
      },
      dataTitle: "Type",
      className: "nowrap",
    },
    {
      header: {
        id: AlertColumnsEnum.createdDate,
        name: "createdDate",
        text: "Added on",
        sortable: false,
      },
      cell: (row) => row.createdDateFormatted,
      dataTitle: "Added on",
      className: "nowrap",
    },
    {
      header: {
        id: AlertColumnsEnum.startDate,
        name: "startDate",
        text: "Start Date",
        sortable: false,
      },
      cell: (row) => row.startDateFormatted,
      dataTitle: "Expiry Date",
      className: "nowrap",
    },
    {
      header: {
        id: AlertColumnsEnum.expiryDate,
        name: "expiryDate",
        text: "Expiry Date",
        sortable: false,
      },
      cell: (row) => row.expiryDateFormatted,
      dataTitle: "Expiry Date",
      className: "nowrap",
    },
    // {
    //   header: {
    //     id: AlertColumnsEnum.status,
    //     name: "status",
    //     text: "Status",
    //     sortable: false,
    //   },
    //   cell: (row) => row.status,
    //   dataTitle: "Status",
    //   className: "nowrap",
    // },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.rowClass === ThemeColorsEnum.due,
      classNames: [ThemeColorsEnum.due],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.overdue,
      classNames: [ThemeColorsEnum.overdue],
    },
  ];

  return { columns, conditionalRowStyles };
};

export default useAlertsDataTable;
