import React, { useState,useEffect } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box } from "@mui/material";
import { ElementSize } from "../../constants/enums";
import { Form } from "react-bootstrap";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";

// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

dayjs.extend(customParseFormat);

const AppTimePicker = ({
  name,
  onChange = () => {},
  placeholder,
  size = ElementSize.md,
  value,
  ...otherProps
}) => {
  const [time, setTime] = useState(dayjs(value, "HH:mm"));


  useEffect(()=>{
    setTime(dayjs(value, "HH:mm"))
  },[value])


  const handleChange = (dateTime) => {
    setTime(dateTime);
    const time = dayjs(dateTime).format("HH:mm");

    onChange(time);
  };

  const renderCustomInput = ({ inputRef, inputProps, InputProps }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#0c2340",
        borderRadius: ".25rem",
        paddingRight: "8px",
      }}
    >
      <Form.Control
        ref={inputRef}
        {...inputProps}
        name={name}
        placeholder={placeholder}
        readOnly
        style={InputProps?.sx}
      ></Form.Control>
      {InputProps?.endAdornment}
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        // ampm
        // ampmInClock
        disableMaskedInput
        //   Style Props --- start
        PaperProps={{
          sx: {
            backgroundColor: "#000000",
            color: "#eeeeee",
            "& .MuiButtonBase-root": {
              color: "#ffffff",
            },
            "& .MuiPickersArrowSwitcher-button": {
              backgroundColor: "#0c2340",
              color: "#ffffff",
            },
            // clocl background color
            "& .css-eziifo": {
              backgroundColor: "#0c2340",
            },
            // clock numbers color
            "& .css-1flhz3h, .css-x9nfkm": {
              color: "#ffffff",
            },
            // clock selected items color
            "& .css-7lip4c, .css-12ha4i7, .css-118whkv, .Mui-selected, .css-a1rc6s":
              {
                backgroundColor: "#d3121a",
              },
          },
        }}
        InputProps={{
          disableUnderline: true,
          sx: {
            backgroundColor: "#0c2340",
          },
        }}
        OpenPickerButtonProps={{
          sx: { color: "hsl(0, 0%, 80%)" },
        }}
        //   Style Props --- end
        inputFormat="HH:mm"
        onChange={handleChange}
        renderInput={renderCustomInput}
        showToolbar={false}
        value={time}
      />
    </LocalizationProvider>
  );
};

export default AppTimePicker;
