import { SearchFilterRequest } from "./searchFilterRequest";
import { StatusEnum } from "../constants/enums";

class ScheduledOrderFilterRequest extends SearchFilterRequest {
	constructor() {
		super();
		this.assignedToUserId = null;
		this.completedByUserId = null;
		this.completedOnFrom = null;
		this.completedOnTo = null;
		this.customerId = null;
		this.dashboardType = null;
		this.isDirectDrop = null;
		this.isPickUp = null;
		this.orderStatusId = null;
		this.productId = null;
		this.status = StatusEnum.active;
		this.workOrderType = null;
	}
}

export default ScheduledOrderFilterRequest;
