const getProperty = (name) => {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (err) {
    console.error("Could not get property", name);
  }
};

const setProperty = (name, value) => {
  try {
    localStorage.setItem(name, JSON.stringify(value));
  } catch (err) {
    console.error("Could not set property", name);
  }
};

const removeProperty = (name) => {
  try {
    localStorage.removeItem(name);
  } catch (err) {
    console.error("Could not remove property", name);
  }
};
const clear = () => {
  try {
    localStorage.clear();
  } catch (err) {
    console.error("Could not clear localStorage");
  }
};

//------------------------------------------------
const localStorageService = {
  getProperty,
  setProperty,
  removeProperty,
  clear,
};

export default localStorageService;
//------------------------------------------------
