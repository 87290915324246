import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const Overlay = ({ visible = false, ...otherProps }) => {
  if (!visible) return null;

  return (
    <div className="app-overlay" {...otherProps}>
      {/* <FontAwesomeIcon icon={faSpinner} /> */}
      <i className="fa fa-spinner fa-spin" />
    </div>
  );
};

export default Overlay;
