import Endpoints from "../../constants/endpoints";
import httpService from "../httpService";

const all = async () => {
  const url = Endpoints.roles.all;
  const response = await httpService.get(url);

  return response;
};

//-----------------------------------------//
const rolesApiService = {
  all,
};

export default rolesApiService;
//-----------------------------------------//
