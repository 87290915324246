
import PropTypes from "prop-types";


//performance tab developer settings on chrome

export const RemoveButton = ({
    ariaLabel,
    handleClick,
}) => {
    return (
    <button
        aria-label={ariaLabel}
        type="button"
        onClick={handleClick}
        style={styles.RemoveButtonStyle}
    >
        <svg style={{justifyContent: "start", overflow: "visible"}} viewBox="0 0 16 16" preserveAspectRatio="xMinYMin" >
            <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"
            />
        </svg>
    </button>
    );
};

let styles = {

    RemoveButtonStyle: {
        backgroundColor: "transparent",
        outline: "none",
        border: "none",
        width:16,
        height: 16,
        fill: "red",
        padding: 0, 
        marging: 0,
        borderCollapse: "collapse",
    }

}

RemoveButton.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default RemoveButton;
