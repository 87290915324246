import {
  ScheduledCheckInColumnsEnum,
  ThemeColorsEnum,
} from "../constants/enums";

const useCheckInsDataTable = () => {
  const columns = [
    {
      header: {
        id: ScheduledCheckInColumnsEnum.actionButton,
        name: "id",
        text: "Id",
        sortable: true,
      },
      cell: (row) => row.id,
      dataTitle: "Id",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledCheckInColumnsEnum.enteredBy,
        name: "createdByName",
        text: "User",
        sortable: true,
      },
      cell: (row) => row.createdByName,
      dataTitle: "User",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledCheckInColumnsEnum.enteredOn,
        name: "createdDate",
        text: "Check-in Request Time",
        sortable: true,
      },
      cell: (row) => row.createdDateFormatted,
      dataTitle: "Request Time",
      className: "nowrap",
    },
    {
      header: {
        id: ScheduledCheckInColumnsEnum.completedOn,
        name: "completedDate",
        text: "Check-in Completed Time",
        sortable: true,
      },
      cell: (row) => row.completedDateFormatted,
      dataTitle: "Completed at",
      className: "nowrap",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.rowClass === ThemeColorsEnum.completed,
      classNames: [ThemeColorsEnum.completed],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.due,
      classNames: [ThemeColorsEnum.due],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.overdue,
      classNames: [ThemeColorsEnum.overdue],
    },
  ];

  return { columns, conditionalRowStyles };
};

export default useCheckInsDataTable;
