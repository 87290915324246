import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import React from "react";
import ServiceOrderCategoryFormPage from "./ServiceOrderCategoryFormPage";

const title = "Service Categories - New";
const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: FormatListBulletedIcon,
    title: "Service Categories",
    to: AppRoutes.serviceOrderCategories,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const ServiceOrderCategoryCreatePage = (props) => {
  return (
    <ServiceOrderCategoryFormPage breadcrumbs={breadcrumbs} title={title} />
  );
};

export default ServiceOrderCategoryCreatePage;
