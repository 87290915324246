import Button from "./Button";
import React from "react";
import { ThemeColorsEnum } from "../../constants/enums";

const CircleButton = ({
  className,
  color = ThemeColorsEnum.primary,
  icon,
  IconComponent,
  onClick = () => {},
  size,
  style,
  title,
  to = "#",
  ...otherProps
}) => {
  style = {
    ...style,
    ...(size
      ? {
          height: `${size}px`,
          width: `${size}px`,
          lineHeight: `${size - 5}px`,
        }
      : null),
  };
  return (
    <Button
      className={`circle ${className}`.trim()}
      color={color}
      icon={icon}
      IconComponent={IconComponent}
      onClick={onClick}
      style={style}
      title={title}
      to={to}
      {...otherProps}
    />
  );
};

export default CircleButton;
