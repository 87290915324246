import React, { useContext } from "react";

import { AuthContext } from "../context/contextProvider";
import Breadcrumbs from "../components/header/Breadcrumbs";
import HeaderNav from "../components/header/HeaderNav";
import SidebarNav from "../components/sidebar/SidebarNav";
import SidebarNavRight from "../components/sidebar/SidebarNavRight";
import useImportScript from "../hooks/useImportScript";

const Page = ({
  breadcrumbs,
  children: content,
  sidebarRightContent,
  title,
  ...otherProps
}) => {
  const { currentUser } = useContext(AuthContext);

  useImportScript(`${process.env.PUBLIC_URL}/theme/js/theme.js`, currentUser);
  useImportScript(`${process.env.PUBLIC_URL}/js/sidebar-menu.js`, currentUser);
  useImportScript(`${process.env.PUBLIC_URL}/js/header-nav.js`, currentUser);
  useImportScript(
    `${process.env.PUBLIC_URL}/theme/js/theme.init.js`,
    currentUser
  );

  return (
    <section className="body">
      <HeaderNav />
      <div className="inner-wrapper">
        <SidebarNav />
        <section role="main" className="content-body">
          <Breadcrumbs items={breadcrumbs} title={title} />
          {content}
        </section>
      </div>
      <SidebarNavRight>{sidebarRightContent}</SidebarNavRight>
    </section>
  );
};

export default Page;
