import { EnrolledClientColumnsEnum, ThemeColorsEnum } from "../constants/enums";

const useEnrolledClientsDataTable = () => {
  const columns = [
    {
      header: {
        id: EnrolledClientColumnsEnum.companyName,
        name: "companyName",
        text: "Company",
      },
      cell: (row) => row.name,
      dataTitle: "Company",
      className: "nowrap",
    },
    {
      header: {
        id: EnrolledClientColumnsEnum.city,
        name: "city",
        text: "City",
      },
      cell: (row) => row.city,
      dataTitle: "City",
      className: "nowrap",
    },
    {
      header: {
        id: EnrolledClientColumnsEnum.phone,
        name: "phone",
        text: "Phone",
      },
      cell: (row) => row.phone,
      dataTitle: "Phone",
      className: "nowrap",
    },
    {
      header: {
        id: EnrolledClientColumnsEnum.email,
        name: "email",
        text: "Email",
      },
      cell: (row) => row.email,
      dataTitle: "Email",
      className: "nowrap",
    },
    {
      header: {
        id: EnrolledClientColumnsEnum.provinceState,
        name: "provinceState",
        text: "Province/State",
      },
      cell: (row) => row.province,
      dataTitle: "Province/State",
      className: "nowrap",
    },
    {
      header: {
        id: EnrolledClientColumnsEnum.country,
        name: "country",
        text: "Country",
      },
      cell: (row) => row.country,
      dataTitle: "Country",
      className: "nowrap",
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row.rowClass === ThemeColorsEnum.completed,
      classNames: [ThemeColorsEnum.completed],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.due,
      classNames: [ThemeColorsEnum.due],
    },
    {
      when: (row) => row.rowClass === ThemeColorsEnum.overdue,
      classNames: [ThemeColorsEnum.overdue],
    },
  ];

  return { columns, conditionalRowStyles };
};

export default useEnrolledClientsDataTable;
