// import apiService from "./api/apiServices";

import apiService from "./api/apiService";
import authService from "./authService";
import billingService from "./billingService";
import localStorageService from "./localStorageService";
import logService from "./logService";
import sessionStorageService from "./sessionStorageService";
import utilityService from "./utilityService";
import workOrdersService from "./workOrdersService";

/**
 * This is just a container of all the available services in the app
 */
//------------------------------------------------
const Services = {
  api: apiService,
  auth: authService,
  billing: billingService,
  localStorage: localStorageService,
  log: logService,
  sessionStorage: sessionStorageService,
  utility: utilityService,
  workOrders: workOrdersService,
};

export default Services;
//------------------------------------------------
