import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const all = async () => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.trucks.all.replace("{clientId}", clientId);

  const response = await httpService.get(url);

  return response;
};

const create = async (payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.trucks.create;
  const response = await httpService.post(url, {
    ...payload,
    clientId: clientId,
  });

  return response;
};

/**
 *
 * @param {int} id The truck id
 * @returns
 */
const deleteTruck = async (id) => {
  const url = Endpoints.trucks.delete.replace("{id}", id);
  const response = await httpService.delete(url);

  return response;
};

const get = async (id) => {
  const url = Endpoints.trucks.get.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

/**
 *
 * @param {SearchFilterRequest} filter
 * @returns
 */
const getTrucks = async (filter) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.trucks.filter.replace("{clientId}", clientId);
  const response = await httpService.post(url, filter, false, true);

  return response;
};

const save = async (payload) => {
  return payload?.id > 0 ? await update(payload) : await create(payload);
};

const update = async (payload) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.trucks.update.replace("{id}", payload?.id);
  const response = await httpService.put(url, {
    ...payload,
    clientId: clientId,
  });

  return response;
};

//-----------------------------------------//
const trucksApiService = {
  all,
  delete: deleteTruck,
  get,
  getTrucks,
  save,
};

export default trucksApiService;
//-----------------------------------------//
