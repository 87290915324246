const Constants = {
  defaults: {
    pageCache: {
      search: null,
      sortColumn: null,
      sortDirection: null,
    },
    rowsPerPage: 100,
  },
};

export default Constants;
