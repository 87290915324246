import AddIcon from "@mui/icons-material/Add";
import Button from "./Button";
import React from "react";

const AddButton = ({ to, ...otherProps }) => {
  return (
    <Button
      IconComponent={() => <AddIcon style={{ fontSize: 15 }} />}
      title="Add New"
      to={to}
      {...otherProps}
    />
  );
};

export default AddButton;
