import React, { useContext } from "react";

import { AppRoutes } from "../../constants/appRoutes";
import { AuthContext } from "../../context/contextProvider";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Navigate } from "react-router-dom";
import WorkOrdersMapPage from "../workOrders/WorkOrdersMapPage";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashhboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: LocalGasStationIcon,
    title: "Delivery Orders",
    to: AppRoutes.deliveryOrders,
  },
  {
    IconComponent: LocationOnIcon,
    title: "Map",
  },
];

const DeliveryOrdersMapPage = (props) => {
  const { branchModules } = useContext(AuthContext);

  if (!branchModules?.workOrders)
    return (
      <Navigate
        to={{
          pathname: AppRoutes.forbidden,
        }}
      />
    );

  return (
    <WorkOrdersMapPage
      breadcrumbs={breadcrumbs}
      title="Delivery Orders - Map"
    ></WorkOrdersMapPage>
  );
};

export default DeliveryOrdersMapPage;
