import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../assets/img/LiveDispatchLogo.png";
import CopyrightFooter from "../../components/CopyrightFooter";
import AppForm from "../../components/forms/Form";
import FormTextField from "../../components/forms/FormTextField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { ThemeColorsEnum } from "../../constants/enums";
import { LoadingContext } from "../../context/contextProvider";
import Services from "../../services/services";
import GuestPage from "../GuestPage";

let validationSchema = Yup.object().shape({
  username: Yup.string().required().label("Username"),
  password: Yup.string()
    .required()
    .min(6, "Password is too short (min 6)")
    .label("Password"),
  confirmPassword: Yup.string()
    .when("password", {
      is: (password) => password?.length > 0,
      then: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
      otherwise: Yup.string()
        .optional()
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    })
    .label("Confirm password"),
  code: Yup.string().required(),
});

const ResetPasswordPage = (props) => {
  const [busy, setBusy] = useState(false);

  const { setLoading } = useContext(LoadingContext);

  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");
  const navigate = useNavigate();

  useEffect(() => {
    return () => {};
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (values) => {
    setLoading(true);
    const response = await Services.api.account.resetPassword({
      ...values,
      code,
    });
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not reset your password.",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast("Your password was reset!", ThemeColorsEnum.success);
    navigate(AppRoutes.login);
  };

  return (
    <GuestPage className="body-sign">
      <div className="center-sign">
        <a href="/" className="logo float-left">
          <img src={logo} height="70" alt="Porto Admin" />
        </a>

        <div className="panel card-sign">
          <div className="card-title-sign mt-3 text-end">
            <h2 className="title text-uppercase font-weight-bold m-0">
              <i className="bx bx-user-circle me-1 text-6 position-relative top-5"></i>
              Reset Password
            </h2>
          </div>

          <div className="card-body">
            <div className="alert alert-info">
              <i className="fas fa-envelope"></i>&nbsp;Enter your username and
              your new password.
            </div>
            <AppForm
              initialValues={{
                username: "",
                password: "",
                confirmPassword: "",
                code: code,
              }}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              <FormTextField
                name="username"
                label="Username"
                icon="bx bx-user text-4"
                required
                size="lg"
              />

              <Row>
                <Col>
                  <FormTextField
                    icon="bx bx-lock text-4"
                    info="Min. 6 characters and 1 number"
                    label="New Password"
                    name="password"
                    required
                    size="lg"
                    type="password"
                  />
                </Col>
                <Col>
                  <FormTextField
                    icon="bx bx-lock text-4"
                    label="Confirm Password"
                    name="confirmPassword"
                    required
                    size="lg"
                    type="password"
                  />
                </Col>
              </Row>

              <div className="row">
                <div className="col-sm-5">
                  {/* <div className="checkbox-custom checkbox-default">
                      <input
                        id="RememberMe"
                        name="rememberme"
                        type="checkbox"
                      />
                      <label htmlFor="RememberMe">Remember Me</label>
                    </div> */}
                </div>

                <div className="col-sm-7 text-end">
                  <SubmitButton loading={busy}>Submit</SubmitButton>
                </div>
              </div>
            </AppForm>
            <p className="text-center mt-3">
              Remembered? <Link to={AppRoutes.login}>Sign In!</Link>
            </p>
          </div>
        </div>
        <CopyrightFooter />
      </div>
    </GuestPage>
  );
};

export default ResetPasswordPage;
