import { SearchFilterRequest } from "./searchFilterRequest";
import { StatusEnum } from "../constants/enums";

class WorkOrderFilterRequest extends SearchFilterRequest {
  constructor() {
    super();

    this.assignedToUserId = null;
    this.completedByUserId = null;
    this.completedOnFrom = null;
    this.completedOnTo = null;
    this.customerId = null;
    this.dashboardType = null;
    this.isDirectDrop = null;
    this.isPickUp = null;
    this.isSchedule = false;
    this.orderStatusId = null;
    this.productId = null;
    this.status = StatusEnum.active;
    this.workOrderType = null;
  }
}

export default WorkOrderFilterRequest;
