import Endpoints from "../../constants/endpoints";
import { OrderStatusIdEnum } from "../../constants/enums";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const assign = async (id, userId) => {
  const url = Endpoints.workOrders.assign
    .replace("{id}", id)
    .replace("{userId}", userId);
  const response = await httpService.put(url);

  return response;
};

const complete = async (payload, sync = false) => {
  const url = Endpoints.workOrders.complete
    .replace("{id}", payload?.id)
    .replace("{sync}", sync);
  const response = await httpService.put(url, payload);

  return response;
};

const create = async (payload, sync) => {
  const url = Endpoints.workOrders.create.replace("{sync}", sync);
  const response = await httpService.post(url, payload);

  return response;
};

/**
 *
 * @param {int} id The workOrder id
 * @returns
 */
const deleteWorkOrder = async (id) => {
  const url = Endpoints.workOrders.delete.replace("{id}", id);
  const response = await httpService.delete(url);

  return response;
};

const get = async (id) => {
  const url = Endpoints.workOrders.get.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const getActiveWorkOrdersGeolocations = async (directDrops = false) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.workOrders.geolocations
    .replace("{clientId}", clientId)
    .replace("{isDirectDrop}", directDrops);
  const response = await httpService.get(url);

  return response;
};

const getPaymentMethods = async () => {
  const url = Endpoints.workOrders.paymentMethods;
  const response = await httpService.get(url);

  return response;
};

const getWorkOrders = async (filter) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.workOrders.filter.replace("{clientId}", clientId);
  const response = await httpService.post(url, filter);

  return response;
};

const isDuplicate = async (workOrder) => {
  // const formattedDueDate = format(new Date(workOrder?.dueDate), "yyyy-MM-dd");
  // const formattedDueDate = moment(workOrder?.dueDate).format("YYYY-MM-DD");
  // const formattedDueDate = utilityService.formatISODate(workOrder?.dueDate);

  const url = Endpoints.workOrders.isDuplicate
    .replace("{workOrderId}", workOrder?.id)
    .replace("{customerId}", workOrder?.customerId)
    .replace("{dueDate}", workOrder?.dueDate);
  const response = await httpService.get(url);

  return response;
};

const move = async (id, newClientId) => {
  const url = Endpoints.workOrders.move
    .replace("{id}", id)
    .replace("{newClientId}", newClientId);
  const response = await httpService.put(url);

  return response;
};

const save = async (payload, sync = false) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  payload = {
    ...payload,
    clientId: payload.clientId ?? clientId,
    // dueDate: utilityService.formatISODate(payload?.dueDate),
    orderStatusId:
      payload?.id > 0 ? payload?.orderStatusId : OrderStatusIdEnum.new,
  };

  return payload?.id > 0
    ? await update(payload, sync)
    : await create(payload, sync);
};

const start = async (id, sync = false) => {
  const url = Endpoints.workOrders.start
    .replace("{id}", id)
    .replace("{sync}", sync);
  const response = await httpService.put(url);

  return response;
};

const undoStart = async (id, sync = false) => {
  const url = Endpoints.workOrders.undoStart
    .replace("{id}", id)
    .replace("{sync}", sync);
  const response = await httpService.put(url);

  return response;
};

const update = async (payload, sync) => {
  const url = Endpoints.workOrders.update
    .replace("{id}", payload?.id)
    .replace("{sync}", sync);
  const response = await httpService.put(url, payload);

  return response;
};

//-----------------------------------------//
const workOrdersApiService = {
  assign,
  complete,
  delete: deleteWorkOrder,
  get,
  getActiveWorkOrdersGeolocations,
  getPaymentMethods,
  getWorkOrders,
  isDuplicate,
  move,
  save,
  start,
  undoStart,
};

export default workOrdersApiService;
//-----------------------------------------//
