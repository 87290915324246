import { AuthContext, LoadingContext } from "../../context/contextProvider";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EditIcon from "@mui/icons-material/Edit";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import React from "react";
import ScheduledOrderFormPage from "./ScheduledOrderFormPage";
import Services from "../../services/services";
import { ThemeColorsEnum, WorkOrderTypesEnum } from "../../constants/enums";
import { useContext } from "react";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import { useNavigate } from "react-router-dom";

const breadcrumbs = [
	{
		IconComponent: DashboardIcon,
		title: "Dashboard",
		to: AppRoutes.dashboard,
	},
	{
		IconComponent: EventRepeatIcon,
		title: "Scheduled Orders",
		to: AppRoutes.scheduledOrders,
	},
	{
		IconComponent: EditIcon,
		title: "Edit",
	},
];

const ScheduledOrderEditPage = (props) => {
	const { id } = useParams();
	const { state } = useLocation();

	const { setLoading } = useContext(LoadingContext);
	const { branchModules } = useContext(AuthContext);
	const userInfo = useCurrentUserInfo();
	const navigate = useNavigate();
	

	//useEffect(() => {
	//  setScheduledOrder(state?.scheduledOrder);
	//}, []); // eslint-disable-line react-hooks/exhaustive-deps

	//useEffect(() => {
	//  if (id && !state?.scheduledOrder) getScheduledOrderAsync(id);
	//}, [id]); // eslint-disable-line react-hooks/exhaustive-deps
	//
	//const getScheduledOrderAsync = async (id) => {
	//  setLoading(true);
	//  const response = await Services.api.workOrders.get(id);
	//  setLoading(false);
	//
	//  if (!response.ok)
	//    return Services.utility.toast(
	//      "Could not fetch the schedule",
	//      ThemeColorsEnum.error
	//    );
	//
	//  setScheduledOrder(response.data);
	//};

	const canAccessOrder = (type, module, permission) => {
		var ret = state?.scheduledOrder?.workOrderType === type &&
			(branchModules?.[module] && userInfo[permission]);
		return ret;
	}

	const deleteOrder = async (values, templateId, deleteOrderType) => {
		if (templateId <= 0 || templateId === undefined)
			return Services.utility.toast(
				"Order id is invalid",
				ThemeColorsEnum.error,
				templateId);
		let orderResponse;
		switch (deleteOrderType) {
			case WorkOrderTypesEnum.workOrder:
				values.isDirectDrop = false;
				orderResponse = await Services.api.workOrders.delete(templateId);
				return orderResponse;
				orderResponse = await Services.api.serviceOrders.delete(templateId);
				return orderResponse;
			case WorkOrderTypesEnum.directDrop:
				values.isDirectDrop = true;
				orderResponse = await Services.api.workOrders.delete(templateId);
				return orderResponse;
		}
	};

	const newOrderUpdateOrder = async (values, templateId) => {
		
		const payload = values;
		if (templateId)
			payload.id = templateId;
		else
			payload.id = null;
		
		let orderResponse;
		// eslint-disable-next-line default-case
		setLoading(true);
		switch (values.orderType) {
			case WorkOrderTypesEnum.workOrder:
				values.isDirectDrop = false;
				orderResponse = await Services.api.workOrders.save({
					...payload,
				});
				setLoading(false);
				return orderResponse;
			case WorkOrderTypesEnum.serviceOrder:
				orderResponse = await Services.api.serviceOrders.save({
					...payload,
				});
				setLoading(false);
				return orderResponse;
			case WorkOrderTypesEnum.directDrop:
				values.isDirectDrop = true;
				orderResponse = await Services.api.workOrders.save({
					...payload,
				});
				setLoading(false);
				return orderResponse;
		}
	}

	const handleSubmit = async (scheduledOrder, order) => {
		setLoading(true);
		var response = await Services.api.scheduledOrders.get(scheduledOrder?.id);
		setLoading(false);
		if (!response.ok) {
			return Services.utility.toast(
				"Could not retrieve scheduled order with id: ".concat(scheduledOrder?.id),
				ThemeColorsEnum.error,
				response
			);
		}
			

		const templateId = response.data?.templateId;

		let orderResponse;
		if (response.data?.workOrderType !== order.orderType) {
			// Delete the old order
			//TODO Deleting the wrong thing
			setLoading(true);
			const deleteResponse = await deleteOrder(
				order, 
				templateId, 
				response.data?.workOrderType);
			setLoading(false);
			if ((!deleteResponse || !deleteResponse.ok) &&
				deleteResponse?.data?.message !== "This order has already been deleted") {
				return Services.utility.toast(
					"Could not delete the old scheduled order",
					ThemeColorsEnum.error,
					deleteResponse
				);
			}

			// Create a new order with the new type
			orderResponse = await newOrderUpdateOrder(order);
		} else {
			// Update the existing order
			orderResponse = await newOrderUpdateOrder(order, templateId);
		}

		if (!orderResponse || !orderResponse.ok) {
			return Services.utility.toast(
				"Could not save the order, type: ".concat(order.orderType),
				ThemeColorsEnum.error,
				orderResponse
			);
		}

		scheduledOrder.templateId = orderResponse?.data?.id;
		
		// Update the scheduled order
		if (scheduledOrder.templateId <= 0 || scheduledOrder.templateId == undefined) {
			return Services.utility.toast(
				"No valid template Id",
				ThemeColorsEnum.error,
				scheduledOrder
			);

		}
		setLoading(true);
		let scheduledOrderResponse = await Services.api.scheduledOrders.update(scheduledOrder);
		setLoading(false);
		if (!scheduledOrderResponse || !scheduledOrderResponse.ok) {
			console.log("update failed");
			return Services.utility.toast(
				scheduledOrderResponse?.message,
				ThemeColorsEnum.error,
				scheduledOrderResponse
			);
		}
		else {
			Services.utility.toast(
				"Scheduled order updated successfully", 
				ThemeColorsEnum.success, 
				scheduledOrderResponse);
			navigate(AppRoutes.scheduledOrders);
		}
	};


	if (
		(state?.scheduledOrder?.workOrderType === WorkOrderTypesEnum.workOrder &&
			!canAccessOrder(WorkOrderTypesEnum.workOrder, 'workOrders', 'canEditDeliveryOrders')) ||
		(state?.scheduledOrder?.workOrderType === WorkOrderTypesEnum.serviceOrder &&
			!canAccessOrder(WorkOrderTypesEnum.serviceOrder, 'serviceOrders', 'canEditDeliveryOrders')) ||
		(state?.scheduledOrder?.workOrderType === WorkOrderTypesEnum.directDrop &&
			!canAccessOrder(WorkOrderTypesEnum.directDrop, 'directDrops', 'canEditDeliveryOrders'))
	) {
		return (
			<Navigate
				to={{
					pathname: AppRoutes.forbidden,
				}}
			/>
		);
	}

	return (
		<ScheduledOrderFormPage
			breadcrumbs={breadcrumbs}
			title="Scheduled Orders - Edit"
			scheduledOrder={state?.scheduledOrder}
			initialWorkOrderType={state?.scheduledOrder.workOrderType}
			order={state?.scheduledOrder.workOrder ||
				state?.scheduledOrder.serviceOrder}
			onEdit={handleSubmit}
		/>
	);
};

export default ScheduledOrderEditPage;
