import React, { useCallback, useEffect, useState } from "react";

import DetailsRow from "../../components/DetailsRow";
import Map from "../../components/maps/Map";
import AppMarker from "../../components/maps/Marker";
import { ThemeColorsEnum } from "../../constants/enums";
import useBranchInfo from "../../hooks/useBranchInfo";
import Services from "../../services/services";

const CustomerDetails = ({ customer, ...otherProps }) => {
  const [customerLocations, setCustomerLocations] = useState([]);
  const { isPropaneBranch } = useBranchInfo();

  useEffect(() => {
    if (!!customer?.id) getCustomerLocationsAsync(customer.id);
  }, [customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomerLocationsAsync = useCallback(async () => {
    // setLoading(true);
    const response = await Services.api.customers.getLocations(customer?.id);
    // setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the locations",
        ThemeColorsEnum.error
      );

    setCustomerLocations(response.data);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Map
        centerPosition={{
          lat: customerLocations?.[0]?.latitude,
          lng: customerLocations?.[0]?.longitude,
        }}
        className="map-container-modal mb-3"
        zoom={7}
      >
        {customerLocations?.map((customerLocation) => (
          <AppMarker
            clickable
            info={
              <span className="info-window-text-title">
                {customerLocation.name}
              </span>
            }
            key={customerLocation.id}
            position={{
              lat: customerLocation.latitude,
              lng: customerLocation.longitude,
            }}
          />
        ))}
      </Map>
      <dl className="horizontal">
        <DetailsRow title="Id" details={customer.id} />
        <DetailsRow title="Name" details={customer.displayName} />
        <DetailsRow
          title="Member #"
          details={customer.memberNumber}
          visible={!!customer.memberNumber}
        />
        <DetailsRow
          title="Contact"
          details={customer.contactName}
          visible={!!customer.contactName}
        />
        <DetailsRow
          title="Phone"
          details={customer.phone}
          visible={!!customer.phone}
        />
        <DetailsRow
          title="Serial #"
          details={customer.serialNumber}
          visible={!!customer.serialNumber}
        />
        <DetailsRow
          title="Email"
          details={customer.email}
          visible={!!customer.email}
        />
        <DetailsRow
          title="City"
          details={customer.city}
          visible={!!customer.city}
        />
        <DetailsRow
          title="Province"
          details={customer.province}
          visible={!!customer.province}
        />
        <DetailsRow
          title="Country"
          details={customer.country}
          visible={!!customer.country}
        />
        {/* Propane only */}
        <DetailsRow
          title="Tank Size"
          details={customer.tankSize}
          visible={!!customer.tankSize && isPropaneBranch}
        />
        <DetailsRow
          title="Route"
          details={customer.route}
          visible={!!customer.route && isPropaneBranch}
        />
        <DetailsRow
          title="Ram Card"
          details={customer.ramCard}
          visible={!!customer.ramCard && isPropaneBranch}
        />
        <DetailsRow
          title="Comment 1"
          details={customer.comment1}
          visible={!!customer.comment1 && isPropaneBranch}
        />
        <DetailsRow
          title="Comment 2"
          details={customer.comment2}
          visible={!!customer.comment2 && isPropaneBranch}
        />
        <DetailsRow
          title={
            <span>
              Hazard Assessment
              <br />
              Permanent Instructions
            </span>
          }
          details={customer?.permanentInstructions}
          visible={!!customer?.permanentInstructions}
        />
        <DetailsRow
          title="Notes"
          details={customer?.notes}
          visible={!!customer?.notes}
        />
      </dl>
    </>
  );
};

export default CustomerDetails;
