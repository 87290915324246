import { StatusCodes } from "http-status-codes";

class FakeHttpReponse {
  constructor(
    data = null,
    statusCode = StatusCodes.OK,
    ok = true,
    problem = null
  ) {
    this.ok = ok;
    this.problem = problem;
    this.statusCode = statusCode;
    this.data = data;
  }
}

export default FakeHttpReponse;
