import React, { useContext, useEffect, useState } from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Alert } from "@mui/material";
import { AppRoutes } from "../../constants/appRoutes";
import AppSelect from "../forms/Select";
import { AuthContext } from "../../context/contextProvider";
import BuildIcon from "@mui/icons-material/Build";
import { Link } from "react-router-dom";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { LocalStorageKeys } from "../../constants/storageKeys";
import OilBarrelIcon from "@mui/icons-material/OilBarrel";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";
import TvIcon from "@mui/icons-material/Tv";
import UserBox from "./UserBox";
import { isMobile } from "react-device-detect";
import logo from "../../assets/img/LiveDispatchLogo.png";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";

const HeaderNav = ({ onToggleSidebar = () => {}, ...otherProps }) => {
  const [showAlert, setShowAlert] = useState(false);

  const {
    branchModules,
    currentClient,
    setCurrentClient,
    currentUser,
    userSettings,
  } = useContext(AuthContext);

  const [branches, setBranches] = useState(currentUser?.clients);
  const userInfo = useCurrentUserInfo();

  // useEffect(() => {
  //   const hideAlert = Services.localStorage.getProperty(
  //     LocalStorageKeys.hideVersionAlert
  //   );
  //   setShowAlert(!hideAlert);
  // }, []);

  useEffect(() => {
    setBranches(currentUser?.clients);
  }, [currentUser]);

  const handleHideAlert = () => {
    setShowAlert(false);
    Services.localStorage.setProperty(LocalStorageKeys.hideVersionAlert, true);
  };

  const handleOpenUrl = (url) => (event) => {
    window.open(url, "_blank", "noreferrer");
  };

  const handleSwitchBranch = (branch) => {
    const newBranch = currentUser?.clients?.find(
      (x) => x.id === parseInt(branch.id)
    );

    Services.localStorage.setProperty(
      LocalStorageKeys.currentClient,
      newBranch
    );
    setCurrentClient(newBranch);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <a href="/#" className="logo">
          <img src={logo} width="150" alt="NorthStar Systems" />
        </a>
        <div
          className="d-md-none toggle-sidebar-left"
          data-toggle-class="sidebar-left-opened"
          data-target="html"
          data-fire-event="sidebar-left-opened"
        >
          <i className="fas fa-bars" aria-label="Toggle sidebar" />
        </div>
      </div>
      <div className="header-right">
        <ul className="notifications">
          <li>
            <Link
              aria-expanded="false"
              className="dropdown-toggle notification-icon"
              data-bs-toggle="dropdown"
              to="#"
            >
              <i className="fa fa-plus-circle"></i>
              {/* <AddCircleIcon /> */}
              {/* <span className="badge">new</span> */}
            </Link>
            <div className="dropdown-menu notification-menu" role="menu">
              {branchModules?.workOrders &&
                !userSettings?.hideWorkOrders &&
                userInfo?.canCreateDeliveryOrders && (
                  <Link
                    className="dropdown-item text-1"
                    to={AppRoutes.deliveryOrders_create}
                  >
                    <span>
                      <LocalGasStationIcon style={{ fontSize: 20 }} /> Delivery
                      Order
                    </span>
                  </Link>
                )}
              {branchModules?.directDrops &&
                !userSettings?.hideDirectDrops &&
                userInfo?.canCreateDeliveryOrders && (
                  <Link
                    className="dropdown-item text-1"
                    to={AppRoutes.directDrops_create}
                  >
                    <span>
                      <OilBarrelIcon style={{ fontSize: 20 }} /> Direct Drop
                    </span>
                  </Link>
                )}
              {branchModules?.serviceOrders &&
                !userSettings?.hideServiceOrders && (
                  <Link
                    className="dropdown-item text-1"
                    to={AppRoutes.serviceOrders_create}
                  >
                    <span>
                      <BuildIcon style={{ fontSize: 20 }} /> Service Order
                    </span>
                  </Link>
                )}
            </div>
          </li>
		  <li>
            <Link
              to={AppRoutes.contact}
              className="dropdown-toggle notification-icon"
              title="Contact"
            >
              <i className="fas fa-envelope"></i>
            </Link>
          </li>
          <li>
            <Link
              aria-expanded="false"
              className="dropdown-toggle notification-icon"
              data-bs-toggle="dropdown"
              to="#"
              title="User Guides"
            >
              <i className="fas fa-question-circle"></i>
              <span className="badge">new</span>
            </Link>
            <div className="dropdown-menu notification-menu" role="menu">
              <Link
                className="dropdown-item text-1"
                onClick={handleOpenUrl(
                  `${process.env.PUBLIC_URL}/userGuides/Live Dispatch Web App User Guides.pdf`
                )}
                // to={AppRoutes.userGuides}
                state={{ platform: "web" }}
              >
                <span>
                  <TvIcon style={{ fontSize: 20 }} /> User Guides - Web
                </span>
              </Link>
              <Link
                className="dropdown-item text-1"
                onClick={handleOpenUrl(
                  `${process.env.PUBLIC_URL}/userGuides/Live Dispatch Mobile User Guide.pdf`
                )}
                // to={AppRoutes.userGuides}
                state={{ platform: "mobile" }}
              >
                <span>
                  <PhoneIphoneIcon style={{ fontSize: 20 }} /> User Guides -
                  Mobile
                </span>
              </Link>
            </div>
          </li>
        </ul>
        <span className="separator" />
        <AppSelect
          className="search"
          clearable={false}
          itemLabelProperty="displayName"
          items={branches}
          itemValueProperty="id"
          onChange={handleSwitchBranch}
          placeholder="Switch branch"
          searchable
          value={currentClient?.id}
        />
        {/* {branches &&
            branches?.map((branch, index) => (
              <option key={`${branch.guid} ${index}`} value={branch.id}>
                {branch.displayName}
              </option>
            ))}
        </AppSelect> */}
        <span className="separator" />
        <UserBox />
      </div>
      {!!showAlert && !isMobile && (
        <Alert
          onClose={handleHideAlert}
          severity={ThemeColorsEnum.warning}
          style={{
            backgroundColor: "white",
            float: "left",
            left: 200,
            position: "absolute",
          }}
          className="clearfix"
        >
          You are viewing Live Dispatch v3. If you still want the old version{" "}
          <a
            href={`${
              process.env.REACT_APP_V2
            }?access_token=${Services.localStorage.getProperty(
              LocalStorageKeys.accessToken
            )}`}
            rel="noreferrer"
            target="_blank"
          >
            click here
          </a>
          .
        </Alert>
      )}
    </header>
  );
};

export default HeaderNav;
