import * as Yup from "yup";

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { ClientTypesEnum, ThemeColorsEnum } from "../../constants/enums";
import { AuthContext, LoadingContext } from "../../context/contextProvider";

import { useNavigate } from "react-router-dom";
import Card from "../../components/cards/Card";
import AppForm from "../../components/forms/Form";
import FormCheckboxField from "../../components/forms/FormCheckboxField";
import FormDatePickerField from "../../components/forms/FormDatePickerField";
import FormSelectField from "../../components/forms/FormSelectField";
import FormTextField from "../../components/forms/FormTextField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import Schemas from "../../constants/schemas";
import { LocalStorageKeys } from "../../constants/storageKeys";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import Page from "../Page";

const validationSchema = Yup.object().shape({
  city: Yup.string().min(1).required().label("City"),
  name: Yup.string().min(1).required().label("Name"),
  postalCode: Yup.string().max(6).label("Postal/Zip"),
  displayName: Yup.string().min(1).required().label("Branch name"),
  phone: Schemas.fields.phoneNumber.required(),
  typeId: Yup.number()
    .label("Service type")
    .typeError("Service type is a required field"),
});

const BranchFormPage = ({ breadcrumbs, branch = {}, title, ...otherProps }) => {
  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [regions, setRegions] = useState([]);

  const [initialValues, setInitialValues] = useState({
    address1: branch?.address1 ?? "",
    address2: branch?.address2 ?? "",
    city: branch?.city ?? "",
    country: branch?.country ?? "",
    displayName: branch?.displayName ?? "",
    email: branch?.email ?? "",
    expiryDate: branch?.expiryDate ?? "",
    fee: branch?.fee ?? "",
    isDemo: branch?.isDemo,
    leadEmail: branch?.leadEmail ?? "",
    name: branch?.name ?? "",
    notes: branch?.notes ?? "",
    phone: branch?.phone ?? "",
    parentClientId: branch?.parentClientId ?? "",
    postalCode: branch?.postalCode ?? "",
    province: branch?.province ?? "",
    typeId: branch?.typeId ?? "",
  });

  const clientTypes = [
    {
      id: ClientTypesEnum.petroleum,
      name: "Petroleum",
    },
    {
      id: ClientTypesEnum.propane,
      name: "Propane",
    },
    {
      id: ClientTypesEnum.service,
      name: "Other/Services",
    },
  ];

  const userInfo = useCurrentUserInfo();
  const { busy, setBusy, loading, setLoading } = useContext(LoadingContext);
  const { setCurrentClient, setCurrentUser } = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    initAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCompaniesAsync = async () => {
    const response = await Services.api.clients.getCompanies();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the companies list",
        ThemeColorsEnum.error
      );

    setCompanies(response.data);
  };

  const getCountriesAsync = async () => {
    const response = await Services.api.home.getCountries();

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the countries list",
        ThemeColorsEnum.error
      );

    setCountries(response.data);
  };

  const getRegionsAsync = async (country) => {
    if (!country) return;

    const response = await Services.api.home.getRegions(country);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the regions list",
        ThemeColorsEnum.error
      );

    setRegions(response.data);
  };

  const handleCountryChange = async (selected) => {
    await getRegionsAsync(selected.value);
  };

  const handleParentCompanyChange = async (selected, { setFieldValue }) => {
    setFieldValue("name", selected?.name ?? "");
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const response = await Services.api.clients.save({ ...branch, ...values });
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save the branch",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast("Saved", ThemeColorsEnum.success);

    // reset the user so that the branches dropdown refreshes too
    const userResponse = await Services.api.account.me();
    if (userResponse.ok) {
      setCurrentUser(
        Services.localStorage.getProperty(LocalStorageKeys.currentUser)
      );
      setCurrentClient(
        Services.localStorage.getProperty(LocalStorageKeys.currentClient)
      );
    }

    navigate(AppRoutes.branches);
  };

  const initAsync = async () => {
    setLoading(true);
    if (userInfo.isSuperAdmin) await getCompaniesAsync();
    await getCountriesAsync();
    await getRegionsAsync(branch?.country);
    setLoading(false);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <AppForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Card visible={userInfo.isSuperAdmin}>
          <Card.Header>
            <h2 className="card-title">Internal North Star Systems Use Only</h2>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col lg={4}>
                <FormSelectField
                  info="Leave it blank if it's a brand new company"
                  itemLabelProperty="name"
                  items={companies}
                  itemValueProperty="id"
                  label="Parent Company"
                  name="parentClientId"
                  onChange={handleParentCompanyChange}
                  searchable
                />
              </Col>
            </Row>
            <Row>
              <Col lg={3}>
                <FormDatePickerField
                  clearable
                  label="Expiry Date"
                  name="expiryDate"
                  placeholder="Select a date"
                />
              </Col>
              <Col lg={3}>
                <FormCheckboxField
                  description=""
                  info="Remember to uncheck it once the client fully enrolls"
                  label="Trial or Demo"
                  name="isDemo"
                />
              </Col>
              <Col lg={3}>
                <FormTextField label="NSS Email" name="leadEmail" />
              </Col>
              <Col lg={3}>
                <FormTextField
                  label="Price per User per Month"
                  name="fee"
                  type="number"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Row>
              <Col lg={3}>
                <FormTextField
                  label="Name"
                  name="name"
                  required
                  readOnly={!userInfo.isSuperAdmin}
                />
              </Col>
              <Col lg={3}>
                <FormTextField
                  info="This text shows in the dropdown"
                  label="Branch Name"
                  name="displayName"
                  required
                />
              </Col>
              <Col lg={3}>
                <FormTextField label="Phone" name="phone" required />
              </Col>
              <Col lg={3}>
                <FormTextField label="Email" name="email" />
              </Col>
              <Col lg={3}>
                <FormSelectField
                  itemLabelProperty="name"
                  items={clientTypes}
                  itemValueProperty="id"
                  label="Service Type"
                  name="typeId"
                  required
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormTextField label="Address 1" name="address1" />
              </Col>
              <Col lg={3}>
                <FormTextField label="Address 2" name="address2" />
              </Col>
              <Col lg={3}>
                <FormTextField label="City" name="city" required />
              </Col>
              <Col lg={3}>
                <FormTextField label="Postal/Zip" name="postalCode" />
              </Col>
              <Col lg={3}>
                <FormSelectField
                  itemLabelProperty="name"
                  items={countries}
                  itemValueProperty="value"
                  label="Country"
                  name="country"
                  onChange={handleCountryChange}
                  searchable
                />
              </Col>
              <Col lg={3}>
                <FormSelectField
                  itemLabelProperty="name"
                  items={regions}
                  itemValueProperty="value"
                  label="Province/State"
                  name="province"
                  searchable
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <div className="form-group mb-0 fixed-to-bottom">
          <Row>
            <Col lg={2} md={4} sm={6}>
              <SubmitButton className="btn-block" loading={busy}>
                Save
              </SubmitButton>
            </Col>
          </Row>
        </div>
      </AppForm>
    </Page>
  );
};

export default BranchFormPage;
