import { Link } from "react-router-dom";
import React from "react";
import { ThemeColorsEnum } from "../../constants/enums";

const Button = ({
  className,
  color = ThemeColorsEnum.primary,
  icon,
  IconComponent,
  onClick = () => {},
  title,
  to = "#",
  visible = true,
  ...otherProps
}) => {
  if (!visible) return null;

  let classes = `mb-1 mt-1 me-1 btn btn-${color}`;
  if (className) classes = `${classes} ${className}`;

  return (
    <Link className={classes} onClick={onClick} to={to} {...otherProps}>
      {(icon && <i className={`${icon} me-2`}></i>) ||
        (IconComponent && <IconComponent className="me-2" />)}
      {title}
    </Link>
  );
};

export default Button;
