import * as Yup from "yup";

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  CustomerColumnsEnum,
  SortDirections,
  ThemeColorsEnum,
} from "../../constants/enums";
import { Search, SearchFilterRequest } from "../../models/searchFilterRequest";

import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import CircleButton from "../../components/buttons/CircleButton";
import Card from "../../components/cards/Card";
import AppForm from "../../components/forms/Form";
import FormCheckboxField from "../../components/forms/FormCheckboxField";
import FormCustomerSelect from "../../components/forms/FormCustomerSelect";
import FormSelectField from "../../components/forms/FormSelectField";
import FormTextField from "../../components/forms/FormTextField";
import SubmitButton from "../../components/forms/SubmitButton";
import { AppRoutes } from "../../constants/appRoutes";
import { LoadingContext } from "../../context/contextProvider";
import Services from "../../services/services";
import Page from "../Page";

const validationSchema = Yup.object().shape({
  customerId: Yup.number()
    .required()
    .label("Customer")
    .typeError("Customer is a required field"),
  name: Yup.string().min(1).required().label("Name"),
  productId: Yup.string().min(1).required().label("Product"),
  uniqueId: Yup.string().min(1).required().label("Tattle ID"),
});

const TattleFormPage = ({ breadcrumbs, tattle = {}, title, ...otherProps }) => {
  const [customers, setCustomers] = useState([]);
  const [customerPredictions, setCustomerPredictions] = useState([]);
  const [products, setProducts] = useState([]);
  const [tattleId, setTattleId] = useState(tattle?.uniqueId);
  const [initialValues, setInitialValues] = useState({
    customerId: tattle?.customerId ?? "",
    isDirectDrop: tattle?.isDirectDrop ?? false,
    latitude: tattle?.latitude ?? "",
    longitude: tattle?.longitude ?? "",
    name: tattle?.name ?? "",
    notes: tattle?.notes ?? "",
    productId: tattle?.productId,
    uniqueId: tattle?.uniqueId ?? "",
  });

  const { busy, setLoading } = useContext(LoadingContext);

  const navigate = useNavigate();

  useEffect(() => {
    initAsync();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (tattle?.customerId > 0)
      setCustomers([
        {
          id: tattle.customerId,
          company: tattle.customerDisplayName,
        },
      ]);

    // if (tattle?.productId > 0)
    //   setProducts([
    //     {
    //       id: tattle.productId,
    //     },
    //   ]);
  }, [tattle]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getCustomersAsync = async () => {
  //   const response = await Services.api.customers.getBasicList();

  //   if (!response.ok)
  //     return Services.utility.toast(
  //       "Could not download the customers",
  //       ThemeColorsEnum.error
  //     );

  //   setCustomers(response.data);
  // };

  const getProductsAsync = async () => {
    const response = await Services.api.products.all(tattle?.clientId);

    if (!response.ok)
      return Services.utility.toast(
        "Could not download the products",
        ThemeColorsEnum.error
      );

    setProducts(response.data);
  };

  const handleCustomerInputChange = async (text) => {
    if (!text) return setCustomerPredictions(customers);
    text = text.toLowerCase();

    // filter
    const filter = new SearchFilterRequest();
    filter.search = new Search(text);
    filter.start = 0;
    filter.length = 20;
    filter.order[0].column = CustomerColumnsEnum.company;
    filter.order[0].dir = SortDirections.ascending;

    // setLoading(true);
    const response = await Services.api.customers.getCustomers(
      filter,
      tattle?.clientId
    );
    // setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not get the customers",
        ThemeColorsEnum.error
      );

    setCustomerPredictions(response.data?.data);
    // const matches = Services.utility.getCustomerPredictions(customers, text);
    // setCustomerPredictions(matches);
  };

  const handleCustomerInputChangeWithDebounce = useMemo(
    () =>
      debounce((text) => {
        handleCustomerInputChange(text?.trim());
      }, 500),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleSearchTattle = async () => {
    setLoading(true);
    const response = await Services.api.tattles.getTattleInfo(tattleId);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not fetch the device info",
        ThemeColorsEnum.error
      );

    if (!response.data?.item1)
      return Services.utility.toast(
        "Device not found",
        ThemeColorsEnum.warning
      );

    setInitialValues({
      ...initialValues,
      name: response.data?.item1?.name ?? "",
      latitude: response.data?.item1?.latitude ?? "",
      longitude: response.data?.item1?.longitude ?? "",
      uniqueId: response.data?.item1?.tattleId,
    });
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    const response = await Services.api.tattles.save({ ...tattle, ...values });
    setLoading(false);


    if (!response.ok)
      return Services.utility.toast(
        "Could not save the tattle",
        ThemeColorsEnum.error,
        response
      );

    Services.utility.toast("Saved", ThemeColorsEnum.success);
    navigate(AppRoutes.tattles);
  };

  const initAsync = async () => {
    setLoading(true);
    setCustomerPredictions([]);
    await getProductsAsync();
    setLoading(false);
  };

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <Card>
        <Card.Body>
          <AppForm
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Row>
              <Col lg={4}>
                <Row>
                  <Col lg={10}>
                    <FormTextField
                      label="Tattle ID"
                      name="uniqueId"
                      onChange={setTattleId}
                      required
                    />
                  </Col>
                  <Col lg={2}>
                    <CircleButton
                      style={{
                        top: "50%",
                        position: "absolute",
                        transform: "translateY(-50%)",
                      }}
                      color={ThemeColorsEnum.primary}
                      size={40}
                      // style={{ backgroundColor: "#eaeaea" }}
                      IconComponent={() => (
                        <SearchIcon
                          className="cur-pointer"
                          style={{ fontSize: 20 }}
                        />
                      )}
                      onClick={handleSearchTattle}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <FormCustomerSelect
                  allItems={customers}
                  autocomplete
                  itemLabelProperty="company"
                  items={customerPredictions}
                  itemValueProperty="id"
                  label="Customer"
                  name="customerId"
                  onInputChange={handleCustomerInputChangeWithDebounce}
                  placeholder="Search by anything..."
                  required
                  searchable
                />
              </Col>
              <Col lg={4}>
                <FormTextField label="Name" name="name" required />
              </Col>
              <Col lg={4}>
                <FormSelectField
                  itemLabelProperty="shortName"
                  items={products}
                  itemValueProperty="id"
                  label="Product"
                  name="productId"
                  required
                  searchable
                />
                <FormCheckboxField
                  description="This is for Direct Drops"
                  name="isDirectDrop"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <FormTextField label="Latitude" name="latitude" />
              </Col>
              <Col lg={4}>
                <FormTextField label="Longitude" name="longitude" />
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <FormTextField
                  as="textarea"
                  className="custom-text-area"
                  label="Notes"
                  name="notes"
                />
              </Col>
            </Row>

            <div className="form-group mb-0 fixed-to-bottom">
              <Row>
                <Col lg={2} md={4} sm={6}>
                  <SubmitButton className="btn-block" loading={busy}>
                    Save
                  </SubmitButton>
                </Col>
              </Row>
            </div>
          </AppForm>
        </Card.Body>
      </Card>
    </Page>
  );
};

export default TattleFormPage;
