import { Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { ThemeColorsEnum, UnitOfMeasurementsEnum } from "../../constants/enums";

import AddIcon from "@mui/icons-material/Add";
import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import { AuthContext } from "../../context/contextProvider";
import CircleButton from "../buttons/CircleButton";
import FormFieldContainer from "./FormFieldContainer";
import WorkOrderProduct from "./WorkOrderProduct";
import { useFormikContext } from "formik";

const FormWorkOrderProducts = ({
  availableProducts,
  completing,
  directDrop,
  errorFieldName,
  label = null,
  LabelComponent,
  name,
  onSelectProduct = () => { },
  required,
  visible = true,
  ...otherProps
}) => {
  const formik = useFormikContext();
  const { errors, handleBlur, setFieldValue, touched, values } = formik;

  const [productsList, setProductsList] = useState(values[name] || []);

  const { branchSettings } = useContext(AuthContext);

  useEffect(() => {
    if (values[name] !== productsList){
      setProductsList(values[name]);
    }
  }, [values[name]]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleAddProduct = () => {
    setTimeout(() => {// long running task, yield it
      setProductsList([
        ...productsList,
        {
          id: 0,
          currentPercentage: "",
          productId: 0,
          quantity: directDrop ? branchSettings?.directDropDefaultQuantity : "",
          unitOfMeasurement: UnitOfMeasurementsEnum.liters,
          fill: false,
        },
      ]);
    }, 0);
  }

  const handleProductRemoved = (ArgIndex) => {
    if (ArgIndex > -1) {
      const updatedProductList = [...productsList.slice(0, ArgIndex), ...productsList.slice(ArgIndex + 1)];
      values[name] = [...values[name].slice(0, ArgIndex), ...values[name].slice(ArgIndex + 1)];
      setProductsList(updatedProductList);
      setFieldValue(name, updatedProductList);
    }
  };

  const handleSelectProduct = (index) => (product) => {
    setFieldValue(`${name}[${index}]`, product);
    onSelectProduct(product, formik);
  };

  const calculateProductList = () => {
    return productsList?.map((workOrderProduct, index) => (
      <WorkOrderProduct
        className="mb-1"
        completing={completing}
        directDrop={directDrop}
        key={index}
        name={`${name}[${index}]`}
        onBlur={() => handleBlur(name)}
        onSelected={handleSelectProduct(index)}
        products={availableProducts}
        workOrderProduct={workOrderProduct}
        onProductRemoved={() => handleProductRemoved(index)}
        ListIndex={index}
      />
    ));
  }

  if (!visible) return null;

  return (
    <FormFieldContainer>
      <Row>
        <Col lg={10}>
          <AppFormLabel label={label} required={required} visible={!!label} />
          {LabelComponent}
        </Col>
        <Col lg={2} className="text-right">
          <CircleButton
            color={ThemeColorsEnum.quaternary}
            size={25}
            // style={{ backgroundColor: "#eaeaea" }}
            IconComponent={() => (
              <AddIcon className="cur-pointer" style={{ fontSize: 20 }} />
            )}
            onClick={handleAddProduct}
          />
        </Col>
      </Row>
      {calculateProductList()}
      <Row>
        <Col sm={8}>
          <AppErrorMessage
            error={errors[errorFieldName]}
            visible={!!touched[name] && !!errors[errorFieldName]}
          />
        </Col>
      </Row>
    </FormFieldContainer>
  );
};

export default FormWorkOrderProducts;
