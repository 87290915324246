import React, { useState } from "react";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ElementSize } from "../../constants/enums";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { useEffect } from "react";

const AppDatePicker = ({
  clearable,
  name,
  onChange = () => {},
  placeholder,
  size = ElementSize.md,
  value,
  ...otherProps
}) => {
  const [date, setDate] = useState(value);

  useEffect(() => {
    setDate(value);
  }, [value]);

  const handleChange = (dateTime) => {
    setDate(dateTime);
    const date = dayjs(dateTime)?.format("YYYY-MM-DD");  
    onChange(date);
  };

  const handleClear = () => {
    setDate(null);
    onChange(null);
  };

  const renderCustomInput = ({ inputRef, inputProps, InputProps }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#0C2340",
        borderRadius: ".25rem",
        paddingRight: "8px",
      }}
    >
      <Form.Control
        ref={inputRef}
        {...inputProps}
        name={name}
        placeholder={placeholder}
        readOnly
        style={InputProps?.sx}
      ></Form.Control>
      {InputProps?.endAdornment}
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        disableMaskedInput
        //   Style Props --- start
        PaperProps={{
          sx: {
            backgroundColor: "#000000",
            color: "#eeeeee",
            "& .MuiButtonBase-root": {
              //   backgroundColor: "#0C2340",
              color: "#ffffff",
            },
            "& .MuiPickersArrowSwitcher-button": {
              backgroundColor: "#0C2340",
              color: "#ffffff",
            },
            "& .MuiTypography-caption": {
              color: "#eeeeee",
            },
            "& .MuiTypography-caption:first-of-type": {
              color: "red",
            },
            "& .MuiTypography-caption:last-of-type": {
              color: "red",
            },
            "& .MuiPickersDay-root": {
              backgroundColor: "#0C2340",
              color: "#ffffff",
            },
            "& .MuiPickersDay-root:hover": {
              backgroundColor: "#A9C833",
              color: "#ffffff",
            },
            "& .MuiPickersDay-today": {
              backgroundColor: "#7d9425 !important",
              color: "#ffffff",
            },
            "& .Mui-selected": {
              backgroundColor: "#A9C833 !important",
              color: "#ffffff",
            },
          },
        }}
        // PopperProps={
        //   {
        //       sx: { color: "red" },
        //   }
        // }
        InputProps={{
          disableUnderline: true,
          sx: {
            backgroundColor: "#0C2340",
          },
        }}
        OpenPickerButtonProps={{
          sx: { color: "hsl(0, 0%, 80%)" },
        }}
        //   Style Props --- end
        inputFormat="d MMM, yyyy"
        onChange={handleChange}
        // readOnly
        // renderInput={(params) => (
        //   <TextField
        //     {...params}
        //     className="form-control"
        //     aria-readonly="true"
        //     variant="standard"
        //     // inputProps={{ className: "form-control" }}
        //   />
        // )}
        renderInput={renderCustomInput}
        value={date}
      />
      {!!clearable && (
        <div className="text-right">
          <Link onClick={handleClear} to="#">
            Clear
          </Link>
        </div>
      )}
    </LocalizationProvider>
  );
};

export default AppDatePicker;
