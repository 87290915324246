import { useContext, useEffect } from "react";
import AlertBox from "../components/AlertBox";
import { ThemeColorsEnum } from "../constants/enums";
import { LocalStorageKeys } from "../constants/storageKeys";
import { AuthContext } from "../context/contextProvider";
import Services from "../services/services";

const useAlerts = () => {
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (!currentUser) return;
    getLatestAlertAsync();
  }, [currentUser]);

  const getLatestAlertAsync = async () => {
    const response = await Services.api.alerts.latest();

    const lastHiddenAlertId =
      Services.localStorage.getProperty(LocalStorageKeys.hideAlertId) ?? 0;

    if (response.ok && lastHiddenAlertId < response.data?.id)
      Services.utility.toast(
        <AlertBox alert={response.data} />,
        response.data?.type ?? ThemeColorsEnum.default,
        null,
        true,
        {
          position: "top-center",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
  };
};

export default useAlerts;
