import { InfoWindow, Marker } from "@react-google-maps/api";
import React, { useState } from "react";

import Services from "../../services/services";

const AppMarker = ({
  icon,
  info,
  onClick = () => {},
  order,
  position,
  visible = true,
  ...otherProps
}) => {
  const [selectedCenter, setSelectedCenter] = useState(null);

  if (!visible) return null;

  const handleMarkerClick = () => {
    setSelectedCenter(position);
    onClick(position);
  };

  icon =
    !!icon || !!order
      ? new window.google.maps.MarkerImage(
          icon ?? Services.workOrders.getMarker(order),
          null /* size is determined at runtime */,
          null /* origin is 0,0 */,
          null /* anchor is bottom center of the scaled image */,
          new window.google.maps.Size(32, 32)
        )
      : null;

  return (
    <Marker
      icon={icon}
      onClick={handleMarkerClick}
      position={position}
      {...otherProps}
    >
      {!!selectedCenter && info && (
        <InfoWindow
          onCloseClick={() => setSelectedCenter(null)}
          position={{
            lat: selectedCenter.lat,
            lng: selectedCenter.lng,
          }}
        >
          {info}
        </InfoWindow>
      )}
    </Marker>
  );
};

export default AppMarker;
