const account = {
  changePassword: "Account/ChangePassword", // unused
  me: "Account/UserInfo?include=Clients",
  logOut: "Account/LogOut",
  resetPassword: "/Account/ResetPassword",
  sendResetPasswordLink: "Account/SendResetPasswordLink",
};

const alerts = {
  create: "Alerts",
  delete: "Alerts/{id}",
  filter: "Alerts/Filter",
  get: "Alerts/{id}",
  latest: "Alerts/Latest",
  update: "Alerts/{id}",
};

const auth = {
  refreshToken: "Auth/RefreshToken",
  token: "Auth/Token",
};

const billing = {
  billableUsers: "Billing/BillableUsers",
  branchDetails: "Billing/Branches/{id}", // unused
  clientBranches: "Billing/Clients/{id}/Branches", // unused
  enrolledClients: "Billing/Clients",
  trialUsers: "Billing/TrialUsers",
};

const clients = {
  companies: "Clients/Companies",
  create: "Clients",
  delete: "Clients/{id}",
  filter: "Clients/Filter",
  get: "Clients/{id}",
  update: "Clients/{id}",
};

const customers = {
  attachDevice: "Customers/{id}/TattleDevices",
  create: "Customers",
  delete: "Customers/{id}",
  devices: "Customers/{id}/TattleDevices",
  filter: "Customers/Filter?clientId={clientId}",
  get: "Customers/{id}",
  geolocations: "Customers/Geolocations?clientId={clientId}",
  getBasicList: "Customers/GetBasicList?clientId={clientId}",
  // customerLocations
  addLocation: "Customers/{id}/Locations",
  deleteLocation: "Customers/{id}/Locations/{locationId}",
  getLocation: "Customers/{id}/Locations/{locationId}",
  import: "Customers/Import?clientId={clientId}",
  locations: "Customers/{id}/Locations",
  updateLocation: "Customers/{id}/Locations/{locationId}",
  allLocations: "Customers/Locations?clientId={clientId}",
  //
  removeDevice: "Customers/{id}/TattleDevices?tattleDeviceId={tattleDeviceId}",
  saveLocation: "Customers/{id}/SaveLocation",
  update: "Customers/{id}",
};

const home = {
  contact: "Home/Contact",
  countries: "Home/Countries",
  regions: "Home/Regions?country={country}",
  summary: "Home/Summary?clientId={clientId}",
};

const products = {
  all: "Products?clientId={clientId}",
  create: "Products",
  delete: "Products/{id}",
  filter: "Products/Filter?clientId={clientId}",
  get: "Products/{id}",
  update: "Products/{id}",
};

const roles = {
  all: "Roles",
};

const scheduledCheckIns = {
  complete: "ScheduledCheckIns/{id}/Complete",
  filter: "ScheduledCheckIns/Filter?clientId={clientId}",
};

const serviceCategories = {
  all: "ServiceCategories?clientId={clientId}",
  create: "ServiceCategories",
  delete: "ServiceCategories/{id}",
  get: "ServiceCategories/{id}",
  filter: "ServiceCategories/Filter?clientId={clientId}",
  update: "ServiceCategories/{id}",
};

const serviceOrders = {
  assign: "ServiceOrders/Assign/{id}?userId={userId}",
  complete: "ServiceOrders/Complete/{id}?sync={sync}",
  create: "ServiceOrders?sync={sync}",
  delete: "ServiceOrders/{id}?sync={sync}",
  get: "ServiceOrders/{id}",
  filter: "ServiceOrders/Filter?clientId={clientId}",
  geolocations: "ServiceOrders/Geolocations?clientId={clientId}",
  isDuplicate:
    "ServiceOrders/IsDuplicate?customerId={customerId}&dueDate={dueDate}&serviceOrderId={serviceOrderId}",
  move: "ServiceOrders/Move/{id}?newClientId={newClientId}",
  start: "ServiceOrders/Start/{id}?sync={sync}",
  undoStart: "ServiceOrders/UndoStart/{id}?sync={sync}",
  update: "ServiceOrders/{id}?sync={sync}",
};

const settings = {
  getClientModules: "Settings/Modules/{clientId}",
  getClientSettings: "Settings/Clients/{clientId}",
  getTimezones: "Settings/Timezones",
  getUserSettings: "Settings/Users/{userId}",
  saveUserSettings: "Settings/Users/{userId}",
  saveClientModules: "Settings/Modules/{clientId}",
  saveClientSettings: "Settings/Clients/{clientId}",
};

const tattles = {
  create: "Tattles",
  delete: "Tattles/{id}",
  deviceInfo: "Tattles/{uniqueId}/Info",
  filter: "Tattles/Filter?clientId={clientId}",
  get: "Tattles/{id}",
  nearbyDevices:
    "Tattles/FindNearbyDevices?latitude={latitude}&longitude={longitude}&range={range}",
  update: "Tattles/{id}",
};

const trips = {
  addCheckIn: "WATravels/{id}/CheckIns",
  complete: "WATravels/Complete/{id}",
  create: "WATravels",
  delete: "WATravels/{id}",
  filter: "WATravels/Filter?clientId={clientId}",
  start: "WATravels/Start/{id}",
  update: "WATravels/{id}",
};

const trucks = {
  all: "Trucks?clientId={clientId}",
  create: "Trucks",
  delete: "Trucks/{id}",
  fillUp: "Trucks/FillUp/{id}",
  filter: "Trucks/Filter?clientId={clientId}",
  get: "Trucks/{id}",
  update: "Trucks/{id}",
};

const users = {
  addDevice: "Users/AddDevice",
  attachToBranch: "Users/{id}/AttachToClient?newClientId={newClientId}",
  availableBranches: "Users/{id}/AttachableClients?clientId={clientId}",
  create: "Users",
  delete: "Users/{id}?clientId={clientId}",
  filter: "Users/Filter?clientId={clientId}",
  geolocations: "Users/Geolocations?clientId={clientId}",
  get: "Users/{id}",
  getByRole: "Users?clientId={clientId}&role={role}",
  lastKnownLocation: "Users/{id}/LastKnownLocation?clientId={clientId}",
  reactivate: "Users/{id}/Reactivate?clientId={clientId}",
  update: "Users/{id}",
};

const workOrders = {
  assign: "WorkOrders/Assign/{id}?userId={userId}",
  complete: "WorkOrders/Complete/{id}?sync={sync}",
  create: "WorkOrders?sync={sync}",
  delete: "WorkOrders/{id}?sync={sync}",
  filter: "WorkOrders/Filter?clientId={clientId}",
  geolocations:
    "WorkOrders/Geolocations?clientId={clientId}&isDirectDrop={isDirectDrop}",
  get: "WorkOrders/{id}",
  isDuplicate:
    "WorkOrders/IsDuplicate?customerId={customerId}&dueDate={dueDate}&workOrderId={workOrderId}",
  move: "WorkOrders/Move/{id}?newClientId={newClientId}",
  paymentMethods: "WorkOrders/PaymentOptions",
  start: "WorkOrders/Start/{id}?sync={sync}",
  undoStart: "WorkOrders/UndoStart/{id}?sync={sync}",
  update: "WorkOrders/{id}?sync={sync}",
};

const scheduledOrders = {
  create: "ScheduledOrders",
  update: "ScheduledOrders/{id}?sync={sync}",
  get: "ScheduledOrders/{id}",
  delete: "ScheduledOrders/{id}",
  filter: "ScheduledOrders/Filter?clientId={clientId}",
  checkWorkOrderSchedules: "ScheduledOrders/CheckWorkOrderSchedules",
};

//-----------------------------------------//
const Endpoints = {
  account,
  alerts,
  auth,
  billing,
  clients,
  customers,
  home,
  products,
  roles,
  scheduledCheckIns,
  scheduledOrders,
  serviceCategories,
  serviceOrders,
  settings,
  tattles,
  trips,
  trucks,
  users,
  workOrders,
};

export default Endpoints;
//-----------------------------------------//
