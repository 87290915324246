import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const attachToBranch = async (id, branchId) => {
  const url = Endpoints.users.attachToBranch
    .replace("{id}", id)
    .replace("{newClientId}", branchId);
  const response = await httpService.post(url);

  return response;
};

const availableBranches = async (id) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;

  const url = Endpoints.users.availableBranches
    .replace("{id}", id)
    .replace("{clientId}", clientId);
  const response = await httpService.get(url);

  return response;
};

const create = async (payload) => {
  const url = Endpoints.users.create;
  const response = await httpService.post(url, payload);

  return response;
};

const deleteUser = async (id, fromAllBranches = false) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;

  let url = Endpoints.users.delete
    .replace("{id}", id)
    .replace("{clientId}", clientId);
  if (fromAllBranches) url = `${url}&fromAllBranches=${fromAllBranches}`;

  const response = await httpService.delete(url);

  return response;
};

const get = async (id) => {
  const url = Endpoints.users.get.replace("{id}", id);
  const response = await httpService.get(url);

  return response;
};

const getByRole = async (roleName) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.users.getByRole
    .replace("{clientId}", clientId)
    .replace("{role}", roleName ?? "");

  const response = await httpService.get(url);

  return response;
};

const getLastKnownLocation = async (id) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.users.lastKnownLocation.replace("{id}", id).replace("{clientId}", clientId);
  const response = await httpService.get(url);

  return response;
};

const getLastKnownLocations = async (roles) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;

  let url = Endpoints.users.geolocations.replace("{clientId}", clientId);
  roles?.map((role) => (url = `${url}&roles=${role}`));

  const response = await httpService.get(url);

  return response;
};

/**
 *
 * @param {SearchFilterRequest} filter
 * @returns
 */
const getUsers = async (filter) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;
  const url = Endpoints.users.filter.replace("{clientId}", clientId);
  const response = await httpService.post(url, filter);

  return response;
};

const reactivate = async (id) => {
  const clientId = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  )?.id;

  const url = Endpoints.users.reactivate
    .replace("{id}", id)
    .replace("{clientId}", clientId);
  const response = await httpService.post(url);

  return response;
};

const save = async (payload) => {
  const client = localStorageService.getProperty(
    LocalStorageKeys.currentClient
  );

  payload = {
    ...payload,
    clientId: client?.id,
    clientGuid: client?.guid,
    clients: null,
    devices: null,
    primaryClient: null,
  };
  return payload?.id ? await update(payload) : await create(payload);
};

const update = async (payload) => {
  const url = Endpoints.users.update.replace("{id}", payload?.id);
  const response = await httpService.put(url, payload);

  return response;
};

//-----------------------------------------//
const usersApiService = {
  attachToBranch,
  availableBranches,
  delete: deleteUser,
  get,
  getByRole,
  getLastKnownLocation,
  getLastKnownLocations,
  getUsers,
  reactivate,
  save,
};

export default usersApiService;
//-----------------------------------------//
