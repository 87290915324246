import React from "react";
import { useNavigate } from "react-router-dom";
import UserForm from "../../components/forms/custom/UserForm";
import { AppRoutes } from "../../constants/appRoutes";
import Page from "../Page";

const UserFormPage = ({
  breadcrumbs,
  user = {},
  title,
  validationSchema,
  ...otherProps
}) => {
  const navigate = useNavigate();

  return (
    <Page breadcrumbs={breadcrumbs} title={title}>
      <UserForm
        onSave={() => navigate(AppRoutes.users)}
        user={user}
        validationSchema={validationSchema}
      />
    </Page>
  );
};

export default UserFormPage;
