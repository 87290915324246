import AddIcon from "@mui/icons-material/Add";
import { AppRoutes } from "../../constants/appRoutes";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ProductFormPage from "./ProductFormPage";
import PropaneIcon from "@mui/icons-material/Propane";
import React from "react";

const breadcrumbs = [
  {
    IconComponent: DashboardIcon,
    title: "Dashboard",
    to: AppRoutes.dashboard,
  },
  {
    IconComponent: PropaneIcon,
    title: "Products",
    to: AppRoutes.products,
  },
  {
    IconComponent: AddIcon,
    title: "New",
  },
];

const ProductCreatePage = (props) => {
  return <ProductFormPage breadcrumbs={breadcrumbs} title="Products - New" />;
};

export default ProductCreatePage;
