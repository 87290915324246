import Endpoints from "../../constants/endpoints";
import { LocalStorageKeys } from "../../constants/storageKeys";
import httpService from "../httpService";
import localStorageService from "../localStorageService";

const changePassword = async (payload) => {
  const response = await httpService.post(
    Endpoints.account.changePassword,
    payload
  );

  return response;
};

const me = async () => {
  const response = await httpService.get(Endpoints.account.me);

  if (response.ok) {
    localStorageService.setProperty(
      LocalStorageKeys.currentUser,
      response.data
    );

    if (!localStorageService.getProperty(LocalStorageKeys.currentClient))
      localStorageService.setProperty(
        LocalStorageKeys.currentClient,
        response.data?.primaryClient
      );
  }

  return response;
};

const resetPassword = async (payload) => {
  const response = await httpService.post(
    Endpoints.account.resetPassword,
    payload
  );

  return response;
};

const sendResetPasswordLink = async (username) => {
  const response = await httpService.post(
    Endpoints.account.sendResetPasswordLink,
    { username: username }
  );

  return response;
};

//-----------------------------------------//
const accountApiService = {
  changePassword,
  me,
  resetPassword,
  sendResetPasswordLink,
};

export default accountApiService;
//-----------------------------------------//
