import { BillableUserColumnsEnum } from "../constants/enums";
import "../extensions/number.extension";
import Services from "../services/services";

const useBillableUsersDataTable = (showExpiryDate = false) => {
  const columns = [
    {
      header: {
        id: BillableUserColumnsEnum.userName,
        name: "userName",
        text: "Username",
        sortable: false,
      },
      cell: (row) => row.userName,
      dataTitle: "Username",
      className: "nowrap",
    },
    {
      header: {
        id: BillableUserColumnsEnum.fullName,
        name: "name",
        text: "Name",
        sortable: false,
      },
      cell: (row) => row.fullName,
      dataTitle: "Name",
      className: "nowrap",
    },
    {
      header: {
        id: BillableUserColumnsEnum.branchName,
        name: "clientName",
        text: "Branch",
        sortable: false,
      },
      cell: (row) => row.branchName,
      dataTitle: "Branch",
      className: "nowrap",
    },
    {
      header: {
        id: BillableUserColumnsEnum.addedOn,
        name: "createdDate",
        text: "Added on",
        sortable: false,
      },
      cell: (row) =>
        row.createdDate ? Services.utility.formatFullDate(row.createdDate) : "",
      dataTitle: "Added on",
      className: "nowrap",
    },
    ...(showExpiryDate
      ? [
          {
            header: {
              id: BillableUserColumnsEnum.expiresOn,
              name: "expiryDate",
              text: "Expires on",
              sortable: false,
            },
            cell: (row) =>
              row.expiryDate
                ? Services.utility.formatFullDate(row.expiryDate)
                : "",
            dataTitle: "Expires on",
            className: "nowrap",
          },
        ]
      : []),
    {
      header: {
        id: BillableUserColumnsEnum.fee,
        name: "fee",
        text: "Fee",
        sortable: false,
      },
      cell: (row) => row.fee.toCurrency(),
      dataTitle: "Fee",
      className: "nowrap",
    },
  ];

  return { columns };
};

export default useBillableUsersDataTable;
