import React, { useState } from "react";

import { Link } from "react-router-dom";
import NavLink from "./NavLink";
import { ThemeColorsEnum } from "../../constants/enums";

const NavDropdown = ({
  active,
  badgeColor = ThemeColorsEnum.primary,
  badgeTitle,
  children: childItems,
  color,
  icon,
  IconComponent,
  title,
  visible = true,
  ...otherProps
}) => {
  //   let active = false;
  //   const subComponentList = Object.keys(NavDropdown);
  //   const subComponents = subComponentList?.map((key) => {
  //     return React.Children.map(children, (child) => {
  //       if (child.type.name === key) {
  //         if (child.props.active) active = true;
  //         return child;
  //       }

  //       return null;
  //     });
  //   });
  if (!visible) return null;

  return (
    <NavLink
      active={active}
      badgeColor={badgeColor}
      badgeTitle={badgeTitle}
      color={color}
      icon={icon}
      IconComponent={IconComponent}
      parent
      title={title}
    >
      <ul className="nav nav-children">{childItems}</ul>
    </NavLink>
  );
};

export default NavDropdown;
