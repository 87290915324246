import {
  ServiceCategoryColumnsEnum,
  ThemeColorsEnum,
} from "../constants/enums";
import { LoadingContext, ModalContext } from "../context/contextProvider";

import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import DropdownSelect from "../components/forms/DropdownSelect";
import { AppRoutes } from "../constants/appRoutes";
import Services from "../services/services";

const useCategoriesDataTable = (onDeleted = () => {}) => {
  const navigate = useNavigate();

  const { setLoading } = useContext(LoadingContext);
  const { showConfirmation } = useContext(ModalContext);

  const handleDelete = async (category) => {
    setLoading(true);
    const response = await Services.api.serviceCategories.delete(category.id);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not delete the category",
        ThemeColorsEnum.error
      );

    Services.utility.toast("Category deleted", ThemeColorsEnum.success);
    onDeleted(category);
  };

  const menuItems = (row) => [
    // Edit
    {
      // href: AppRoutes.products_edit.replace(":id", row.id),
      onClick: () =>
        navigate(AppRoutes.serviceOrderCategories_edit.replace(":id", row.id), {
          state: { product: row },
        }),
      title: "Edit",
    },
    { type: "divider" },
    // Delete
    {
      className: "text-danger",
      onClick: () => {
        showConfirmation(
          "Confirmation",
          "Do you really want to delete this category?",
          [
            {
              color: ThemeColorsEnum.quaternary,
              title: "Cancel",
            },
            {
              color: ThemeColorsEnum.danger,
              onClick: () => handleDelete(row),
              title: "Delete",
            },
          ]
        );
      },
      title: "Delete",
    },
  ];

  const columns = [
    {
      header: {
        className: "col-menu",
        id: ServiceCategoryColumnsEnum.actionButton,
        name: "id",
      },
      cell: (row) => (
        <DropdownSelect
          color={ThemeColorsEnum.quaternary}
          fixedTitle
          items={menuItems(row)}
          itemLabelProperty="title"
          itemValueProperty="title"
          size="sm"
          style={{ width: "auto" }}
          title={<i className="fa fa-bars" />}
          // title={<MenuIcon />}
        />
      ),
      dataTitle: "Menu",
      className: "nowrap",
    },
    {
      header: {
        id: ServiceCategoryColumnsEnum.name,
        name: "name",
        text: "Name",
        sortable: true,
      },
      cell: (row) => row.name,
      dataTitle: "Name",
      className: "nowrap",
    },
    {
      header: {
        id: ServiceCategoryColumnsEnum.shortName,
        name: "shortName",
        text: "Short Name",
        sortable: true,
      },
      cell: (row) => row.shortName,
      dataTitle: "Short Name",
      className: "nowrap",
    },
    {
      header: {
        id: ServiceCategoryColumnsEnum.notes,
        name: "notes",
        text: "Description",
        sortable: true,
      },
      cell: (row) => row.notes,
      dataTitle: "Description",
      className: "nowrap",
    },
    {
      header: {
        id: ServiceCategoryColumnsEnum.serviceOrdersCount,
        name: "serviceOrdersCount",
        text: "Orders",
        sortable: true,
      },
      cell: (row) => row.serviceOrdersCount,
      dataTitle: "Orders",
      className: "nowrap",
    },
  ];

  return { columns };
};

export default useCategoriesDataTable;
