import React, { useState } from "react";
import { useField, useFormikContext } from "formik";

import AppDatePicker from "./DatePicker";
import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import { ElementSize } from "../../constants/enums";
import FormFieldContainer from "./FormFieldContainer";

const FormDatePickerField = ({
  clearable,
  disabled = false,
  icon,
  label,
  LabelComponent,
  // name,
  onBlur = () => {},
  onChange = () => {},
  onIconClick = () => {},
  placeholder,
  required,
  size = ElementSize.md,
  visible = true,
  ...otherProps
}) => {
  const formik = useFormikContext();
  const [field] = useField(otherProps); // extracts name and value
  const [datePickerValue, setDatePickerValue] = useState(field.value);

  const { errors, handleBlur, setFieldValue, touched, values } = formik;

  if (!visible) return null;

  const handleChange = (dateTime) => {
    setFieldValue(field.name, dateTime);
    onChange(dateTime, formik);
  };

  return (
    <FormFieldContainer>
      <AppFormLabel label={label} required={required} visible={!!label} />
      {LabelComponent}
      {/* <InputGroup> */}
      <AppDatePicker
        clearable={clearable}
        name={field.name}
        onChange={handleChange}
        placeholder={placeholder}
        value={datePickerValue}
      />
      {/* {icon && (
          <span
            className={`input-group-text ${
              onIconClick === null ? "" : "cur-pointer"
            }`}
            onClick={onIconClick}>
            <i className={icon}></i>
          </span>
        )}
      </InputGroup> */}
      <div>
        <AppErrorMessage
          error={errors?.[field.name]}
          visible={!!touched?.[field.name] && !!errors?.[field.name]}
        />
      </div>
    </FormFieldContainer>
  );
};

export default FormDatePickerField;
