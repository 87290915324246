import { ButtonGroup, Dropdown, DropdownButton } from "react-bootstrap";
import React, { useState } from "react";

import { ThemeColorsEnum } from "../../constants/enums";

const DropdownSelect = ({
  color = ThemeColorsEnum.default,
  fixedTitle = false,
  items = [],
  itemLabelProperty,
  itemValueProperty,
  onChange = () => {},
  placeholder = "",
  title,
  value,
  visible = true,
  ...otherProps
}) => {
  const [buttonTitle, setButtonTitle] = useState(
    title
      ? title
      : value
      ? items?.find((x) => x[itemValueProperty] === value)?.[
          itemLabelProperty
        ] ?? items[0]?.[itemLabelProperty]
      : placeholder
  );

  if (!visible) return null;

  const handleChange = (selectedValue, event) => {
    const selectedText = event?.target?.innerText;
    if (!fixedTitle) setButtonTitle(selectedText);
    onChange(selectedValue, selectedText);
  };

  return (
    <DropdownButton
      as={ButtonGroup}
      className="btn-block"
      onClick={(event) => event.stopPropagation()}
      onSelect={handleChange}
      title={buttonTitle}
      variant={color}
      {...otherProps}
    >
      {items?.map((item, index) =>
        item.type === "divider" ? (
          <Dropdown.Divider key={`divider-${index}`} />
        ) : (
          <Dropdown.Item
            className={item.className}
            eventKey={item[itemValueProperty]}
            href={item.href}
            onClick={item.onClick}
            key={`${item[itemValueProperty]}-${index}`}
          >
            {item[itemLabelProperty]}
          </Dropdown.Item>
        )
      )}
    </DropdownButton>
  );
};

export default DropdownSelect;
