import { Col, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { ThemeColorsEnum, UnitOfMeasurementsEnum } from "../../constants/enums";

import AddIcon from "@mui/icons-material/Add";
import AppErrorMessage from "./ErrorMessage";
import AppFormLabel from "./FormLabel";
import { AuthContext } from "../../context/contextProvider";
import CircleButton from "../buttons/CircleButton";
import FormFieldContainer from "./FormFieldContainer";
import { Link } from "react-router-dom";
import WorkOrderProduct from "./WorkOrderProduct";
import { useFormikContext } from "formik";
import { WorkOrderTypesEnum } from "../../constants/enums";
import FormServiceCategorySelect from "./FormServiceCategorySelect";
import FormSelectField from "./FormSelectField";
import FormCheckboxField from "./FormCheckboxField";
import FormTextField from "./FormTextField";
import useCurrentUserInfo from "../../hooks/useCurrentUserInfo";
import Services from "../../services/services";
import { RolesEnum } from "../../constants/enums";


const FormGenericOrderProducts = ({
	availableProducts,
	completing,
	directDrop,
	order,
	orderType,
	errorFieldName,
	label = null,
	LabelComponent,
	name,
	onSelectProduct = () => { },
	required,
	visible = true,
	...otherProps
}) => {
	const formik = useFormikContext();
	const { errors, handleBlur, setFieldValue, touched, values } = formik;
	const { currentClient, branchSettings } = useContext(AuthContext);

	const [productsList, setProductsList] = useState(values[name] || []);
	const [categories, setCategories] = useState([]);
	const [technicians, setTechnicians] = useState([]);

	const userInfo = useCurrentUserInfo();

	//useEffect(() => {
	//
	//	console.log("name: ", name);
	//	setProductsList(values[name] || []);
	//}, [values[name]]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		initAsync();
	}, [currentClient]); // Add order to the dependency array

	useEffect(() => {
		setProductsList(values[name] || []);
	}, [orderType, values[name]]);

	const initAsync = async () => {
		if (order) {
			await populateEditFormAsync();
		}
		await getUsersAsync();
		await getCategoriesAsync();
	};

	const populateEditFormAsync = async () => {
		if (order) {
			const orderTypeValue = order.WorkOrderType || '';
			if (orderTypeValue === WorkOrderTypesEnum.directDrop ||
				orderTypeValue === WorkOrderTypesEnum.workOrder) {
				setFieldValue("productList", order.productList || []);
				setProductsList(order.productList || []);
			}
			else {
				setFieldValue("assignedToIds", order.assignedToIds || "");
				setFieldValue("assignedToNames", order.assignedToNames || "");
				setFieldValue("title", order.title || "");
				setFieldValue("categoryId", order.categoryId || "");
				setFieldValue("categoryName", order.categoryName || ""); // Add this line
				setFieldValue("description", order.description || "");
				setFieldValue("isDriverLocked", order.isDriverLocked || false);
			}
		}
	};

	const getCategoriesAsync = async () => {
		const response = await Services.api.serviceCategories.all();

		if (!response.ok)
			return Services.utility.toast(
				"Could not download the categories",
				ThemeColorsEnum.error
			);

		setCategories(response.data);
	};

	const getUsersAsync = async () => {
		const response = await Services.api.users.getByRole(RolesEnum.serviceTech);
		if (!response.ok)
			return Services.utility.toast(
				"Could not download the users",
				ThemeColorsEnum.error
			);
		const finalData = Services.utility.getDataForUserPicker(response.data);
		setTechnicians(finalData);
	};

	const handleAddProduct = () => {
		const newProduct = {
			id: 0,
			currentPercentage: "",
			productId: 0,
			quantity: directDrop ? branchSettings?.directDropDefaultQuantity : "",
			unitOfMeasurement: UnitOfMeasurementsEnum.liters,
		};
		const updatedList = [...productsList, newProduct];
		setProductsList(updatedList);
		setFieldValue(name, updatedList);
	};

	const handleRemoveLastProduct = () => {
		const updatedList = productsList.slice(0, -1);
		setProductsList(updatedList);
		setFieldValue(name, updatedList);
	};

	const handleSelectProduct = (index) => (product) => {
		const updatedList = [...productsList];
		updatedList[index] = { ...updatedList[index], ...product };
		setProductsList(updatedList);
		setFieldValue(name, updatedList);
		onSelectProduct(product, formik);
	};

	const handleProductRemoved = (ArgIndex) => {
		if (ArgIndex > -1 ) {
			var ret = productsList.splice(ArgIndex, 1);
			const updatedProductList = productsList.filter(
				(x) => x !== ret);
			setProductsList(updatedProductList);
		}
	};

	const handleSelectCategory = (category, { setFieldValue }) => {
		setFieldValue("categoryId", category?.id);
		setFieldValue("categoryName", category?.name);
		setFieldValue("description", category?.notes);
	};

	if (!visible) return null;
	const orderTypeValue = orderType || 0;
//	console.log("otype val: ", orderTypeValue, "raw: ", orderType, "plist: ", productsList);
//	console.log("wo?", orderTypeValue === WorkOrderTypesEnum.workOrder, productsList);

	return (
		<FormFieldContainer>
			<Row>
				<Col lg={10}>
					<AppFormLabel label={label} required={required} visible={!!label} />
					{LabelComponent}
				</Col>
				<Col lg={2} className="text-right">
					{orderTypeValue !== WorkOrderTypesEnum.serviceOrder && (
						<CircleButton
							color={ThemeColorsEnum.quaternary}
							size={25}
							IconComponent={() => (
								<AddIcon className="cur-pointer" style={{ fontSize: 20 }} />
							)}
							onClick={handleAddProduct}
						/>
					)}
				</Col>
			</Row>
			{orderTypeValue === WorkOrderTypesEnum.workOrder && (
				<>
					{productsList?.map((workOrderProduct, index) => (
						<WorkOrderProduct
							className="mb-1"
							completing={completing}
							directDrop={directDrop}
							key={index}
							name={`${name}[${index}]`}
							onBlur={() => handleBlur(name)}
							onSelected={handleSelectProduct(index)}
							products={availableProducts}
							onProductRemoved={handleProductRemoved}
							workOrderProduct={workOrderProduct}
							ListIndex={index}
						/>
					))}
					<Row>
						<Col sm={8}>
							<AppErrorMessage
								error={errors[errorFieldName]}
								visible={!!touched[name] && !!errors[errorFieldName]}
							/>
						</Col>
						{/*{productsList?.length > 1 && (
							<Col className="text-right" sm={4}>
								<Link onClick={handleRemoveLastProduct} to="#">
									Remove
								</Link>
							</Col>
						)}*/}
					</Row>
				</>
			)}
			{orderTypeValue === WorkOrderTypesEnum.directDrop && (
				<>
					{productsList?.map((directDropProduct, index) => (
						<WorkOrderProduct
							className="mb-1"
							completing={completing}
							directDrop={true}
							key={index}
							name={`${name}[${index}]`}
							onBlur={() => handleBlur(name)}
							onSelected={handleSelectProduct(index)}
							products={availableProducts}
							workOrderProduct={directDropProduct}
							onProductRemoved={handleProductRemoved}
							ListIndex={index}
						/>
					))}
					<Row>
						<Col sm={8}>
							<AppErrorMessage
								error={errors[errorFieldName]}
								visible={!!touched[name] && !!errors[errorFieldName]}
							/>
						</Col>
						{/*{productsList?.length > 1 && (
							<Col className="text-right" sm={4}>
								<Link onClick={handleRemoveLastProduct} to="#">
									Remove
								</Link>
							</Col>
						)}*/}
					</Row>
				</>
			)}
			{orderTypeValue === WorkOrderTypesEnum.serviceOrder && (
				<Row>
					<Col lg={4}>
						<FormSelectField
							disabled={!userInfo?.isAdmin && order?.isTechLocked}
							itemLabelProperty="label"
							items={technicians}
							itemValueProperty="value"
							multi
							label="Technician(s)"
							name="assignedToIds"
						/>
						<FormCheckboxField
							description="Only Managers can change the technician(s)"
							name="isDriverLocked"
						/>
					</Col>
					<Col lg={4}>
						<FormTextField label="Title" name="title" />
					</Col>
					<Col lg={3}>
						<FormServiceCategorySelect
							itemLabelProperty="name"
							items={categories}
							itemValueProperty="id"
							label="Category"
							name="categoryId"
							onChange={handleSelectCategory}
							placeholder="Pick a category..."
							required
							searchable
						/>
					</Col>
				</Row>
			)}
		</FormFieldContainer>
	);
};

export default FormGenericOrderProducts;
