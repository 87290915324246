import {
  AuthContext,
  LoadingContext,
  ModalContext,
} from "../../context/contextProvider";
import { Avatar, Badge } from "@mui/material";
import React, { useContext } from "react";

import BranchPickerModal from "../modals/custom/BranchPickerModal";
import { Link } from "react-router-dom";
import { LocalStorageKeys } from "../../constants/storageKeys";
import Schemas from "../../constants/schemas";
import Services from "../../services/services";
import { ThemeColorsEnum } from "../../constants/enums";
import UserForm from "../forms/custom/UserForm";
import UserPreferencesModal from "../modals/custom/UserPreferencesModal";
import { isMobile } from "react-device-detect";
import useAuthentication from "../../hooks/useAuthentication";

const UserBox = () => {
  const { currentClient, setCurrentClient, currentUser, setUserSettings } =
    useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);
  const { showModal, closeModal } = useContext(ModalContext);
  const authentication = useAuthentication();

  const handleLogout = async () => {
    await authentication.logOut();
  };

  const handleShowPreferences = () => {
    showModal(
      `User Preferences - ${currentUser?.fullName}`,
      null,
      null,
      <UserPreferencesModal onSave={savePreferences} user={currentUser} />
    );
  };

  const handleShowProfile = () => {
    showModal(
      `User Profile - ${currentUser?.fullName}`,
      null,
      null,
      <UserForm
        modal
        onCancel={closeModal}
        onSave={closeModal}
        validationSchema={Schemas.editUser}
        user={currentUser}
      />
    );
  };

  const handleSwitchBranch = () => {
    showModal(
      "Pick a branch",
      null,
      null,
      <BranchPickerModal
        branches={currentUser?.clients}
        onSave={switchBranch}
      />
    );
  };

  const savePreferences = async (data) => {
    setLoading(true);
    const response = await Services.api.settings.saveUserSettings(data);
    setLoading(false);

    if (!response.ok)
      return Services.utility.toast(
        "Could not save preferences",
        ThemeColorsEnum.error
      );

    // update current user settings globally
    if (currentUser?.id === response.data?.userId)
      setUserSettings(response.data);

    Services.utility.toast("Preferences saved", ThemeColorsEnum.success);
  };

  const switchBranch = (branchId) => {
    const newBranch = currentUser?.clients?.find(
      (x) => x.id === parseInt(branchId)
    );

    Services.localStorage.setProperty(
      LocalStorageKeys.currentClient,
      newBranch
    );
    setCurrentClient(newBranch);
  };

  return (
    <div id="userbox" className="userbox">
      <Link to="#" data-bs-toggle="dropdown">
        {/* <figure className="profile-picture">
          <img
            src={`${process.env.PUBLIC_URL}/img/user.png`}
            alt={currentUser.userName}
            className="rounded-circle"
            data-lock-picture=""
          />
        </figure> */}
        <Badge
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={0}
          color="success"
          overlap="circular"
          showZero
          variant="dot"
        >
          <Avatar 
			sx={{ bgcolor: "#0C2340" }}
			className="profile-picture">
            {currentUser.fullName
              ?.split(" ")
              ?.map((n) => n[0])
              ?.join("")}
          </Avatar>
        </Badge>
        <div
          className="profile-info"
          data-lock-name={currentUser.userName}
          data-lock-email={currentUser.email}
        >
          <span className="name">{currentUser.userName}</span>
          {currentClient && (
            <span className="role">{currentClient.displayName}</span>
          )}
        </div>

        <i className="fa custom-caret"></i>
      </Link>

      <div className="dropdown-menu" role="menu">
        <ul className="list-unstyled mb-2">
          <li className="divider" />
          <li>
            <Link
              onClick={handleShowProfile}
              role="menuitem"
              tabIndex="-1"
              to="#"
            >
              <i className="fas fa-user-edit" /> Edit Profile
            </Link>
          </li>
          <li>
            <Link
              onClick={handleShowPreferences}
              role="menuitem"
              tabIndex="-1"
              to="#"
            >
              <i className="fas fa-cog" /> My Preferences
            </Link>
          </li>
          {isMobile && (
            <>
              <li className="divider" />
              <li>
                <Link
                  onClick={handleSwitchBranch}
                  role="menuitem"
                  tabIndex="-1"
                  to="#"
                >
                  <i className="fas fa-exchange-alt" /> Switch Branch
                </Link>
              </li>
            </>
          )}
          <li className="divider" />
          <li>
            <Link
              className="text-danger"
              onClick={handleLogout}
              role="menuitem"
              tabIndex="-1"
              to="#"
            >
              <i className="fa fa-power-off" /> Logout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserBox;
